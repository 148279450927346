import _ from 'lodash'
import { combineReducers } from 'redux'
import { appStateReducer } from 'mgr/lib/reducers/UtilReducers'
import perksReducer from 'mgr/pages/single-venue/marketing/reducers/Perks'
import languagesReducer from 'mgr/pages/single-venue/settings/reducers/Languages'
import { actualSlideoutApi, experiencesApi } from '@sevenrooms/core/api'
import bookAvailabilityReducer from './BookAvailabilityReducer'
import bookClientReducer from './BookClientReducer'
import bookDetailsReducer from './BookDetailsReducer'
import bookReducer from './BookReducer'
import bookSourceReducer from './BookSourceReducer'
import slideoutReducer from './SlideoutReducer'
import viewResReducer from './ViewResReducer'
import bookPaymentReducer from './BookPaymentReducer'
import { bookPaymentSlice } from './BookPaymentSlice'
import { upgradesState } from './UpgradesSlice'
import commonPaymentReducer from 'svr/component-lib/Widget/Payments/reducer'

const numActionsToKeep = 10 // Most we would dispatch in one batchActions event
let actionIndex = 0
const lastActionsReducer = (prevLastActions = [], action) => {
  const lastActions = [...prevLastActions, { action, actionIndex }]
  actionIndex += 1
  const numToDrop = lastActions.length - numActionsToKeep
  return numToDrop > 0 ? _.drop(lastActions, numToDrop) : lastActions
}

const rootReducer = combineReducers({
  lastActions: lastActionsReducer,
  appState: appStateReducer,
  slideoutState: slideoutReducer,
  bookState: bookReducer,
  bookAvailabilityState: bookAvailabilityReducer,
  bookClientState: bookClientReducer,
  bookSourceState: bookSourceReducer,
  bookPaymentState: window.globalInit?.venueSettings?.res_slideout_fe_refactor_enabled ? bookPaymentSlice.reducer : bookPaymentReducer,
  upgradesState: upgradesState.reducer,
  bookDetailsState: bookDetailsReducer,
  viewResState: viewResReducer,
  languages: languagesReducer,
  perksList: perksReducer,
  commonPayment: commonPaymentReducer,
  [actualSlideoutApi.reducerPath]: actualSlideoutApi.reducer,
  [experiencesApi.reducerPath]: experiencesApi.reducer,
})

export default rootReducer
