export const INITIALIZE = 'INITIALIZE'
export const INITIALIZE_EXPERIENCE_MODE = 'INITIALIZE_EXPERIENCE_MODE'
export const REVERT_STAGE = 'REVERT_STAGE'
export const ADVANCE_STAGE = 'ADVANCE_STAGE'
export const HOVER_DATE = 'HOVER_DATE'
export const INCREMENT_ITEM_DATE = 'INCREMENT_ITEM_DATE'
export const DECREMENT_ITEM_DATE = 'DECREMENT_ITEM_DATE'
export const INCREMENT_ITEM_PARTY_SIZE = 'INCREMENT_ITEM_PARTY_SIZE'
export const DECREMENT_ITEM_PARTY_SIZE = 'DECREMENT_ITEM_PARTY_SIZE'
export const INCREMENT_ITEM_DURATION = 'INCREMENT_ITEM_DURATION'
export const DECREMENT_ITEM_DURATION = 'DECREMENT_ITEM_DURATION'
export const INCREMENT_ITEM_TIME = 'INCREMENT_ITEM_TIME'
export const DECREMENT_ITEM_TIME = 'DECREMENT_ITEM_TIME'
export const TOGGLE_SEARCH_COMPONENT = 'TOGGLE_SEARCH_COMPONENT'
export const SELECT_QUERY_DATE = 'SELECT_QUERY_DATE'
export const SELECT_QUERY_PARTY_SIZE = 'SELECT_QUERY_PARTY_SIZE'
export const SELECT_QUERY_DURATION = 'SELECT_QUERY_DURATION'
export const SELECT_QUERY_TIME = 'SELECT_QUERY_TIME'
export const SELECT_TIME_SLOT = 'SELECT_TIME_SLOT'
export const SELECT_TIME_SLOT_VENUE = 'SELECT_TIME_SLOT_VENUE'
export const SELECT_REQUEST = 'SELECT_REQUEST'
export const SELECT_REQUEST_TIME = 'SELECT_REQUEST_TIME'
export const SELECT_REQUEST_END_TIME = 'SELECT_REQUEST_END_TIME'
export const CLEAR_FORM_VALIDATION = 'CLEAR_FORM_VALIDATION'
export const SELECT_VENUE = 'SELECT_VENUE'
export const SET_LOGIN_INFO = 'SET_LOGIN_INFO'
export const SET_USER_DATA_FB = 'SET_USER_DATA_FB'
export const SET_USER_DATA_GP = 'SET_USER_DATA_GP'
export const DISPLAY_MODAL = 'DISPLAY_MODAL'
export const DISMISS_MODAL = 'DISMISS_MODAL'
export const CONFIRM_TIME_SLOT = 'CONFIRM_TIME_SLOT'
export const SAVE_TAG_GROUP = 'SAVE_TAG_GROUP'
export const STRIPE_ERROR = 'STRIPE_ERROR'
export const TOGGLE_FIELD = 'TOGGLE_FIELD'
export const CHANGE_PHONE_NUM = 'CHANGE_PHONE_NUM'
export const CHANGE_COUNTRY_FLAG = 'CHANGE_COUNTRY_FLAG'
export const CHANGE_FORM_FIELD = 'CHANGE_FORM_FIELD'
export const CHANGE_CAPTCHA_FIELD = 'CHANGE_CAPTCHA_FIELD'
export const VALIDATE_CAPTCHA_FIELD = 'VALIDATE_CAPTCHA_FIELD'
export const VALIDATE_ALL_FIELDS = 'VALIDATE_ALL_FIELDS'
export const MANDATE_POLICIES = 'MANDATE_POLICIES'
export const TRY_GET_PROMO_CODES = 'TRY_GET_PROMO_CODES'
export const GET_PROMO_CODES_SUCCESS = 'GET_PROMO_CODES_SUCCESS'
export const GET_PROMO_CODES_FAILURE = 'GET_PROMO_CODES_FAILURE'
export const GET_REMAINING_LANGUAGE_STRINGS_START = 'GET_REMAINING_LANGUAGE_STRINGS_START'
export const GET_REMAINING_LANGUAGE_STRINGS_FAILURE = 'GET_REMAINING_LANGUAGE_STRINGS_FAILURE'
export const GET_REMAINING_LANGUAGE_STRINGS_SUCCESS = 'GET_REMAINING_LANGUAGE_STRINGS_SUCCESS'
export const TRY_VERIFY_PROMO_CODE = 'TRY_VERIFY_PROMO_CODE'
export const VERIFY_PROMO_CODE_SUCCESS = 'VERIFY_PROMO_CODE_SUCCESS'
export const VERIFY_PROMO_CODE_FAILURE = 'VERIFY_PROMO_CODE_FAILURE'
export const ADD_PROMO_CODE = 'ADD_PROMO_CODE'
export const REMOVE_PROMO_CODE = 'REMOVE_PROMO_CODE'
export const INVALIDATE_PROMO_CODE_ATTEMPT = 'INVALIDATE_PROMO_CODE_ATTEMPT'
export const TRY_AGAIN = 'TRY_AGAIN'
// HTTP Request Actions
export const GET_VALID_DATES_SUCCESS = 'GET_VALID_DATES_SUCCESS'
export const GET_VALID_DATES_FAILURE = 'GET_VALID_DATES_FAILURE'
export const GET_EXPERIENCE_AVAILABILITY_SUCCESS = 'GET_EXPERIENCE_AVAILABILITY_SUCCESS'
export const GET_EXPERIENCE_AVAILABILITY_FAILURE = 'GET_EXPERIENCE_AVAILABILITY_FAILURE'
export const GET_EXPERIENCE_AVAILABILITY_DONE = 'GET_EXPERIENCE_AVAILABILITY_DONE'
export const TRY_GET_INITIAL_AVAILABILITY = 'TRY_GET_INITIAL_AVAILABILITY'
export const GET_INITIAL_AVAILABILITY_SUCCESS = 'GET_INITIAL_AVAILABILITY_SUCCESS'
export const GET_INITIAL_AVAILABILITY_FAILURE = 'GET_INITIAL_AVAILABILITY_FAILURE'
export const TRY_GET_MULTI_VENUE_AVAILABILITY = 'TRY_GET_MULTI_VENUE_AVAILABILITY'
export const GET_MULTI_VENUE_AVAILABILITY_SUCCESS = 'GET_MULTI_VENUE_AVAILABILITY_SUCCESS'
export const GET_MULTI_VENUE_AVAILABILITY_FAILURE = 'GET_MULTI_VENUE_AVAILABILITY_FAILURE'
export const TRY_GET_ADDITIONAL_AVAILABILITY = 'TRY_GET_ADDITIONAL_AVAILABILITY'
export const GET_ADDITIONAL_AVAILABILITY_SUCCESS = 'GET_ADDITIONAL_AVAILABILITY_SUCCESS'
export const GET_ADDITIONAL_AVAILABILITY_FAILURE = 'GET_ADDITIONAL_AVAILABILITY_FAILURE'
export const GET_ADDITIONAL_AVAILABILITY_COMPLETE = 'GET_ADDITIONAL_AVAILABILITY_COMPLETE'
export const TRY_POST_CHECKOUT = 'TRY_POST_CHECKOUT'
export const POST_CHECKOUT_FAILURE = 'POST_CHECKOUT_FAILURE'
export const POST_CHECKOUT_RELOAD_ON_FAILURE = 'POST_CHECKOUT_RELOAD_ON_FAILURE'
export const SET_NETWORK_REDUX_RESPONSE = 'SET_NETWORK_REDUX_RESPONSE'
export const SET_PAYMENT_PENDING_RESPONSE = 'SET_PAYMENT_PENDING_RESPONSE'
export const POST_CHECKOUT_SUCCESS = 'POST_CHECKOUT_SUCCESS'
export const TRY_POST_REQUEST = 'TRY_POST_REQUEST'
export const POST_REQUEST_FAILURE = 'POST_REQUEST_FAILURE'
export const POST_REQUEST_SUCCESS = 'POST_REQUEST_SUCCESS'
export const TRY_HOLD_RESERVATION = 'TRY_HOLD_RESERVATION'
export const TRY_REDIRECT = 'TRY_REDIRECT'
export const HOLD_RESERVATION_FAILURE = 'HOLD_RESERVATION_FAILURE'
export const HOLD_RESERVATION_SUCCESS = 'HOLD_RESERVATION_SUCCESS'
export const CLEAR_HOLD_RESERVATION = 'CLEAR_HOLD_RESERVATION'

export const LOAD_ACTUAL_START = 'LOAD_ACTUAL_START'
export const LOAD_ACTUAL_FAIL = 'LOAD_ACTUAL_FAIL'
export const LOAD_ACTUAL_SUCCESS = 'LOAD_ACTUAL_SUCCESS'
export const SET_HAS_CREDIT_CARD_ON_FILE = 'SET_HAS_CREDIT_CARD_ON_FILE'

export const GET_EXPERIENCE_START = 'GET_EXPERIENCE_START'
export const GET_EXPERIENCE_SUCCESS = 'GET_EXPERIENCE_SUCCESS'
export const GET_EXPERIENCE_FAIL = 'GET_EXPERIENCE_FAIL'

export const GET_RESERVATION_REQUEST_START = 'GET_RESERVATION_REQUEST_START'
export const GET_RESERVATION_REQUEST_SUCCESS = 'GET_RESERVATION_REQUEST_SUCCESS'
export const GET_RESERVATION_REQUEST_FAIL = 'GET_RESERVATION_REQUEST_FAIL'

export const INCREMENT_CALENDAR_MONTH = 'INCREMENT_CALENDAR_MONTH'
export const DECREMENT_CALENDAR_MONTH = 'DECREMENT_CALENDAR_MONTH'

export const PASS_STRIPE_CARD_ELEMENT = 'PASS_STRIPE_CARD_ELEMENT'

export const INCREMENT_WAITLIST_PARTY_SIZE = 'INCREMENT_WAITLIST_PARTY_SIZE'
export const DECREMENT_WAITLIST_PARTY_SIZE = 'DECREMENT_WAITLIST_PARTY_SIZE'

export const SUBMIT_WAITLIST_START = 'SUBMIT_WAITLIST_START'
export const SUBMIT_WAITLIST_SUCCESS = 'SUBMIT_WAITLIST_SUCCESS'
export const SUBMIT_WAITLIST_FAIL = 'SUBMIT_WAITLIST_FAIL'

export const GET_WAITLIST_ETAS_START = 'GET_WAITLIST_ETAS_START'
export const GET_WAITLIST_ETAS_SUCCESS = 'GET_WAITLIST_ETAS_SUCCESS'
export const GET_WAITLIST_ETAS_FAIL = 'GET_WAITLIST_ETAS_FAIL'

export const GET_WAITLIST_ENTRY_START = 'GET_WAITLIST_ENTRY_START'
export const GET_WAITLIST_ENTRY_SUCCESS = 'GET_WAITLIST_ENTRY_SUCCESS'
export const GET_WAITLIST_ENTRY_FAIL = 'GET_WAITLIST_ENTRY_FAIL'

export const CANCEL_WAITLIST_ENTRY_START = 'CANCEL_WAITLIST_ENTRY_START'
export const CANCEL_WAITLIST_ENTRY_SUCCESS = 'CANCEL_WAITLIST_ENTRY_SUCCESS'
export const CANCEL_WAITLIST_ENTRY_FAIL = 'CANCEL_WAITLIST_ENTRY_FAIL'
export const REJOIN_WAITLIST = 'REJOIN_WAITLIST'
export const GO_TO_WAITING_ROOM = 'GO_TO_WAITING_ROOM'

export const SET_PAGE_TITLE = 'SET_PAGE_TITLE'

export const LOAD_LANDING_PAGE_SUCCESS = 'LOAD_LANDING_PAGE_SUCCESS'
export const LOAD_LANDING_PAGE_START = 'LOAD_LANDING_PAGE_START'
export const LOAD_LANDING_PAGE_DONE = 'LOAD_LANDING_PAGE_DONE'

export const VIEW_REDEMPTION_BALANCE = 'VIEW_REDEMPTION_BALANCE'
export const APPLY_REDEMPTION = 'APPLY_REDEMPTION'
export const REMOVE_REDEMPTION = 'REMOVE_REDEMPTION'
export const SHOW_REDEMPTION_FORM = 'SHOW_REDEMPTION_FORM'
export const CANCEL_REDEMPTION_FORM = 'CANCEL_REDEMPTION_FORM'
export const CLEAR_REDEMPTION_FORM = 'CLEAR_REDEMPTION_FORM'
export const SHOW_REDEMPTION_ERROR = 'SHOW_REDEMPTION_ERROR'
export const TRY_VIEW_REDEMPTION_BALANCE = 'TRY_VIEW_REDEMPTION_BALANCE'
export const VIEW_REDEMPTION_BALANCE_LOADED = 'VIEW_REDEMPTION_BALANCE_LOADED'
export const CLEAR_REDEMPTIONS_ON_PROMO_CODE = 'CLEAR_REDEMPTIONS_ON_PROMO_CODE'

export const HIDE_LOADING = 'HIDE_LOADING'

export const SET_FREEDOMPAY_INITIAL_DATA = 'SET_FREEDOMPAY_INITIAL_DATA'
export const SET_FREEDOMPAY_VISIBLE = 'SET_FREEDOMPAY_VISIBLE'

export const INITIALIZE_UPGRADES_STATE = 'INITIALIZE_UPGRADES_STATE'
export const SET_ACCESS_PERSISTENT_ID = 'SET_ACCESS_PERSISTENT_ID'
export const SET_AGREED_TO_GDPR_DIETARY_OPT_IN = 'SET_AGREED_TO_GDPR_DIETARY_OPT_IN'
export const LOAD_WIDGET_DATA_START = 'LOAD_WIDGET_DATA_START'
export const LOAD_WIDGET_DATA_SUCCESS = 'LOAD_WIDGET_DATA_SUCCESS'
export const LOAD_WIDGET_DATA_ERROR = 'LOAD_WIDGET_DATA_ERROR'
export const INITIALIZE_VENUE_INFO = 'INITIALIZE_VENUE_INFO'
export const INITIALIZE_VENUE_ENTITIES = 'INITIALIZE_VENUE_ENTITIES'
export const INITIALIZE_UI = 'INITIALIZE_UI'
export const INITIALIZE_SEARCH = 'INITIALIZE_SEARCH'
export const INITIALIZE_FORM_FIELDS = 'INITIALIZE_FORM_FIELDS'
export const RESET_FORM_FIELDS = 'RESET_FORM_FIELDS'
export const INITIALIZE_TAGS = 'INITIALIZE_TAGS'
export const INITIALIZE_LANGUAGE = 'INITIALIZE_LANGUAGE'
export const RE_INITIALIZE_UPGRADES = 'RE_INITIALIZE_UPGRADES'

export const SET_LAST_CONFIRMATION_METHOD = 'SET_LAST_CONFIRMATION_METHOD'
