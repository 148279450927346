import { useDispatch } from 'react-redux'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in MA-857
import type { EmailCampaignTemplate } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import { Header, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { emailTemplateMessages } from '../../locales'
import { setCampaignTemplateToCopy } from '../../store'

export function EmailTemplateCard({ template, disable }: { template: EmailCampaignTemplate; disable?: boolean }) {
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const dispatch = useDispatch()
  const { formatMessage } = useLocales()
  const handleUseTemplateClick = () => {
    dispatch(setCampaignTemplateToCopy(template))
    nav.push(routes.manager2.marketing.emailCenter.emails.emailTemplate.useTemplate, {
      params: { venueKey: venue.urlKey },
      queryMode: 'clear',
    })
  }

  return (
    <ShadowedVStack spacing="s">
      <Box>
        <StyledHeader type="h4">{`${template.campaignName}`}</StyledHeader>
      </Box>
      <TextBox>
        <Text>{template.campaignDescription}</Text>
      </TextBox>
      <Box>
        <HStack spacing="s" justifyContent="end" alignItems="flex-end">
          <Button variant="secondary" size="s" onClick={handleUseTemplateClick} disabled={disable} data-test="email-template-button">
            {disable ? formatMessage(emailTemplateMessages.inUseTemplateCardText) : formatMessage(emailTemplateMessages.templateCardText)}
          </Button>
        </HStack>
      </Box>
    </ShadowedVStack>
  )
}

const StyledHeader = styled(Header)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 25px;
  max-height: 32px;
`

const TextBox = styled(Box)`
  width: 248px;
  height: 77px;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 18px !important;
`

const ShadowedVStack = styled(VStack)`
  background: ${theme.colors.primaryBackground};
  border: 1px solid ${theme.colors.borders};
  border-radius: 4px;
  box-sizing: border-box;
  height: 211px;
  width: 270px;
  box-shadow: 0px 2px 4px rgba(9, 34, 56, 0.1);
  padding 24px 16px !important;
  position: relative;
`
