import _ from 'lodash'
import React, { useState } from 'react'
import MomentPropTypes from 'react-moment-proptypes'
import { connect } from 'react-redux'
import styled from 'styled-components'
import AvailabilityDateSelectBar from 'mgr/actualslideout/components/availability/AvailabilityDateSelectBar'
import AvailabilityPartySizeSelectBar from 'mgr/actualslideout/components/availability/AvailabilityPartySizeSelectBar'
import { BufferMenu } from 'mgr/actualslideout/components/availability/BufferMenu'
import { InternalArBookingBar } from 'mgr/actualslideout/components/availability/InternalArBookingBar'
import BookStepComponent from 'mgr/actualslideout/components/BookStepComponent'
import { selectDiningVenues } from 'mgr/actualslideout/selectors/BookSelectors'
import DurationDropDown from 'mgr/lib/components/DurationDropDown'
import SeatingAreaDropDown from 'mgr/lib/components/SeatingAreaDropDown'
import ShiftDropDown from 'mgr/lib/components/ShiftDropDown'
import TimeDropDown from 'mgr/lib/components/TimeDropDown'
import VenueDropDown from 'mgr/lib/components/VenueDropDown'
import { formatWithoutYear } from 'mgr/lib/utils/MomentUtils'
import { getVenueToday, getStartTimeEndTimeDisplay } from 'svr/common/TimeUtil'
import { VmsIcons } from 'svr/common/VmsIcons'
import { Locale } from '@sevenrooms/core/locales'
import { AvailabilityTimeslotExperiencePopup } from 'mgr/actualslideout/containers/booksteps/availability/AvailabilityTimeslotExperiencePopup'
import { AvailabilityTimeslotExperienceButton } from 'mgr/actualslideout/containers/booksteps/availability/AvailabilityTimeslotExperienceButton'
import AvailabilityResults from './availability/AvailabilityResults'
import * as BookAvailabilityActions from 'mgr/actualslideout/actions/BookAvailabilityActions'

const HorizontalLine = styled.div`
  border-bottom: 2px solid ${props => props.theme.margin};
  margin: 10px 0;
`

const FieldGroup = styled.div`
  margin: 15px 12px;
  ${props => props.theme.clearFix};
`

const allTimeSlots = _.range(0, 96)

const subTitleSeparator = '\u00A0 · \u00A0'

const AvailabilityStep = ({
  diningVenues,
  venueSearchGroups,
  canBookDifferentVenue,
  searchVenues,
  selectedVenue,
  date,
  partySize,
  shifts,
  shiftPersistentId,
  searchTimeSlot,
  shiftTimeSlots,
  timezone,
  startOfDayHour,
  duration,
  usingDefaultDuration,
  seatingAreas,
  searchSeatingAreaId,
  selectedSeatingArea,
  isMultiVenueSeatingAreaSearchAny,
  actions,
  searchResultsPage,
  availabilityByVenue,
  selectedTimeSlot,
  isCollapsed,
  scrollIntoView,
  validateFieldMaps,
  currencyCode,
  internalArBookingEnabled,
  bufferMins,
  shiftBufferMins,
  shiftBufferPartySizeMin,
  toggleBuffer,
}) => {
  const [isActivePopup, setIsActivePopup] = useState(false)

  const locale = Locale.getLocale(window.globalInit.venueLocale)
  const { is_buffers_enabled: isBuffersEnabled } = window.globalInit.venueSettings
  const showBuffers = window.globalInit.venueSettings.is_buffers_enabled && bufferMins

  const validateFieldMap = validateFieldMaps.availability
  const hasSelection = _.isObject(selectedTimeSlot)
  const onToggle = () => {
    actions.toggleStep('availability')
  }
  const onCompleteScrollIntoView = () => {
    actions.clearScrollIntoView()
  }
  const today = getVenueToday(timezone, startOfDayHour)
  const handleDateChange = (d, venue) => {
    actions.changeDate(d, venue)
  }
  const hasMultiVenues = diningVenues.length > 1
  const showVenueDropdown = hasMultiVenues && canBookDifferentVenue
  const isMultiVenueSearch = searchVenues.length > 1
  const showLoadingSubtitle = isCollapsed && _.find(availabilityByVenue, { isTimesLoading: true })
  validateFieldMap.selected_time_slot = {
    isValid: () => (_.isNil(selectedTimeSlot) ? 'Please select an arrival time' : true),
  }
  validateFieldMap.selected_party_size = {
    isValid: () => (_.isNil(partySize) || partySize === 0 ? 'Please select a party size' : true),
  }
  let [title, subTitle] = ['Availability', showLoadingSubtitle ? 'Loading...' : null]
  if (isCollapsed && hasSelection) {
    const startEndTimeDisplay = getStartTimeEndTimeDisplay(selectedTimeSlot.timeMoment, duration, selectedVenue.confirmationIncludeEndTime)
    title = formatWithoutYear(date, 'dddd, LL')
    subTitle = [
      showBuffers ? `${startEndTimeDisplay} (+${bufferMins}m)` : startEndTimeDisplay,
      `${partySize} guests`,
      selectedVenue.internalDisplayName,
      _.isObject(selectedSeatingArea) ? selectedSeatingArea.name : 'Any Seating Area',
    ].join(subTitleSeparator)
    if (internalArBookingEnabled && selectedTimeSlot?.access_persistent_id) {
      subTitle = (
        <>
          {subTitle}
          {subTitleSeparator}
          <AvailabilityTimeslotExperienceButton timeslot={selectedTimeSlot} setIsActive={setIsActivePopup} />
        </>
      )
    }
  }
  const defaultMultiSearchSeatingAreaId = isMultiVenueSeatingAreaSearchAny ? '_any_' : '_default_'

  return (
    <>
      <BookStepComponent
        {...{
          title,
          subTitle,
          onToggle,
          onCompleteScrollIntoView,
          isCollapsed,
          scrollIntoView,
        }}
        testId="sr-section-availability"
        icon={VmsIcons.Calendar}
        iconLine={VmsIcons.CalendarLine}
        isEmpty={!hasSelection}
        onCompleteScrollIntoView={() => {
          actions.clearScrollIntoView()
        }}
      >
        <div>
          {showVenueDropdown && (
            <FieldGroup key="FieldGroup_MultiVenueSection">
              <VenueDropDown
                venues={diningVenues}
                venueSearchGroups={venueSearchGroups}
                venueIds={searchVenues.map(v => v.id)}
                onChange={actions.changeSearchVenues}
                isLightTheme
                isMultiSelect
                width={350}
              />
            </FieldGroup>
          )}
          <FieldGroup key="FieldGroup_Date">
            <AvailabilityDateSelectBar {...{ date, today, locale, handleDateChange: e => handleDateChange(e, searchVenues[0]) }} />
          </FieldGroup>
          <FieldGroup key="FieldGroup_PartySize">
            <AvailabilityPartySizeSelectBar
              testId="sr-select-party_size"
              {...{ partySize }}
              onChangeHandler={e => actions.changePartySize(e, searchVenues[0])}
            />
          </FieldGroup>
          <FieldGroup key="FieldGroup_ShiftTimeDurationSeatingArea">
            {isMultiVenueSearch ? (
              <TimeDropDown
                {...{ startOfDayHour }}
                timeSlot={searchTimeSlot}
                timeSlots={allTimeSlots}
                onChangeTime={actions.changeSearchTimeSlot}
                showNavigation={false}
                isLightTheme
                width={200}
              />
            ) : (
              <ShiftDropDown
                shifts={shifts}
                shiftPersistentId={shiftPersistentId}
                placeholder="LOADING"
                onChangeShift={actions.changeShift}
                showNavigation={false}
                isLightTheme
                style={{ marginBottom: 3 }}
                width={200}
              />
            )}
            <DurationDropDown
              {...{ duration }}
              onChangeDuration={e => actions.changeDuration(e, searchVenues[0])}
              usingDefaultDuration={usingDefaultDuration}
              width={328}
              style={{ marginBottom: 3 }}
              disabled={isMultiVenueSearch}
              extra={
                isBuffersEnabled && (shiftBufferMins || bufferMins) && partySize > shiftBufferPartySizeMin ? (
                  <BufferMenu showAdd={!bufferMins} onClick={toggleBuffer} />
                ) : null
              }
              bufferMins={bufferMins}
            />
            <SeatingAreaDropDown
              seatingAreaId={searchSeatingAreaId ?? (isMultiVenueSearch ? defaultMultiSearchSeatingAreaId : null)}
              {...{ seatingAreas }}
              includeDefaultOption={isMultiVenueSearch}
              includeAllOption
              placeholder="LOADING"
              onChangeSeatingArea={actions.changeSeatingArea}
              style={{ marginRight: 0, marginBottom: 3 }}
              width={200}
            />
          </FieldGroup>
          <HorizontalLine />
          {internalArBookingEnabled && <InternalArBookingBar actions={actions} />}
          <FieldGroup key="FieldGroup_Results">
            <AvailabilityResults {...{ actions }} />
          </FieldGroup>
        </div>
      </BookStepComponent>
      {internalArBookingEnabled && selectedTimeSlot?.access_persistent_id && (
        <AvailabilityTimeslotExperiencePopup
          timeslot={selectedTimeSlot}
          currencyCode={currencyCode}
          venueId={searchVenues[0]?.id}
          isActive={isActivePopup}
          setIsActive={setIsActivePopup}
        />
      )}
    </>
  )
}

AvailabilityStep.propTypes = {
  userDomain: React.PropTypes.object,
  searchVenues: React.PropTypes.array.isRequired,
  selectedVenue: React.PropTypes.object.isRequired,
  diningVenues: React.PropTypes.array.isRequired,
  venueSearchGroups: React.PropTypes.object.isRequired,
  canBookDifferentVenue: React.PropTypes.bool,
  date: MomentPropTypes.momentObj,
  partySize: React.PropTypes.number,
  actions: React.PropTypes.object,
  timezone: React.PropTypes.string,
  startOfDayHour: React.PropTypes.number,
  shifts: React.PropTypes.array,
  shift: React.PropTypes.object,
  searchTimeSlot: React.PropTypes.number,
  duration: React.PropTypes.number,
  seatingAreas: React.PropTypes.array,
  seatingArea: React.PropTypes.object,
  availabilityByVenue: React.PropTypes.object.isRequired,
  selectedTimeSlot: React.PropTypes.object,
  isCollapsed: React.PropTypes.bool,
  scrollIntoView: React.PropTypes.bool,
  usingDefaultDuration: React.PropTypes.bool,
  validateFieldMaps: React.PropTypes.object.isRequired,
  internalArBookingEnabled: React.PropTypes.bool,
  currencyCode: React.PropTypes.string,
}

AvailabilityStep.defaultProps = {
  date: moment(),
  actions: {},
}

const mapStateToProps = state => {
  const { searchVenues } = state.bookAvailabilityState
  const searchSeatingAreaId = state.bookAvailabilityState.seatingAreaId
  const { selectedTimeSlot } = state.bookAvailabilityState
  const selectedSeatingArea =
    _.isNil(selectedTimeSlot) || _.isNil(selectedTimeSlot.seating_area_id)
      ? null
      : state.bookAvailabilityState.floorPlan.seatingAreasById[selectedTimeSlot.seating_area_id]
  const isEditMode = !_.isEmpty(state.slideoutState.actualId)
  const canBookDifferentVenue = !isEditMode || (state.viewResState.actual || {}).can_rebook_different_venue
  return {
    searchVenues,
    searchResultsPage: state.bookAvailabilityState.searchResultsPage,
    selectedVenue: state.bookState.selectedVenue,
    diningVenues: selectDiningVenues(state),
    venueSearchGroups: state.appState.userDomain.venueSearchGroups,
    canBookDifferentVenue,
    date: state.bookAvailabilityState.date,
    partySize: state.bookAvailabilityState.partySize,
    timezone: state.bookState.selectedVenue.timezone,
    startOfDayHour: state.bookState.selectedVenue.startOfDayHour,
    shifts: state.bookAvailabilityState.shifts,
    shiftPersistentId: state.bookAvailabilityState.shiftPersistentId,
    accessPersistentId: state.bookAvailabilityState.accessPersistentId,
    searchTimeSlot: state.bookAvailabilityState.searchTimeSlot,
    duration: state.bookAvailabilityState.duration,
    usingDefaultDuration: state.bookAvailabilityState.usingDefaultDuration,
    seatingAreas: searchVenues.length > 1 ? [] : state.bookAvailabilityState.floorPlan.seatingAreas,
    searchSeatingAreaId,
    selectedSeatingArea,
    isMultiVenueSeatingAreaSearchAny: state.bookAvailabilityState.isMultiVenueSeatingAreaSearchAny,
    availabilityByVenue: state.bookAvailabilityState.availabilityByVenue,
    selectedTimeSlot,
    isCollapsed: state.bookState.isStepCollapsed.availability,
    stepHeightChangeTimestamp: state.bookState.stepHeightChangeTimestamps.availability,
    scrollIntoView: state.bookState.scrollIntoViewStep === 'availability',
    internalArBookingEnabled: state.bookAvailabilityState.internalArBookingEnabled,
    currencyCode: state.bookPaymentState.currencyCode,
    bufferMins: state.bookAvailabilityState.bufferMins,
    shiftBufferMins: state.bookAvailabilityState.shiftBufferMins,
    shiftBufferPartySizeMin: state.bookAvailabilityState.shiftBufferPartySizeMin,
  }
}

const mapDispatchToProps = {
  toggleBuffer: BookAvailabilityActions.toggleBuffer,
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityStep)
