import { requestInitialData, submitImportFile, uploadFile } from './services'

export const SET_INITIAL_DATA = 'SET_INITIAL_DATA'
export const ADD_NEW_TAG = 'ADD_NEW_TAG'
export const ADD_NEW_CATEGORY = 'ADD_NEW_CATEGORY'
export const MAP_TAG = 'MAP_TAG'
export const FILE_UPLOADED = 'FILE_UPLOADED'
export const SUBMISSION_DIALOG_INITIALIZED = 'SUBMISSION_DIALOG_INITIALIZED'
export const SUBMISSION_DIALOG_CLOSED = 'SUBMISSION_DIALOG_CLOSED'
export const IMPORT_SUBMISSION_STARTED = 'IMPORT_SUBMISSION_STARTED'
export const IMPORT_SUBMISSION_COMPLETED = 'IMPORT_SUBMISSION_COMPLETED'

export const setInitialData = (initialData, importType) => ({
  type: SET_INITIAL_DATA,
  initialData,
  importType,
})

export const addNewCategory = tagGroup => ({
  type: ADD_NEW_CATEGORY,
  tagGroup,
})

export const addNewTag = tag => ({
  type: ADD_NEW_TAG,
  tag,
})

export const mapTag = (mappedTag, tagToMap) => ({
  type: MAP_TAG,
  mappedTag,
  tagToMap,
})

export const fileUploaded = data => ({
  type: FILE_UPLOADED,
  data,
})

export const submissionDialogInitialized = () => ({
  type: SUBMISSION_DIALOG_INITIALIZED,
})

export const submissionDialogClosed = () => ({
  type: SUBMISSION_DIALOG_CLOSED,
})

export const importSubmissionStarted = () => ({
  type: IMPORT_SUBMISSION_STARTED,
})

export const importSubmissionCompleted = importId => ({
  type: IMPORT_SUBMISSION_COMPLETED,
  importId,
})

export const getInitialData = (venueId, importType) => dispatch => {
  requestInitialData(venueId, importType).then(r => dispatch(setInitialData(r, importType)))
}

export const uploadFlatFile = flatFileResults => dispatch => {
  const dataForUpload = flatFileResults.$data.map(JSON.stringify).join('\n')
  const file = new Blob([dataForUpload], { type: 'application/json' })
  uploadFile(file).then(r => dispatch(fileUploaded(r)))
}

export const initializeSubmissionDialogue = () => dispatch => {
  dispatch(submissionDialogInitialized())
}

export const closeSubmissionDialog = () => dispatch => {
  dispatch(submissionDialogClosed())
}

export const submitImport =
  (
    venueId,
    importType,
    blobstoreUploadKey,
    customTagFields,
    mappedTags,
    newTagGroupsToSubmit,
    dateFormat,
    futureTableAndSeatingAreaOverride
  ) =>
  dispatch => {
    dispatch(importSubmissionStarted())
    submitImportFile(
      venueId,
      importType,
      blobstoreUploadKey,
      customTagFields,
      mappedTags,
      newTagGroupsToSubmit,
      dateFormat,
      futureTableAndSeatingAreaOverride
    ).then(r => dispatch(importSubmissionCompleted(r.importId)))
  }
