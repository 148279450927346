import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled, { createGlobalStyle } from 'styled-components'
import * as PaymentActions from 'mgr/actualslideout/actions/PaymentActions'
import AdyenCheckout from '@adyen/adyen-web'
import '@adyen/adyen-web/dist/adyen.css'
import * as BookActions from 'mgr/actualslideout/actions/BookActions'

const PaymentDiv = styled.div`
  border-radius: 8px;
  border-width: 2px;
  border-style: ${props => (!props.isValid && props.submitClicked ? 'solid' : 'hidden')};
`

const GlobalStyle = createGlobalStyle`
  #sr-checkout-payment {
    font-size: 14px;
  }
  .adyen-checkout__label__text {
      padding: 10px 0px 10px 10px ;
      font-size: 14px;
  }
  .adyen-checkout__input-wrapper {
      margin: 10px;
  }
  .adyen-checkout__field {
      margin-bottom: 0;
  }
  .adyen-checkout__issuer-list {
      padding: 1px;
  }
  #fields-holder-name, #fields-card-number, #fields-expiration, #fields-cvc {
      height: 40px;
      width: 355px;
      margin-bottom: 5px;
  }
`

class AdyenFormRender extends Component {
  constructor(props) {
    super(props)
    this.adyenRootCard = React.createRef()
  }

  componentDidMount() {
    const configuration = {
      paymentMethodsResponse: this.props.adyenInitialData.payment_methods.message,
      clientKey: this.props.adyenInitialData.client_key,
      locale: this.props.adyenInitialData.locale,
      environment: this.props.adyenInitialData.environment,
      onChange: this.onChange,
      paymentMethodsConfiguration: {
        card: {
          hideCVC: true,
          hasHolderName: true,
          positionHolderNameOnTop: true,
          holderNameRequired: true,
        },
      },
    }
    this.adyenCheckout = new AdyenCheckout(configuration)

    this.adyenCheckout.create('card').mount(this.adyenRootCard.current)
  }

  onChange = adyenState => {
    this.props.receiveToken({
      data: adyenState.isValid ? adyenState.data : null,
      holderName: adyenState?.data?.paymentMethod?.holderName || '',
    })
  }

  render() {
    return (
      <PaymentDiv isValid>
        <div ref={this.adyenRootCard} />
      </PaymentDiv>
    )
  }
}

class AdyenFormFetch extends Component {
  componentDidMount() {
    this.props.fetchAdyenInitialData(this.props.venueId)
  }

  render() {
    if (!this.props.adyenInitialData.client_key || this.props.adyenInitialData.needClean) {
      return null
    }
    return (
      <>
        <GlobalStyle />
        <AdyenFormRender key={this.props.venueId} adyenInitialData={this.props.adyenInitialData} receiveToken={this.props.receiveToken} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  venueId: state.appState.venue.id,
  adyenInitialData: state.bookPaymentState.adyenInitialData,
})

const mapDispatchToProps = {
  fetchAdyenInitialData: PaymentActions.fetchAdyenInitialData,
  receiveToken: BookActions.receiveToken,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdyenFormFetch)
