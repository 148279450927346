import _ from 'lodash'
import * as ActionTypes from 'mgr/actualslideout/actions/ActionTypes'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import { reduceUserDomainVenueAttrs } from 'mgr/lib/utils/AppStateUtils'

export const stepCollapsedStateDefault = {
  availability: true,
  client: true,
  details: false, // Always open
  messaging: false, // Always open
  payment: true,
  source: true,
  upgrades: true,
}
const resetStepCollapsedState = expandedStep => {
  const stepCollapsedState = { ...stepCollapsedStateDefault }
  if (!_.isEmpty(expandedStep)) {
    stepCollapsedState[expandedStep] = false
  }
  return stepCollapsedState
}

export const initialState = {
  isStepCollapsed: resetStepCollapsedState('availability'),
  stepHeightChangeTimestamps: {},
  isStepOrderAvailabilityFirst: true,
  scrollIntoViewStep: null,
  firstInvalidStep: null,
  hasLoadedAvailableTimes: false,
  hasSelectedTimeSlot: false,
  isPreselectedTime: true,
  hasSelectedClient: false,
  hasSelectedSource: false,
  preventCollapseOnPreselectedSortOrder: false,
  selectedVenue: null,
  formErrors: {},
  didDismissBookErrorDisplay: false,
  isFormSubmitting: false,
  isDirty: false,
  isEditMode: false,
  doShowUnsavedWarning: false,
  doShowHigherChargeWarning: false,
  doShowMissingEmailWarning: false,
  missingEmailWarningIgnored: false,
  reservationRequestId: undefined,
}

const bookReducer = (state = initialState, action) => {
  switch (action.type) {
    case GlobalActionTypes.ON_USER_DOMAIN_VENUES_LOAD: {
      const initialUserDomainVenue = _.find(Pmp.Manager.Global.UserDomainVenues.venues, { id: window.globalInit.venueId })
      return {
        ...state,
        initVenue: reduceUserDomainVenueAttrs(initialUserDomainVenue),
        selectedVenue: reduceUserDomainVenueAttrs(initialUserDomainVenue),
      }
    }
    case ActionTypes.ENTER_ADD_RESERVATION: {
      const isStepCollapsed = resetStepCollapsedState('availability')
      return {
        ...state,
        ...initialState,
        scrollIntoViewStep: 'availability',
        selectedVenue: state.initVenue,
        isStepCollapsed,
        isEditMode: false,
        isDirty: false,
        reservationRequestId: action.reservationRequestId,
      }
    }
    case ActionTypes.COPY_VIEW_ACTUAL_DETAILS_TO_BOOK_DETAILS:
      return {
        ...state,
        ...initialState,
        selectedVenue: action.venue,
        hasLoadedAvailableTimes: false,
      }
    case ActionTypes.ENTER_EDIT_RESERVATION: {
      const { editStep, actual } = action
      const expandedStep = editStep || 'availability'
      const isStepCollapsed = resetStepCollapsedState(expandedStep)
      const preventCollapseOnPreselectedSortOrder = !state.hasLoadedAvailableTimes
      const hasSelectedSource = !_.isNil(actual && actual.source_client)
      return {
        ...state,
        isStepCollapsed,
        scrollIntoViewStep: editStep,
        hasSelectedClient: true,
        hasSelectedTimeSlot: true,
        hasSelectedSource,
        preventCollapseOnPreselectedSortOrder,
        isEditMode: true,
        isDirty: false,
      }
    }
    case ActionTypes.ENTER_CLONE_RESERVATION: {
      const isStepCollapsed = resetStepCollapsedState('availability')
      const { actual } = action
      const hasSelectedSource = !_.isNil(actual && actual.source_client)
      return {
        ...state,
        isStepCollapsed,
        scrollIntoViewStep: 'client',
        isStepOrderAvailabilityFirst: false,
        hasSelectedClient: true,
        hasSelectedSource,
        hasSelectedTimeSlot: false,
        isEditMode: false,
        isDirty: true,
      }
    }
    case ActionTypes.BOOK_TOGGLE_STEP: {
      const isStepCollapsed = { ...state.isStepCollapsed }
      isStepCollapsed[action.stepKey] = !state.isStepCollapsed[action.stepKey]
      const isStepOrderAvailabilityFirst =
        state.hasSelectedTimeSlot || state.hasSelectedClient
          ? state.isStepOrderAvailabilityFirst
          : state.hasSelectedTimeSlot || isStepCollapsed.client
      return {
        ...state,
        isStepCollapsed,
        isStepOrderAvailabilityFirst,
        scrollIntoViewStep: action.stepKey,
        preventCollapseOnPreselectedSortOrder: false,
      }
    }
    case ActionTypes.BOOK_CHANGE_BOOK_STEP_HEIGHT: {
      const { stepKey } = action
      const now = new Date().getTime()
      const stepHeightChangeTimestamps =
        stepKey === 'all'
          ? _.reduce(state.isStepCollapsed, (o, isCollapsed, stepKey) => _.assign(o, !isCollapsed && { [stepKey]: now }), {})
          : { ...state.stepHeightChangeTimestamps, [action.stepKey]: now }
      return { ...state, stepHeightChangeTimestamps }
    }
    case ActionTypes.BOOK_CLEAR_SCROLL_INTO_VIEW:
      return {
        ...state,
        scrollIntoViewStep: null,
      }
    case ActionTypes.BOOK_AVAILABILITY_GET_TIMES_FAIL:
    case ActionTypes.BOOK_AVAILABILITY_GET_TIMES_NOT_READY:
    case ActionTypes.BOOK_AVAILABILITY_GET_TIMES_SUCCESS:
      return { ...state, hasLoadedAvailableTimes: true }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_SELECTED_TIME_SLOT: {
      const { venue, selectedTimeSlot, isPreselectedTime, isPreviousTime } = action
      const hasSelectedTimeSlot = _.isObject(selectedTimeSlot)
      const selectedVenue = hasSelectedTimeSlot ? venue : state.selectedVenue
      const formErrors = { ...state.formErrors, selected_time_slot: false }
      // eslint-disable-next-line no-nested-ternary
      let isStepCollapsed = isPreviousTime
        ? state.isStepCollapsed
        : state.hasSelectedClient
        ? { ...state.isStepCollapsed, availability: true }
        : resetStepCollapsedState(state.isStepOrderAvailabilityFirst && !state.hasSelectedClient ? 'client' : 'details')
      // eslint-disable-next-line no-nested-ternary
      let scrollIntoViewStep = isPreviousTime ? null : state.isStepOrderAvailabilityFirst ? 'availability' : 'client'
      if (state.preventCollapseOnPreselectedSortOrder) {
        isStepCollapsed = state.isStepCollapsed
        scrollIntoViewStep = state.scrollIntoViewStep
      }
      return {
        ...state,
        formErrors,
        isStepCollapsed,
        scrollIntoViewStep,
        hasSelectedTimeSlot,
        selectedVenue,
        preventCollapseOnPreselectedSortOrder: false,
        isDirty: !isPreselectedTime,
        isPreselectedTime,
      }
    }
    case ActionTypes.BOOK_AVAILABILITY_HOLD_RESERVATION_FAILURE:
      return {
        ...state,
        isStepCollapsed: resetStepCollapsedState('availability'),
        scrollIntoViewStep: 'availability',
      }

    case ActionTypes.BOOK_CLIENT_CHANGE_SELECTED_CLIENT: {
      const { selectedClient } = action
      const hasSelectedClient = _.isObject(selectedClient)
      const hasSelectedExistingClient = hasSelectedClient && (selectedClient.id || _.isObject(selectedClient.hotel_client))
      const formErrors = { ...state.formErrors, selected_client: false }
      let isStepCollapsed
      if (!hasSelectedExistingClient) {
        isStepCollapsed = state.isStepCollapsed
      } else if (state.hasSelectedTimeSlot || state.isStepCollapsed.availability) {
        isStepCollapsed = { ...state.isStepCollapsed, client: true }
      } else if (!state.hasSelectedTimeSlot && !state.isStepCollapsed.availability) {
        isStepCollapsed = resetStepCollapsedState('availability')
      } else {
        isStepCollapsed = resetStepCollapsedState(
          state.isStepOrderAvailabilityFirst || state.hasSelectedTimeSlot ? 'details' : 'availability'
        )
      }
      return {
        ...state,
        hasSelectedClient,
        isStepCollapsed,
        scrollIntoViewStep: hasSelectedClient && state.isStepOrderAvailabilityFirst ? 'availability' : 'client',
        formErrors,
        isDirty: true,
      }
    }
    case ActionTypes.RECEIVE_TOKEN:
      const formErrors = { ...state.formErrors, credit_card_token: false }
      return {
        ...state,
        formErrors,
      }
    case ActionTypes.BOOK_SOURCE_CHANGE_SELECTED_SOURCE: {
      const { selectedSource } = action
      const hasSelectedSource = _.isObject(selectedSource)
      const hasSelectedExistingSource = hasSelectedSource && selectedSource.id
      const formErrors = { ...state.formErrors, selected_source: false }
      // eslint-disable-next-line no-nested-ternary
      const isStepCollapsed = !hasSelectedExistingSource
        ? state.isStepCollapsed
        : state.hasSelectedTimeSlot
        ? { ...state.isStepCollapsed, source: true }
        : resetStepCollapsedState(state.isStepOrderAvailabilityFirst || state.hasSelectedTimeSlot ? 'details' : 'availability')
      return {
        ...state,
        hasSelectedSource,
        isStepCollapsed,
        scrollIntoViewStep: 'source',
        formErrors,
        isDirty: true,
      }
    }
    case ActionTypes.BOOK_DETAILS_CHANGE_SELECTED_BOOKED_BY: {
      const formErrors = { ...state.formErrors, selected_booked_by: false }
      return { ...state, formErrors, isDirty: true }
    }
    case ActionTypes.BOOK_FORM_VALIDATED: {
      const { formErrors, firstInvalidStep } = action
      return {
        ...state,
        formErrors,
        isStepCollapsed: {
          ...state.isStepCollapsed,
          [firstInvalidStep]: false,
        },
        scrollIntoViewStep: firstInvalidStep,
        firstInvalidStep,
        didDismissBookErrorDisplay: false,
        isFormSubmitting: _.isEmpty(formErrors),
      }
    }
    case ActionTypes.BOOK_CLIENT_CHANGE_CLIENT_FIELD: {
      const formErrors = { ...state.formErrors, [action.field]: false }
      return { ...state, formErrors, isDirty: true }
    }
    case ActionTypes.BOOK_CLIENT_CHANGE_SEARCH_TERMS: {
      const formErrors = { ...state.formErrors }
      return { ...state, formErrors }
    }
    case ActionTypes.BOOK_DISMISS_ERROR_DISPLAY: {
      return { ...state, didDismissBookErrorDisplay: true }
    }
    case ActionTypes.BOOK_CLICK_BOOK_RESERVATION: {
      return { ...state, isFormSubmitting: true }
    }
    case ActionTypes.BOOK_SUBMIT_RESERVATION_SUCCESS:
      return {
        ...state,
        isFormSubmitting: false,
        isDirty: false,
      }
    case ActionTypes.BOOK_SUBMIT_RESERVATION_FAIL:
      return { ...state, isFormSubmitting: false }
    case ActionTypes.CLOSE_SLIDEOUT: {
      return { ...state, doShowUnsavedWarning: state.isDirty }
    }
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_DATE:
    case ActionTypes.RESET_BOOKED_BY_NAMES:
    case ActionTypes.BOOK_AVAILABILITY_CHANGE_PARTY_SIZE:
    case ActionTypes.BOOK_CLIENT_CHANGE_CLIENT_TAGS:
    case ActionTypes.BOOK_SOURCE_CHANGE_SOURCE_TAGS:
    case ActionTypes.BOOK_SOURCE_CHANGE_SOURCE_FIELD:
    case ActionTypes.BOOK_DETAILS_CHANGE_RESERVATION_TAGS:
    case ActionTypes.BOOK_DETAILS_CHANGE_RESERVATION_NOTES:
    case ActionTypes.BOOK_DETAILS_CHANGE_SELECTED_TABLES:
    case ActionTypes.BOOK_DETAILS_ADD_ADDITIONAL_BOOKED_BY:
    case ActionTypes.BOOK_DETAILS_ENABLE_CUSTOM_BOOKED_BY_NAME:
    case ActionTypes.BOOK_DETAILS_CHANGE_CUSTOM_BOOKED_BY_NAME:
    case ActionTypes.BOOK_DETAILS_CHANGE_MESSAGING_FIELD: {
      return { ...state, isDirty: state.isEditMode }
    }
    case ActionTypes.ON_BEFORE_PAGE_UNLOAD: {
      return { ...state, doShowUnsavedWarning: state.isDirty }
    }
    case ActionTypes.DISMISS_UNSAVED_WARNING: {
      return { ...state, doShowUnsavedWarning: false }
    }
    case ActionTypes.IGNORE_UNSAVED_WARNING: {
      return { ...state, doShowUnsavedWarning: false, isDirty: false }
    }
    case ActionTypes.DISPLAY_HIGHER_CHARGE_WARNING: {
      return { ...state, doShowHigherChargeWarning: true, isDirty: false, isFormSubmitting: false }
    }
    case ActionTypes.DISMISS_HIGHER_CHARGE_WARNING: {
      return { ...state, doShowHigherChargeWarning: false, isFormSubmitting: false }
    }
    case ActionTypes.IGNORE_HIGHER_CHARGE_WARNING: {
      return { ...state, doShowHigherChargeWarning: false, isFormSubmitting: false, isDirty: false }
    }
    case ActionTypes.DISPLAY_MISSING_EMAIL_WARNING: {
      return { ...state, doShowMissingEmailWarning: true, isDirty: false, isFormSubmitting: false }
    }
    case ActionTypes.DISMISS_MISSING_EMAIL_WARNING: {
      return { ...state, doShowMissingEmailWarning: false, isFormSubmitting: false }
    }
    case ActionTypes.IGNORE_MISSING_EMAIL_WARNING: {
      return {
        ...state,
        doShowMissingEmailWarning: false,
        missingEmailWarningIgnored: true,
        isFormSubmitting: false,
        isDirty: false,
      }
    }
    default:
      return state
  }
}

export default bookReducer
