import _ from 'lodash'
import React from 'react'
import { render } from 'react-dom'
import { ThemeProvider } from 'styled-components'
import Theme from 'mgr/layout/Theme'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'

import { svrExport } from '@sevenrooms/core/utils'

const VenueDropDown = ({
  venueId,
  venueIds,
  onChange,
  venues,
  venueSearchGroups,
  width,
  height,
  style,
  isLightTheme,
  isMultiSelect,
  name,
  noHeader,
  borderAreaStyle,
}) => {
  let choices = _.isEmpty(venues) ? [] : venues.map(v => ({ name: v.internalDisplayName, value: v.id }))
  const value = venueId
  const values = [...(venueIds || [])]
  let displayOverride
  const allVsgs = venueSearchGroups.private.concat(venueSearchGroups.public)
  const vsgById = _.reduce(allVsgs, (result, vsg) => _.assign(result, { [vsg.id]: vsg }), {})
  if (isMultiSelect) {
    choices.unshift({ name: 'All', value: 'ALL' })
    const allVenueIds = venues.map(v => v.id)
    // Store active venue ids on the vsg for convenience
    venueSearchGroups.public.concat(venueSearchGroups.private).map(vsg => {
      vsg.active_venue_ids = _.intersection(vsg.venue_ids, allVenueIds)
    })
    // Filter out groups that this user doesn't have access to any venues in
    const activePublicGroups = venueSearchGroups.public.filter(vsg => vsg.active_venue_ids.length > 1)
    const activePrivateGroups = venueSearchGroups.private.filter(vsg => vsg.active_venue_ids.length > 1)
    const hasPublicGroups = !_.isEmpty(activePublicGroups)
    const hasPrivateGroups = !_.isEmpty(activePrivateGroups)
    if (hasPublicGroups || hasPrivateGroups) {
      choices = _.compact([
        hasPublicGroups && {
          name: 'GROUPS',
          choices: activePublicGroups.map(vsg => ({
            name: vsg.name,
            value: vsg.id,
          })),
        },
        hasPrivateGroups && {
          name: 'MY GROUPS',
          choices: activePrivateGroups.map(vsg => ({
            name: vsg.name,
            value: vsg.id,
          })),
        },
        { name: 'LOCATIONS', choices },
      ])
      // Add venue search groups to values if all of their venues are selected
      const selectedGroups = allVsgs.filter(
        vsg => vsg.active_venue_ids.length > 1 && _.difference(vsg.active_venue_ids, venueIds).length === 0
      )
      selectedGroups.map(vsg => values.push(vsg.id))
      displayOverride = _.isEmpty(selectedGroups) ? null : selectedGroups.map(vsg => vsg.name).join(', ')
    }
    if (venues.length === venueIds.length) {
      values.push('ALL')
      displayOverride = 'All'
    }
  }
  const onChangeHandler = (values, isAddOrRemove, changedVal) => {
    if (isMultiSelect) {
      // Account for ALL selection/deselection
      if (changedVal === 'ALL') {
        if (isAddOrRemove === 'added') {
          onChange(venues)
        } else {
          onChange([])
        }
        return
      }
      values = values.filter(val => val !== 'ALL')

      const changedGroup = vsgById[changedVal]
      if (!_.isUndefined(changedGroup)) {
        if (isAddOrRemove === 'added') {
          // Expand venue search groups to unique venue ids
          values = _.uniq(values.concat(changedGroup.active_venue_ids))
        } else {
          // Deselect underlying venues for group deselection
          values = values.filter(val => !_.includes(changedGroup.active_venue_ids, val))
        }
      }

      // Convert venue ids to venues
      onChange(venues.filter(v => _.includes(values, v.id)))
      return
    }
    // Single select
    onChange(_.find(venues, { id: values }))
  }
  return (
    <DropdownArrowsPicker
      key="VENUE"
      name={name}
      noHeader={noHeader}
      borderAreaStyle={borderAreaStyle}
      style={{
        float: 'left',
        width,
        ...style,
      }}
      {...{
        value,
        values,
        choices,
        displayOverride,
        onChangeHandler,
        isLightTheme,
        isMultiSelect,
        height,
      }}
    />
  )
}

VenueDropDown.defaultProps = {
  width: 154,
  height: 42,
  onChange: () => {},
  isLightTheme: false,
  isMultiSelect: false,
  venueSearchGroups: { private: [], public: [] },
  noHeader: false,
  name: 'LOCATION',
}

VenueDropDown.propTypes = {
  venueId: React.PropTypes.string, // Single-select
  venueIds: React.PropTypes.array, // Multi-select
  venues: React.PropTypes.array.isRequired,
  venueSearchGroups: React.PropTypes.object.isRequired,
  onChange: React.PropTypes.func.isRequired,
  width: React.PropTypes.number.isRequired,
  height: React.PropTypes.number.isRequired,
  style: React.PropTypes.object,
  noHeader: React.PropTypes.bool.isRequired,
  isLightTheme: React.PropTypes.bool.isRequired,
  isMultiSelect: React.PropTypes.bool.isRequired,
}

export default VenueDropDown

export const mountVenueDropDown = (domId, venueProps) => {
  render(
    <ThemeProvider theme={Theme}>
      <VenueDropDown {...venueProps} />
    </ThemeProvider>,
    document.getElementById(domId)
  )
}
svrExport('Components', 'mountVenueDropDown', mountVenueDropDown)
