import { useState, type FormEvent } from 'react'
import type { VenueProfile } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { Button, Label } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalActions,
  ModalTitle,
  type ModalHeaderProps,
  Box,
} from '@sevenrooms/core/ui-kit/layout'
import { campaignBuilderMessages, emailBuilderMessages } from '@sevenrooms/marketing'
import type { Venue } from '@sevenrooms/mgr-core'
import type { EmailEditorDestinations, VMSEmailEditorDestinations } from '../types'

interface ReservationProps {
  venueData?: {
    venueProfile: VenueProfile | undefined
    venue: Venue | undefined
  }
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  destination: EmailEditorDestinations
  insertLink: (link: { text: string; link: string }) => void
}

export function Reservation({ venueData, insertLink, closeHref, destination }: ReservationProps) {
  const { formatMessage } = useLocales()
  const [text, setText] = useState('')
  const nav = useNavigation()
  const reservationWidgetLinkTemplate = formatMessage(emailBuilderMessages.reservationModalReservationWidgetLinkTemplate)

  const { venue, venueProfile } = venueData || {}

  const doLinkInsertion = () => {
    insertLink({ text, link: venueProfile ? venueProfile.reservationWidgetLink : reservationWidgetLinkTemplate })
    if (venue) {
      nav.push(routes.manager2.marketing.emailCenter.emails.emailBuilder, { params: { venueKey: venue.urlKey } })
    }
  }

  const href = venue
    ? nav.closeSurfaceHref((destination as VMSEmailEditorDestinations).reservationModal, {
        params: { venueKey: venue.urlKey },
      })
    : nav.closeSurfaceHref(destination.reservationModal)

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <ModalTitle
          title={formatMessage(emailBuilderMessages.reservationModalTitle)}
          subTitle={formatMessage(emailBuilderMessages.reservationModalSubTitle)}
        />
      </ModalHeader>
      <ModalBody>
        <Box p="s">
          <Label primary={formatMessage(emailBuilderMessages.reservationTextModalLabel)}>
            <Input
              placeholder={formatMessage(emailBuilderMessages.reservationModalTextInputPlaceholder)}
              onChange={(evnt: FormEvent<HTMLInputElement>) => {
                setText(evnt.currentTarget.value)
              }}
              value={text}
              autoFocus
            />
          </Label>
        </Box>
        <Box p="s">
          <Label primary={formatMessage(emailBuilderMessages.reservationLinkModalLabel)}>
            <Input
              placeholder={formatMessage(emailBuilderMessages.reservationModalInputLinkPlaceholder)}
              value={venueProfile ? venueProfile.reservationWidgetLink : reservationWidgetLinkTemplate}
              disabled
              readOnly
            />
          </Label>
        </Box>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" href={href} data-test="cancel-button">
            {formatMessage(campaignBuilderMessages.modalCancelButton)}
          </Button>
          <Button href={href} onClick={doLinkInsertion} variant="primary" disabled={text.length === 0} data-test="add-link-button">
            {formatMessage(campaignBuilderMessages.modalPrimaryButton)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
