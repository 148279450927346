import FroalaReactEditorView from 'react-froala-wysiwyg/FroalaEditorView'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in MA-857
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button, Label } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import type { Venue } from '@sevenrooms/mgr-core'
import { emailBuilderMessages } from '../../locales'
import type { PropsWithChildren } from 'react'

export interface EmailCampaignPreviewProps {
  template: string
  simpleView?: boolean
  isModal?: boolean
  venue?: Venue
  isLoading?: boolean
  showPreviewOnly?: boolean
}
/**
 * Function responsible for creating email body template with various body parts fields
 * Any update in the below function also requires to update BE function : _generate_email_body_template
 */
export const generateEmailCampaignPreviewTemplate = (
  greeting: string | null,
  body: string | null,
  signature: string | null,
  signOff: string | null,
  footer: string | null
) => [greeting, body, [signature, signOff].filter(Boolean).join(''), footer].filter(Boolean).join('<br/>')

export function EmailCampaignPreview({
  template,
  simpleView = false,
  isModal = false,
  venue,
  showPreviewOnly = false,
  children,
}: PropsWithChildren<EmailCampaignPreviewProps>) {
  const nav = useNavigation()
  const { formatMessage } = useLocales()

  if (simpleView) {
    return (
      <SimpleEditorWrapper>
        <FroalaReactEditorView model={template} />
      </SimpleEditorWrapper>
    )
  }

  const defaultContent = !isModal && (
    <Button
      noPadding
      data-test="email-campaign-preview-send-test-email-modal-button"
      variant="tertiary"
      onClick={() => {
        if (venue) {
          nav.push(routes.manager2.marketing.emailCenter.emails.emailBuilder.sendTestEmailModal, {
            params: { venueKey: venue.urlKey },
          })
        }
      }}
    >
      {formatMessage(emailBuilderMessages.sendTestEmailButtonText)}
    </Button>
  )
  if (showPreviewOnly) {
    return (
      <EmailCampaignPreviewWrapper>
        <VStack spacing="s">
          <Preview>
            <FroalaEditorViewWrapper>
              <FroalaReactEditorView model={template} />
            </FroalaEditorViewWrapper>
          </Preview>
        </VStack>
      </EmailCampaignPreviewWrapper>
    )
  }

  return (
    <EmailCampaignPreviewWrapper>
      <VStack spacing="s">
        <Label
          primary={formatMessage(emailBuilderMessages.emailCampaignPreviewTitle)}
          secondary={formatMessage(emailBuilderMessages.emailCampaignPreviewDescription)}
        />
        <Preview
          onClick={e => {
            e.preventDefault()
          }}
        >
          <FroalaEditorViewWrapper>
            <FroalaReactEditorView model={template} />
          </FroalaEditorViewWrapper>
        </Preview>
        <HStack justifyContent="flex-end">{children ?? defaultContent}</HStack>
      </VStack>
    </EmailCampaignPreviewWrapper>
  )
}

const EmailCampaignPreviewWrapper = styled.div`
  width: 100%;
`

const Preview = styled.div`
  display: flex;
  background: ${theme.colors.secondaryBackground};
  align-items: center;
  padding: 15px 15px;
  height: 100%;
`

const SimpleEditorWrapper = styled.div`
  max-height: calc(100vh - 278px);
  width: 600px;
  overflow: auto;
  border-radius: 4px;
  border: 1px solid ${theme.colors.dividerLines};
  a {
    pointer-events: none;
  }
`

const FroalaEditorViewWrapper = styled.div`
  width: 600px;
  height: 374px;
  background: white;
  border: 1px solid ${theme.colors.borders};
  padding: 15px;
  overflow: auto;
  margin: auto;
  a {
    pointer-events: none;
  }
  p {
    margin: 0;
  }
  font-size: 14px;
`
