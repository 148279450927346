import type { GuestFacingUpgrade, GuestFacingUpgradeCategory, ReservationWidget } from '@sevenrooms/core/domain'
import type { PaymentForm } from '@sevenrooms/mgr-reservation-slideout/Payment'
import { type Transaction, TransactionStatus, type UpgradeInventoryTransaction } from '../../reducers/BookPaymentSlice.types'

interface InitPreviousPaymentParams {
  allTransactions: Transaction[]
  upgrades: GuestFacingUpgrade
  taxGroups: ReservationWidget.TaxGroup[]
}

export function initPreviousPayment({ allTransactions, upgrades, taxGroups }: InitPreviousPaymentParams): PaymentForm {
  const categories = {} as PaymentForm['categories']
  const categoriesBundled = {} as PaymentForm['categoriesBundled']
  let amount = 0
  let charges = {} as PaymentForm['charges']
  const transaction = allTransactions?.find(
    t => t.upsell_items && (t.transaction_type === 'transaction_type_internal' || t.transaction_type === 'transaction_type_web')
  )
  if (transaction) {
    const taxId = transaction.reservation_tax_group_id ?? taxGroups.find(group => group.taxRate === transaction.reservation_tax)?.id
    amount = allTransactions
      .filter(t => t.is_charge && t.status === TransactionStatus.SUCCEEDED)
      .reduce((acc, t) => acc + t.reservation_base_price, 0)
    charges = {
      gratuity: Number(transaction.gratuity),
      gratuityClientSelect: transaction.client_select_gratuity,
      requireGratuityCharge: transaction.require_select_gratuity,
      service: Number(transaction.service_charge),
      taxId: taxId ?? null,
      applyGratuity: !!transaction.gratuity,
      applyService: !!transaction.service_charge,
      applyTax: !!taxId,
    }
    const selectedUpgrades = Object.entries(transaction.upsell_items?.selected_upsells ?? {})
    const bundledUpgrades = Object.entries(transaction.upsell_items?.automatic_upsells ?? {})
    upgrades.categories.forEach(category => {
      const upgradesFiltered = selectedUpgrades.filter(([, upgrade]) => upgrade.category_id === category.id)
      const resultCategory = getCategoryForPreviousPayment({ upgrades: upgradesFiltered, category })
      if (resultCategory) {
        categories[category.id] = resultCategory
      }
      const bundledUpgradesFiltered = bundledUpgrades.filter(([, upgrade]) => upgrade.category_id === category.id)
      const resultCategoryBundled = getCategoryForPreviousPayment({ upgrades: bundledUpgradesFiltered, category })
      if (resultCategoryBundled) {
        categoriesBundled[category.id] = resultCategoryBundled
      }
    })
  }
  return { amount, charges, categories, categoriesBundled }
}

interface GetCategoryForPreviousPaymentParams {
  upgrades: [string, UpgradeInventoryTransaction][]
  category: GuestFacingUpgradeCategory
}

function getCategoryForPreviousPayment({ upgrades, category }: GetCategoryForPreviousPaymentParams) {
  return upgrades[0]
    ? {
        name: category.name,
        upgrades: Object.fromEntries(
          upgrades.map(([id, upgrade]) => {
            const count = upgrade?.quantity ?? upgrade?.quantity_num ?? 0
            return [id, { name: upgrade.name, count, amount: upgrade.price * count }]
          })
        ),
        charges: {
          gratuity: upgrades[0][1].gratuity,
          service: upgrades[0][1].service_charge_percentage,
          taxId: upgrades[0][1].tax_group_id,
          applyGratuity: !!upgrades[0][1].gratuity,
          applyService: !!upgrades[0][1].service_charge_percentage,
          applyTax: !!upgrades[0][1].tax_group_id,
          gratuityClientSelect: category.gratuityChargeType === 'CLIENT_GRATUITY',
          requireGratuityCharge: category.requireGratuityCharge,
        },
      }
    : undefined
}
