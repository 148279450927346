import { routerMiddleware } from 'connected-react-router'
import { applyMiddleware, createStore, compose } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import thunk from 'redux-thunk'
import rootReducer from 'mgr/pages/reducers/CombineReducer'
import history from 'mgr/pages/shared/utils/History'
import { handleAction } from 'mgr/pages/single-venue/dayview/actions/Epics'
import { getInitialDayViewState } from 'mgr/pages/single-venue/dayview/reducers/DayviewReducer'
import { getInitialFiltersState } from 'mgr/pages/single-venue/marketing/reducers/Filters'
import queryString from 'query-string'
import { bookingAccessOverrideListApi } from '@sevenrooms/lib-override-series-list'

import {
  accessRuleApi,
  activityLogApi,
  genericTagGroupsApi,
  emailCampaignActivityApi,
  emailCampaignImageApi,
  emailCampaignsApi,
  ordersApi,
  paymentApi,
  policyApi,
  promoCodeManagementApi,
  reservationWidgetSettingsApi,
  reservationWidgetSettingsApiV2,
  shiftApi,
  taxRateApi,
  venueApi,
  userApi,
  perksApi,
  conciergesApi,
  iVvyApi,
  seatingAreasTablesApi,
  insightsApi,
  apiMiddleware,
  subscriptionWidgetApi,
  orderingRoomNumbersApi,
  referralApi,
  smsCampaignActivityApi,
  smsCampaignsApi,
  posiApi,
  experiencesApi,
  marketingStatusApi,
  guestSatisfactionApi,
  paymentsSettingsApi,
  availabilityDebuggerApi,
  backwriterApi,
  customAudienceApi,
} from '@sevenrooms/core/api'
import { venueSettingsApi } from '@sevenrooms/mgr-settings-general/VenueSettings/hooks'
import _ from 'lodash'

export const store = createStore(
  enableBatching(rootReducer),
  {
    dayviewState: {
      ...getInitialDayViewState(),
      date: globalInit.dateSelected,
      shiftCategory: queryString.parse(history.location.search).shift,
    },
    reviews: {
      filters: {
        ...getInitialFiltersState(
          history,
          globalInit.venueSettings ? globalInit.venueSettings.guest_satisfaction_configured_review_sites : []
        ),
      },
    },
    emails: {
      filters: {
        ...getInitialFiltersState(
          history,
          globalInit.venueSettings ? globalInit.venueSettings.guest_satisfaction_configured_review_sites : []
        ),
      },
    },
    autotags: {
      filters: {
        ...getInitialFiltersState(
          history,
          globalInit.venueSettings ? globalInit.venueSettings.guest_satisfaction_configured_review_sites : []
        ),
      },
    },
  },
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(
    applyMiddleware(thunk),
    applyMiddleware(routerMiddleware(history)),
    applyMiddleware(accessRuleApi.middleware),
    applyMiddleware(availabilityDebuggerApi.middleware),
    applyMiddleware(backwriterApi.middleware),
    applyMiddleware(bookingAccessOverrideListApi.middleware),
    applyMiddleware(genericTagGroupsApi.middleware),
    applyMiddleware(emailCampaignActivityApi.middleware),
    applyMiddleware(emailCampaignImageApi.middleware),
    applyMiddleware(emailCampaignsApi.middleware),
    applyMiddleware(ordersApi.middleware),
    applyMiddleware(paymentApi.middleware),
    applyMiddleware(policyApi.middleware),
    applyMiddleware(promoCodeManagementApi.middleware),
    applyMiddleware(reservationWidgetSettingsApi.middleware),
    applyMiddleware(reservationWidgetSettingsApiV2.middleware),
    applyMiddleware(shiftApi.middleware),
    applyMiddleware(taxRateApi.middleware),
    applyMiddleware(venueApi.middleware),
    applyMiddleware(userApi.middleware),
    applyMiddleware(venueSettingsApi.middleware),
    applyMiddleware(perksApi.middleware),
    applyMiddleware(conciergesApi.middleware),
    applyMiddleware(iVvyApi.middleware),
    applyMiddleware(seatingAreasTablesApi.middleware),
    applyMiddleware(subscriptionWidgetApi.middleware),
    applyMiddleware(insightsApi.middleware),
    applyMiddleware(paymentsSettingsApi.middleware),
    applyMiddleware(experiencesApi.middleware),
    applyMiddleware(orderingRoomNumbersApi.middleware),
    applyMiddleware(referralApi.middleware),
    applyMiddleware(smsCampaignActivityApi.middleware),
    applyMiddleware(smsCampaignsApi.middleware),
    applyMiddleware(posiApi.middleware),
    applyMiddleware(marketingStatusApi.middleware),
    applyMiddleware(guestSatisfactionApi.middleware),
    applyMiddleware(customAudienceApi.middleware), //
    applyMiddleware(activityLogApi.middleware),
    ...apiMiddleware.map(m => applyMiddleware(m)),
    // due to we combining old redux + redux toolkit we have odd behaviour with toolkit caching
    // (doesn't work or getting errors related with async actions)
    // as workaround - to apply thunk middleware before and after adding middlewares from redux toolkit
    applyMiddleware(thunk)
  )
)

/*
handle 'epics' - taking actions and dispatching new ones
(assumes lastAction reducer is installed)
*/
store.subscribe(() => {
  const state = store.getState()

  if (_.isEmpty(state.lastAction)) {
    return
  }

  handleAction(state.lastAction, store.dispatch, store.getState)
})
