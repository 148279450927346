import _ from 'lodash'
import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import MomentPropTypes from 'react-moment-proptypes'
import onClickOutside from 'react-onclickoutside'
import styled, { css } from 'styled-components'
import { LoadingSpinner } from 'mgr/layout/StyledComponentUtils'
import SegmentedControl from 'mgr/lib/components/SegmentedControl'
import TextInput from 'mgr/lib/forms/TextInput'
import VmsIcons, { StyledVmsIconS, StyledVmsIconM } from 'svr/common/VmsIcons'
import Button from 'svr/component-lib/Generic/Buttons/StandardButton'

export const popoverWidth = 472

export const PopoverPositioning = styled.div`
  position: absolute;
  z-index: 5;
  top: ${props => (props.isCondensed ? '20px' : '36px')};
  left: ${props => (props.isCondensed && props.leftOffset ? props.leftOffset - 15 : -10)}px;
  height: 300px;
  width: ${popoverWidth}px;
`

const SidePanePositioning = styled.div`
  position: fixed;
  top: 44px;
  right: 802px;
  bottom: 5px;
  width: 472px;
`

const PopoverWrapper = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background-color: ${props => props.theme.background};
  color: ${props => props.theme.navigationDark};
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
`

export const triangleSize = 50

const PopoverTriangle = styled.div`
  position: relative;
  left: ${props =>
    props.isEditable
      ? `calc(50% - ${triangleSize / 2}px);`
      : props.isCondensed
      ? props.leftOffset
        ? `${3 - props.leftOffset}px;`
        : '0px'
      : '0px;'}

  width: ${triangleSize}px;
  height: ${triangleSize / 2}px;
  overflow: hidden;
  box-shadow: 0 16px 10px -17px rgba(0, 0, 0, 0.5);

  &::after {
    content: '';
    position: absolute;
    width: ${triangleSize / 2}px;
    height: ${triangleSize / 2}px;
    background: ${props => (props.isEditable ? props.theme.color.black : props.theme.color.white)};
    transform: rotate(45deg);
    top: ${triangleSize / 4}px;
    left: ${triangleSize / 4}px;
    box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
  }
`

const PopoverHeader = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  line-height: 42px;
  flex-shrink: 0;
  background-color: ${props => props.theme.navigationDark};
  color: ${props => props.theme.white};
  font-size: 18px;
  font-weight: 500;
  padding: 0 ${props => props.theme.gutter.double};
`

const PopoverHeaderText = styled.div`
  flex: 1;
`

const NoteEventsDisplay = styled.div`
  border-top: 1px solid ${props => props.theme.mediumGrey};
  min-height: 142px;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  font-size: 14px;
  padding: ${props => props.theme.gutter.double};
`

const SegmentedControlStyleds = `
  flex-shrink: 0;
`

const SingleEventWrapper = styled.div`
  margin-bottom: 12px;
`

const EventTitle = styled.span`
  font-weight: 800;
  font-size: 20px;
`

const EventFieldLine = styled.div`
  margin-top: 8px;
`

const EventFieldName = styled.span`
  font-size: 15px;
  font-weight: 800;
  margin-right: 6px;
`

const EventFieldValue = styled.span`
  font-weight: 400;
  white-space: pre-line;
`

const HorizontalLine = styled.div`
  border-bottom: 1px solid ${props => props.theme.darkGrey};
  margin: 10px 0;
`

const ActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.theme.gutter.double};

  & > button:not(:last-child) {
    margin-right: ${props => props.theme.gutter.standard};
  }
`

const ActionButtonStyles = css`
  padding: ${props => props.theme.gutter.standard};
  height: auto;
  line-height: normal;
  font-size: ${props => props.theme.fontSize.small};
  font-weight: 700;
`

const SaveButton = css`
  ${ActionButtonStyles}
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.black};
`

const CancelButton = css`
  ${ActionButtonStyles}
  border: 1px solid ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.color.black};
`

const AddNoteContainer = styled.div`
  text-align: center;
  transform: translate(0, calc(50% - ${props => props.theme.gutter.standard}));
  cursor: pointer;
  transition: 0.3s opacity;

  &:hover {
    opacity: 0.7;
  }
`

// TODO update colors when colors PR gets merged
const LargeText = styled.div`
  color: rgb(51, 145, 215);
  font-size: ${props => props.theme.fontSize.subheader};
`

const AddIcon = styled(StyledVmsIconM)`
  margin-bottom: ${props => props.theme.gutter.standard};
  color: rgb(51, 145, 215);
`

const EditIcon = styled(StyledVmsIconS)`
  cursor: pointer;
  transition: 0.3s opacity;

  &:hover {
    opacity: 0.7;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow: hidden;
`

const SpinnerContainer = styled.div`
  width: 100%;
  height: 150px;
  transform: translate(0, calc(50% - ${props => props.theme.gutter.standard}));
`

const Textarea = styled.div`
  textarea {
    line-height: 16px;
  }
`

export class EventsPopover extends PureComponent {
  constructor(props) {
    super(props)
    const { dailyEvents, isEditable } = this.props
    const { date, events, note } = dailyEvents
    const hasEvents = !_.isEmpty(events)
    const hasNote = !_.isEmpty(note)
    this.state = {
      segCtrlValue: hasNote || !hasEvents ? 'note' : 'events',
      isAddEditNoteMode: !hasNote && !hasEvents && isEditable,
    }

    this.handleSegCtrlValueChanged = value => this.setState({ segCtrlValue: value })

    this.toggleAddEditMode = () => this.setState({ isAddEditNoteMode: true })

    this.handleCancelClick = this.handleCancelClick.bind(this)
    this.handleSaveClick = this.handleSaveClick.bind(this)
    this.handleDailyNoteChange = this.handleDailyNoteChange.bind(this)
  }

  handleCancelClick() {
    const { dailyEvents, onDailyNoteChange, isSaveLoading } = this.props
    const { note } = dailyEvents

    if (!isSaveLoading) {
      onDailyNoteChange(note)
      this.setState({ isAddEditNoteMode: false })
    }
  }

  handleSaveClick() {
    const { onSaveClick, isSaveLoading } = this.props

    if (!isSaveLoading) {
      onSaveClick()
      this.setState({ isAddEditNoteMode: false })
    }
  }

  handleDailyNoteChange(val) {
    const { onDailyNoteChange } = this.props

    onDailyNoteChange(val)
  }

  render() {
    const {
      venue,
      dailyEvents,
      useSidePaneWindow,
      isCondensed,
      isEditable,
      parentElement,
      customPositionElement,
      updatedDailyNote,
      isSaveLoading,
    } = this.props
    const { segCtrlValue, isAddEditNoteMode } = this.state
    const { date, events, note } = dailyEvents
    const hasEvents = !_.isEmpty(events)
    const hasNote = !_.isEmpty(note)
    const availableWidth = _.isNil(parentElement) ? 9999 : window.innerWidth - parentElement.getBoundingClientRect().left
    const popoverWidthWithPadding = popoverWidth + 25
    const leftOffset = availableWidth > popoverWidthWithPadding ? 0 : availableWidth - popoverWidthWithPadding
    const EventsPositioning = customPositionElement || (useSidePaneWindow ? SidePanePositioning : PopoverPositioning)

    const programLocalized = `program${venue.locale === 'en_GB' ? 'me' : ''}`
    const notesEventsSegCtrlOptions = [
      { name: 'DAILY NOTES', value: 'note', testId: 'sr-subheader-note' },
      {
        name: `${programLocalized.toUpperCase()} (${events.length})`,
        value: 'events',
        testId: 'sr-subheader-events',
      },
    ]
    const eventField = (name, value) =>
      _.isEmpty(value) ? null : (
        <EventFieldLine key={name}>
          <EventFieldName>{name}</EventFieldName>
          <EventFieldValue>{value}</EventFieldValue>
        </EventFieldLine>
      )
    const displayEvents = () => {
      let isFirst = true
      return events.map(event => {
        const horizLine = !isFirst && <HorizontalLine />
        isFirst = false
        return (
          <div key={event.id}>
            {horizLine}
            <SingleEventWrapper>
              <EventTitle data-test="sr-program-title">{event.title}</EventTitle>
              {eventField('Shifts:', event.shift_categories_display)}
              {eventField('Cuisine:', event.cuisine)}
              {eventField('Description:', event.description)}
              {eventField('Price point:', event.price_point)}
              {eventField('Dress code:', event.dress_code)}
              {eventField('Table holding policy:', event.table_holding_policy)}
              {eventField('Spend policy:', event.spend_policy)}
              {eventField('Child policy:', event.child_policy)}
            </SingleEventWrapper>
          </div>
        )
      })
    }
    const noteText = hasNote ? note : 'No daily note yet.'
    const formattedDate = date.format(venue.locale === 'en_US' ? 'MMMM Do' : 'D MMMM')
    const formattedDateWithDay = date.format(venue.locale === 'en_US' ? 'dddd, MMMM Do' : 'dddd, D MMMM')

    let headerText = ''

    if (isAddEditNoteMode) {
      if (hasNote) {
        headerText = 'Edit Daily Note'
      } else {
        headerText = 'Add a Daily Note'
      }
    } else if (moment(date).isSame(moment(), 'day')) {
      headerText = `Today, ${formattedDate}`
    } else if (moment(date).isSame(moment().subtract(1, 'days'), 'day')) {
      headerText = `Yesterday, ${formattedDate}`
    } else if (moment(date).isSame(moment().add(1, 'days'), 'day')) {
      headerText = `Tomorrow, ${formattedDate}`
    } else {
      headerText = formattedDateWithDay
    }

    return (
      <EventsPositioning data-test="sr-widget-availability_day_events" {...{ isCondensed, leftOffset }}>
        {!useSidePaneWindow && <PopoverTriangle isEditable={isEditable} isCondensed={isCondensed} leftOffset={leftOffset} />}

        <PopoverWrapper>
          {isEditable && (
            <PopoverHeader>
              <PopoverHeaderText data-test="sr-label-header">{headerText}</PopoverHeaderText>
              {!isAddEditNoteMode && hasNote && <EditIcon onClick={this.toggleAddEditMode}>{VmsIcons.EditLine}</EditIcon>}
            </PopoverHeader>
          )}
          {!isAddEditNoteMode ? (
            <ContentContainer>
              <SegmentedControl
                testId="subsection"
                options={notesEventsSegCtrlOptions}
                value={segCtrlValue}
                width={isCondensed ? 300 : 472}
                height={41}
                theme="nav"
                styleds={SegmentedControlStyleds}
                onChangeHandler={this.handleSegCtrlValueChanged}
              />
              <NoteEventsDisplay data-test="sr-content">
                {segCtrlValue === 'note' ? (
                  isSaveLoading ? (
                    <SpinnerContainer>
                      <LoadingSpinner />
                    </SpinnerContainer>
                  ) : isEditable && !hasNote ? (
                    <AddNoteContainer onClick={this.toggleAddEditMode}>
                      <AddIcon>{VmsIcons.AddLine}</AddIcon>
                      <LargeText>Add Note</LargeText>
                    </AddNoteContainer>
                  ) : (
                    <EventFieldValue>{noteText}</EventFieldValue>
                  )
                ) : (
                  displayEvents()
                )}
              </NoteEventsDisplay>
            </ContentContainer>
          ) : (
            <NoteEventsDisplay>
              {isSaveLoading ? (
                <SpinnerContainer>
                  <LoadingSpinner />
                </SpinnerContainer>
              ) : (
                <Textarea>
                  <TextInput
                    charLimit={5000}
                    isMultiLine
                    forceIndent
                    resizable={false}
                    minRows={9}
                    maxRows={9}
                    useOutsideLabel={false}
                    showLabel={false}
                    placeholder="Add your daily note"
                    value={updatedDailyNote}
                    onChange={this.handleDailyNoteChange}
                  />
                </Textarea>
              )}
              <ActionsContainer>
                <Button customButton={CancelButton} onClick={this.handleCancelClick}>
                  Cancel
                </Button>
                <Button customButton={SaveButton} onClick={this.handleSaveClick}>
                  Save
                </Button>
              </ActionsContainer>
            </NoteEventsDisplay>
          )}
        </PopoverWrapper>
      </EventsPositioning>
    )
  }
}

EventsPopover.propTypes = {
  venue: React.PropTypes.object.isRequired,
  dailyEvents: React.PropTypes.object.isRequired,
  useSidePaneWindow: React.PropTypes.bool.isRequired,
  isCondensed: React.PropTypes.bool.isRequired,
  isEditable: React.PropTypes.bool.isRequired,
  parentElement: React.PropTypes.object,
  toggleAddEditMode: React.PropTypes.func,
  handleCancelClick: React.PropTypes.func,
  handleSaveClick: React.PropTypes.func,
  handleDailyNoteChange: React.PropTypes.func,
  isSaveLoading: React.PropTypes.bool.isRequired,
}

EventsPopover.defaultProps = {
  useSidePaneWindow: false,
  isCondensed: false,
  isEditable: false,
  isSaveLoading: false,
}

const DayEventsWrapper = styled.div`
  position: relative;
`

const PreviewWrapper = styled.div`
  height: 42px;
  line-height: 42px;
  color: ${props => props.theme.navigationDark};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  margin: 3px -12px 12px;
  padding: 0 24px;
  border-top: 1px solid ${props => props.theme.margin};
  border-bottom: 1px solid ${props => props.theme.margin};
  ${props => props.theme.clearFix};
`

const IconContainer = styled.div`
  width: 24px;
  float: left;
`

const TruncatedPreview = styled.div`
  width: 180px;
  float: left;
  margin-right: 15px;
  ${props => props.theme.ellipsis};
`

const RemainingLinePreview = styled.div`
  overflow: hidden;
  ${props => props.theme.ellipsis};
`

const CondensedDayEventsWrapper = styled.div`
  position: relative;
  width: 24px;
  float: left;
`

const CondensedPreviewWrapper = styled.div`
  color: ${props => props.theme.navigationDark};
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  margin: 0 5px;
  ${props => props.theme.clearFix};
`

class AvailabilityDayEvents extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isPopoverShowing: false,
      useSidePaneWindow: this.shouldUseSidePaneWindow(),
    }
    this.handleWindowResize = this.handleWindowResize.bind(this)
    this.handleClickPreview = this.handleClickPreview.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  shouldUseSidePaneWindow() {
    return window.innerWidth > 1300
  }

  handleWindowResize() {
    const useSidePaneWindow = this.shouldUseSidePaneWindow()
    if (this.state.useSidePaneWindow !== useSidePaneWindow) {
      this.setState({ useSidePaneWindow })
    }
  }

  componentDidMount() {
    this.handleWindowResize()
    window.addEventListener('resize', this.handleWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize)
  }

  handleClickPreview() {
    this.setState({ isPopoverShowing: !this.state.isPopoverShowing })
  }

  handleClickOutside() {
    if (this.state.isPopoverShowing) {
      this.setState({ isPopoverShowing: false })
    }
  }

  render() {
    const { venue, dailyEvents, isCondensed, showAdditionalSidePaneWindow } = this.props
    const { isPopoverShowing, useSidePaneWindow } = this.state
    const { events, note } = dailyEvents
    const hasEvents = !_.isEmpty(events)
    const hasNote = !_.isEmpty(note)
    if (!hasEvents && !hasNote) {
      return null
    }
    const popover = isPopoverShowing && <EventsPopover {...{ venue, dailyEvents, isCondensed }} parentElement={this.popoverParentElement} />
    if (isCondensed) {
      return (
        <CondensedDayEventsWrapper ref={e => (this.popoverParentElement = ReactDOM.findDOMNode(e))}>
          <CondensedPreviewWrapper onClick={this.handleClickPreview}>
            {!hasNote ? <StyledVmsIconS>{VmsIcons.Event}</StyledVmsIconS> : <StyledVmsIconS>{VmsIcons.Note}</StyledVmsIconS>}
          </CondensedPreviewWrapper>
          {popover}
          {showAdditionalSidePaneWindow && <EventsPopover {...{ venue, dailyEvents, useSidePaneWindow }} />}
        </CondensedDayEventsWrapper>
      )
    }
    if (useSidePaneWindow) {
      // Show a side pane window, no preview
      return <EventsPopover {...{ venue, dailyEvents, useSidePaneWindow }} />
    }
    const truncatedPreview =
      hasEvents && hasNote ? (
        <span>
          <IconContainer>
            <StyledVmsIconS>{VmsIcons.Event}</StyledVmsIconS>
          </IconContainer>
          <TruncatedPreview>{events[0].title}</TruncatedPreview>
        </span>
      ) : null
    const remainingLine = !hasNote ? (
      <span>
        <IconContainer>
          <StyledVmsIconS>{VmsIcons.Event}</StyledVmsIconS>
        </IconContainer>
        <RemainingLinePreview>{events[0].title}</RemainingLinePreview>
      </span>
    ) : (
      <span>
        <IconContainer>
          <StyledVmsIconS>{VmsIcons.Note}</StyledVmsIconS>
        </IconContainer>
        <RemainingLinePreview>{note}</RemainingLinePreview>
      </span>
    )
    return (
      <DayEventsWrapper>
        <PreviewWrapper onClick={this.handleClickPreview}>
          {truncatedPreview}
          {remainingLine}
        </PreviewWrapper>
        {popover}
      </DayEventsWrapper>
    )
  }
}

AvailabilityDayEvents.defaultProps = {
  dailyEvents: {},
  isCondensed: false,
  showAdditionalSidePaneWindow: false,
}

AvailabilityDayEvents.propTypes = {
  venue: React.PropTypes.object.isRequired,
  dailyEvents: React.PropTypes.object.isRequired,
  isCondensed: React.PropTypes.bool.isRequired,
  showAdditionalSidePaneWindow: React.PropTypes.bool.isRequired,
  onSaveClick: React.PropTypes.func,
}

export default onClickOutside(AvailabilityDayEvents)
