import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchShift4InitialData, initI4goTrueTokenObj } from 'svr/component-lib/Widget/Payments/Shift4/services'
import { postCheckoutFailure } from 'widget/paylink/actions/services'
import { setPaymentEngine } from 'svr/component-lib/Widget/Payments/actions'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Spinner } from '@sevenrooms/core/ui-kit/layout'

let retrieveToken

export const shift4TokenPromiseQueue = []

class Shift4FormCheckout extends Component {
  constructor(props) {
    super(props)
    this.shift4RootCard = React.createRef()
  }

  componentDidMount() {
    shift4TokenPromiseQueue.push(
      new Promise(resolve => {
        retrieveToken = resolve
      })
    )
    const { venueInfo, setPaymentEngine, postCheckoutFailure, onSuccess } = this.props
    fetchShift4InitialData(venueInfo.id).then(
      response => {
        if (response?.i4go_accessblock) {
          const i4goTrueTokenObj = initI4goTrueTokenObj(response, this.onComplete(onSuccess), this.onComplete(onSuccess))
          setPaymentEngine(i4goTrueTokenObj)
          this.hideLoadingIndicator()
        } else {
          postCheckoutFailure('Unable to load payment form. Please try again')
        }
      },
      error => {
        postCheckoutFailure('Unable to load payment form. Please try again')
      }
    )
  }

  onComplete(onSuccess) {
    return (form, data) => {
      const token = data?.i4go_uniqueid
      retrieveToken({ token, data })

      shift4TokenPromiseQueue.push(
        new Promise(resolve => {
          retrieveToken = resolve
        })
      )

      return onSuccess && onSuccess(token, data)
    }
  }

  hideLoadingIndicator() {
    const shift4iframe = document.getElementById('i4goFrame').querySelector('iframe')
    shift4iframe.onload = function () {
      document.getElementById('i4goMainForm').querySelector('img').style.display = 'none'
    }
  }

  render() {
    return (
      <Shift4MainForm id="i4goMainForm" ref={this.shift4RootCard} isDining={this.props.isDining} isSlideOut={this.props.isSlideOut}>
        <Spinner size="xs" />
        <Shift4IFrame id="i4goFrame" isDining={this.props.isDining} isSlideOut={this.props.isSlideOut} />
      </Shift4MainForm>
    )
  }
}

Shift4FormCheckout.propTypes = {
  onSuccess: PropTypes.func,
  isDining: PropTypes.bool || undefined,
  isSlideOut: PropTypes.bool || undefined,
}

const mapStateToProps = state => ({
  venueInfo: state.venueInfo || state.appState.venue,
})

const mapDispatchToProps = dispatch => ({
  setPaymentEngine: paymentEngine => {
    dispatch(setPaymentEngine(paymentEngine))
  },
  postCheckoutFailure: errorMessage => {
    dispatch(postCheckoutFailure(errorMessage))
  },
})

const Shift4MainForm = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: ${props => (props.isDining ? 'rgba(0, 0, 0, 0.39) 0 0 10px -2px;' : 'none')};
  width: ${props => (props.isDining || props.isSlideOut ? '100%' : '89%')};
  margin: 0 auto;
  min-height: 200px;
  text-align: center;

  img {
    align-content: center;
    position: absolute;
    margin: 88px 0 0 -12px;
  }
`

const Shift4IFrame = styled.div`
  width: ${props => (props.isDining ? '96%' : '98%')};
  padding-top: ${props => (props.isSlideOut ? '12px' : '34px')};
  margin: 0 auto;
  position: relative;
`

export default connect(mapStateToProps, mapDispatchToProps)(Shift4FormCheckout)
