import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { appStateReducer, lastActionReducer, loadingReducer, statusMessageReducer, navReducer } from 'mgr/lib/reducers/UtilReducers'
import lookerReducer from 'mgr/pages/reducers/Looker'
import tagsReducer from 'mgr/pages/reducers/Tags'
import history from 'mgr/pages/shared/utils/History'
import dayviewReducer from 'mgr/pages/single-venue/dayview/reducers/DayviewReducer'
import { conflictDialogReducer } from 'mgr/pages/single-venue/dayview/reducers/DialogReducer'
import { addDropDownReducer, statusDropDownReducer } from 'mgr/pages/single-venue/dayview/reducers/DropdownReducer'
import floorplanReducer from 'mgr/pages/single-venue/dayview/reducers/FloorplanReducer'
import gridReducer from 'mgr/pages/single-venue/dayview/reducers/GridReducer'
import autotagsReducer from 'mgr/pages/single-venue/marketing/reducers/Autotags'
import emailsReducer from 'mgr/pages/single-venue/marketing/reducers/EmailCenter'
import experiencesReducer from 'mgr/pages/single-venue/marketing/reducers/Experiences'
import landingPageSettingsReducer from 'mgr/pages/single-venue/marketing/reducers/LandingPageSettings'
import perksReducer from 'mgr/pages/single-venue/marketing/reducers/Perks'
import reviewsReducer from 'mgr/pages/single-venue/marketing/reducers/Reviews'
import guestCenterImportReducer from 'mgr/pages/single-venue/reporting/reducers/GuestCenterImport'
import outgoingEmailsReducer from 'mgr/pages/single-venue/reporting/reducers/OutgoingEmails'
import searchReducer from 'mgr/pages/single-venue/search/reducers/Search'
import bookingChannelsReducer from 'mgr/pages/single-venue/settings/reducers/BookingChannels'
import KountaReducer from 'mgr/pages/single-venue/settings/reducers/Kounta'
import languagesReducer from 'mgr/pages/single-venue/settings/reducers/Languages'
import languageStringsReducer from 'mgr/pages/single-venue/settings/reducers/LanguageStrings'
import LightspeedReducer from 'mgr/pages/single-venue/settings/reducers/Lightspeed'
import orderingBrandSettingsReducer from 'mgr/pages/single-venue/settings/reducers/ordering/BrandSettings'
import orderingCheckoutReducer, { type OrderingCheckoutState } from 'mgr/pages/single-venue/settings/reducers/ordering/Checkout'
import orderingCheckoutFeeReducer from 'mgr/pages/single-venue/settings/reducers/ordering/CheckoutFee'
import deliveryIntegrationsReducer from 'mgr/pages/single-venue/settings/reducers/ordering/DeliveryIntegrations'
import { orderingProductsReducer, type Product86State } from 'mgr/pages/single-venue/settings/reducers/ordering/EightySixProduct'
import OrderingMenuReducer, { type OrderingMenuState } from 'mgr/pages/single-venue/settings/reducers/ordering/OrderingMenu'
import orderingMenusListReducer, { type OrderingMenusListState } from 'mgr/pages/single-venue/settings/reducers/ordering/OrderingMenusList'
import orderingSiteReducer from 'mgr/pages/single-venue/settings/reducers/ordering/OrderingSite'
import orderingSitesReducer from 'mgr/pages/single-venue/settings/reducers/ordering/OrderingSites'
import orderingOrderPacingReducer, { type OrderPacingState } from 'mgr/pages/single-venue/settings/reducers/ordering/OrderPacing'
import orderingProductInventoryReducer, {
  type ProductInventoryState,
} from 'mgr/pages/single-venue/settings/reducers/ordering/ProductInventory'
import ReservationAndClientImportReducer from 'mgr/pages/single-venue/settings/reducers/ReservationAndClientImport'
import podsReducer from 'mgr/pages/venue-group/reducers/Pods'
import rolesReducer from 'mgr/pages/venue-group/reducers/Roles'
import usersReducer, { type initialState as usersReducerState } from 'mgr/pages/venue-group/reducers/Users'
import {
  accessRuleApi,
  activityLogApi,
  clientMarketingPreferencesApi,
  genericTagGroupsApi,
  emailCampaignActivityApi,
  emailCampaignImageApi,
  emailCampaignTemplateApi,
  emailCampaignsApi,
  googleReviewApi,
  languageApi,
  ordersApi,
  paymentApi,
  podApi,
  policyApi,
  promoCodeManagementApi,
  reservationWidgetSettingsApi,
  reservationWidgetSettingsApiV2,
  subscriptionWidgetApi,
  subscriptionWidgetSettingsApi,
  shiftApi,
  upsellsApi,
  taxRateApi,
  venueApi,
  userApi,
  perksApi,
  conciergesApi,
  eventApi,
  tablesApi,
  venueTaxApi,
  experiencesApi,
  venueProfileApi,
  venueUsersApi,
  audienceApi,
  adminApiAudienceApi,
  adminApiIntegrationTypesApi,
  iVvyApi,
  seatingAreasTablesApi,
  useSeatingAreasTablesApi,
  useAudienceHierarchyApi,
  venueGroupApi,
  apiReducer,
  insightsApi,
  orderingRoomNumbersApi,
  posiApi,
  referralApi,
  smsCampaignsApi,
  smsCampaignActivityApi,
  smsCampaignTemplateApi,
  venuePhoneNumbersApi,
  marketingStatusApi,
  guestSatisfactionApi,
  availabilityDebuggerApi,
  paymentsSettingsApi,
  backwriterApi,
  customAudienceApi,
} from '@sevenrooms/core/api'
import { bookingAccessOverrideListApi } from '@sevenrooms/lib-override-series-list'
import type { VenueGroup, Venue } from '@sevenrooms/mgr-core'
import type { LookerEmbedState } from '@sevenrooms/mgr-core/typings/looker'
import {
  insightsNewInsightCountSliceName,
  insightsNewInsightCountSliceReducer,
} from '@sevenrooms/mgr-insights/components/insightsNewInsightCountSlice'
import { emailCampaignTemplateReducer, type EmailTemplateState } from '@sevenrooms/mgr-marketing-email-template'
import { smsBuilderReducer, type SMSBuilderState } from '@sevenrooms/mgr-marketing-one-time-sms-center/OneTimeSMSCampaign/smsBuilderSlice'
import {
  deactivateSMSCampaignModalReducer,
  type DeactivateSMSCampaignModalState,
} from '@sevenrooms/mgr-marketing-one-time-sms-center/SMS/SMSCampaigns/SMSCampaignTable/DeactivateSMSCampaignModal/deactivateSMSCampaignModalSlice'
import {
  type SMSCampaignTableState,
  smsCampaignTableReducer,
} from '@sevenrooms/mgr-marketing-one-time-sms-center/SMS/SMSCampaigns/SMSCampaignTable/smsCampaignTableSlice'
import {
  emailCampaignTableReducer,
  type EmailCampaignTableState,
  deactivateEmailCampaignModalReducer,
  type DeactivateEmailCampaignModalState,
  type EmailBuilderState,
  emailBuilderReducer,
} from '@sevenrooms/mgr-marketing-ongoing-email-center'
import { orderingNotificationSettingsApi } from '@sevenrooms/mgr-ordering-notification-settings/hooks/orderingNotificationSettingsApi'
import { venueSettingsApi } from '@sevenrooms/mgr-settings-general/VenueSettings/hooks/useVenueSettingsApi'
import rootProfileReducer from '../../../venueprofile/src/reducers/profileReducer'
import type { AppState } from 'mgr/pages/reducers/reducer.types'

export interface State {
  appState: AppState
  orderingCheckout: OrderingCheckoutState
  orderingOrderPacing: OrderPacingState
  orderingProducts: Product86State
  orderingProductInventory: ProductInventoryState
  orderingMenusList: OrderingMenusListState
  orderingMenu: OrderingMenuState
  emailBuilder: EmailBuilderState
  emailCampaignTable: EmailCampaignTableState
  emailCampaignTemplate: EmailTemplateState
  deactivateEmailCampaignModal: DeactivateEmailCampaignModalState
  deactivateSMSCampaignModal: DeactivateSMSCampaignModalState
  smsBuilder: SMSBuilderState
  smsCampaignTable: SMSCampaignTableState
  venue: Venue
  users: typeof usersReducerState
  venueGroup: VenueGroup
  looker: LookerEmbedState
}

// Eventually: combineReducers<State>({...})
const rootReducer = combineReducers({
  lastAction: lastActionReducer,
  appState: appStateReducer,
  dayviewState: dayviewReducer,
  gridState: gridReducer,
  emailBuilder: emailBuilderReducer,
  emailCampaignTable: emailCampaignTableReducer,
  emailCampaignTemplate: emailCampaignTemplateReducer,
  deactivateEmailCampaignModal: deactivateEmailCampaignModalReducer,
  deactivateSMSCampaignModal: deactivateSMSCampaignModalReducer,
  smsBuilder: smsBuilderReducer,
  floorplanState: floorplanReducer,
  statusMessageState: statusMessageReducer,
  loadingState: loadingReducer,
  addDropDownState: addDropDownReducer,
  statusDropDownState: statusDropDownReducer,
  conflictDialogState: conflictDialogReducer,
  router: connectRouter(history),
  reviews: reviewsReducer,
  search: searchReducer,
  autotags: autotagsReducer,
  autotagPerks: perksReducer,
  landingPageSettings: landingPageSettingsReducer,
  emails: emailsReducer,
  guestcenterimport: guestCenterImportReducer,
  experiences: experiencesReducer,
  outgoingEmails: outgoingEmailsReducer,
  bookingChannels: bookingChannelsReducer,
  orderingSites: orderingSitesReducer,
  orderingSite: orderingSiteReducer,
  orderingMenusList: orderingMenusListReducer,
  orderingMenu: OrderingMenuReducer,
  orderingCheckout: orderingCheckoutReducer,
  orderingCheckoutFee: orderingCheckoutFeeReducer,
  orderingBrandSettings: orderingBrandSettingsReducer,
  orderingProducts: orderingProductsReducer,
  orderingOrderPacing: orderingOrderPacingReducer,
  orderingProductInventory: orderingProductInventoryReducer,
  deliveryIntegrations: deliveryIntegrationsReducer,
  pods: podsReducer,
  tags: tagsReducer,
  roles: rolesReducer,
  users: usersReducer,
  navState: navReducer,
  languages: languagesReducer,
  languageStrings: languageStringsReducer,
  venueProfile: rootProfileReducer,
  looker: lookerReducer,
  lightspeed: LightspeedReducer,
  kounta: KountaReducer,
  smsCampaignTable: smsCampaignTableReducer,
  reservationAndClientImport: ReservationAndClientImportReducer,
  [accessRuleApi.reducerPath]: accessRuleApi.reducer,
  [bookingAccessOverrideListApi.reducerPath]: bookingAccessOverrideListApi.reducer,
  [genericTagGroupsApi.reducerPath]: genericTagGroupsApi.reducer,
  [emailCampaignActivityApi.reducerPath]: emailCampaignActivityApi.reducer,
  [audienceApi.reducerPath]: audienceApi.reducer,
  [adminApiAudienceApi.reducerPath]: adminApiAudienceApi.reducer,
  [adminApiIntegrationTypesApi.reducerPath]: adminApiIntegrationTypesApi.reducer,
  [availabilityDebuggerApi.reducerPath]: availabilityDebuggerApi.reducer,
  [backwriterApi.reducerPath]: backwriterApi.reducer,
  [clientMarketingPreferencesApi.reducerPath]: clientMarketingPreferencesApi.reducer,
  [emailCampaignImageApi.reducerPath]: emailCampaignImageApi.reducer,
  [emailCampaignsApi.reducerPath]: emailCampaignsApi.reducer,
  [emailCampaignTemplateApi.reducerPath]: emailCampaignTemplateApi.reducer,
  [experiencesApi.reducerPath]: experiencesApi.reducer,
  [eventApi.reducerPath]: eventApi.reducer,
  [tablesApi.reducerPath]: tablesApi.reducer,
  [venueTaxApi.reducerPath]: venueTaxApi.reducer,
  [googleReviewApi.reducerPath]: googleReviewApi.reducer,
  [orderingNotificationSettingsApi.reducerPath]: orderingNotificationSettingsApi.reducer,
  [languageApi.reducerPath]: languageApi.reducer,
  [ordersApi.reducerPath]: ordersApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [perksApi.reducerPath]: perksApi.reducer,
  [conciergesApi.reducerPath]: conciergesApi.reducer,
  [podApi.reducerPath]: podApi.reducer,
  [policyApi.reducerPath]: policyApi.reducer,
  [promoCodeManagementApi.reducerPath]: promoCodeManagementApi.reducer,
  [reservationWidgetSettingsApi.reducerPath]: reservationWidgetSettingsApi.reducer,
  [reservationWidgetSettingsApiV2.reducerPath]: reservationWidgetSettingsApiV2.reducer,
  [subscriptionWidgetSettingsApi.reducerPath]: subscriptionWidgetSettingsApi.reducer,
  [shiftApi.reducerPath]: shiftApi.reducer,
  [upsellsApi.reducerPath]: upsellsApi.reducer,
  [taxRateApi.reducerPath]: taxRateApi.reducer,
  [venueApi.reducerPath]: venueApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [venueProfileApi.reducerPath]: venueProfileApi.reducer,
  [venueUsersApi.reducerPath]: venueUsersApi.reducer,
  [venueSettingsApi.reducerPath]: venueSettingsApi.reducer,
  [iVvyApi.reducerPath]: iVvyApi.reducer,
  [seatingAreasTablesApi.reducerPath]: seatingAreasTablesApi.reducer,
  [useSeatingAreasTablesApi.reducerPath]: useSeatingAreasTablesApi.reducer,
  [useAudienceHierarchyApi.reducerPath]: useAudienceHierarchyApi.reducer,
  [venueGroupApi.reducerPath]: venueGroupApi.reducer,
  [insightsApi.reducerPath]: insightsApi.reducer,
  [insightsNewInsightCountSliceName]: insightsNewInsightCountSliceReducer,
  [smsCampaignActivityApi.reducerPath]: smsCampaignActivityApi.reducer,
  [smsCampaignsApi.reducerPath]: smsCampaignsApi.reducer,
  [smsCampaignTemplateApi.reducerPath]: smsCampaignTemplateApi.reducer,
  [subscriptionWidgetApi.reducerPath]: subscriptionWidgetApi.reducer,
  [paymentsSettingsApi.reducerPath]: paymentsSettingsApi.reducer,
  [orderingRoomNumbersApi.reducerPath]: orderingRoomNumbersApi.reducer,
  [posiApi.reducerPath]: posiApi.reducer,
  [referralApi.reducerPath]: referralApi.reducer,
  [venuePhoneNumbersApi.reducerPath]: venuePhoneNumbersApi.reducer,
  [marketingStatusApi.reducerPath]: marketingStatusApi.reducer,
  [guestSatisfactionApi.reducerPath]: guestSatisfactionApi.reducer,
  [activityLogApi.reducerPath]: activityLogApi.reducer,
  [customAudienceApi.reducerPath]: customAudienceApi.reducer, //
  apiReducer,
})

export default rootReducer
