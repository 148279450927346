import { skipToken } from '@reduxjs/toolkit/query'
import { useMemo } from 'react'
import styled from 'styled-components'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import { useGetActiveAudiencesQuery } from '@sevenrooms/core/api'
import { TimeslotDefaults } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Toggle, StyledLabel } from '@sevenrooms/core/ui-kit/core'
import { Grid, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useStoreSelector } from '../../selectors/useStoreSelector'
import { AvailabilityLocales } from './Availability.locales'
import { defaultAudiences } from './defaults'
import { useAvailabilityTimeslotsRequest } from './useAvailabilityTimeslotsRequest'
import type * as BookAvailabilityActions from '../../actions/BookAvailabilityActions'

interface InternalArBookingControlsProps {
  actions: typeof BookAvailabilityActions
}

export function InternalArBookingBar({ actions }: InternalArBookingControlsProps) {
  const { formatMessage } = useLocales()
  const { showAccessRules, searchVenues, audienceId, typeId } = useStoreSelector(state => state.bookAvailabilityState)

  const { data: audiences = defaultAudiences, isSuccess } = useGetActiveAudiencesQuery(searchVenues[0]?.id ?? skipToken)

  const { types, byOfferChoices } = useAvailabilityTimeslotsRequest()

  const typesChoices = useMemo(
    () => [
      { choices: [{ name: formatMessage(AvailabilityLocales.allTypesName), value: TimeslotDefaults.ALL }] },
      { name: formatMessage(AvailabilityLocales.byPublicDescription), choices: types },
      { name: formatMessage(AvailabilityLocales.byOffer), choices: byOfferChoices },
    ],
    [byOfferChoices, formatMessage, types]
  )

  if (!audienceId && isSuccess && audiences.length > 0) {
    actions.changeAudience(audiences[0]?.value)
  }

  if (isSuccess && audiences.length === 0) {
    return null
  }

  return (
    <FieldGroup gridTemplateColumns="repeat(3, minmax(0, 1fr))" pr="m">
      <HStack alignItems="center" height={46} pr="xs">
        <Toggle
          name="toggle-show-access-rules"
          label={
            <HStack spacing="s" alignItems="center">
              <Text>Show Access Rules</Text>
            </HStack>
          }
          checked={showAccessRules}
          onChange={actions.changeShowAccessRules}
        />
      </HStack>
      {showAccessRules && (
        <>
          <DropdownArrowsPicker
            name="AUDIENCE"
            choices={audiences}
            value={audienceId}
            onChangeHandler={actions.changeAudience}
            {...dropdownCommonProps}
          />
          <DropdownArrowsPicker
            name="TYPE"
            choices={typesChoices}
            value={typeId}
            onChangeHandler={actions.changeType}
            {...dropdownCommonProps}
          />
        </>
      )}
    </FieldGroup>
  )
}

const dropdownCommonProps = {
  placeholder: 'LOADING',
  showNavigation: false,
  isLightTheme: true,
  style: { width: 'auto', margin: 0 },
}

const FieldGroup = styled(Grid)`
  column-gap: 9px;
  margin: 15px 12px;
  ${props => props.theme.clearFix};

  ${StyledLabel} div:first-child {
    margin-right: 6px;
  }
`
