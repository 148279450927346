import { defineMessages } from '@sevenrooms/core/locales'

export const campaignBuilderMessages = defineMessages({
  scheduleForFuture: {
    id: 'campaignBuilder.scheduleForFuture',
    defaultMessage: 'Schedule for future',
  },
  selectTime: {
    id: 'campaignBuilder.selectTime',
    defaultMessage: 'Select time',
  },
  sendOn: {
    id: 'campaignBuilder.sendOn',
    defaultMessage: 'Send on',
  },
  immediately: {
    id: 'campaignBuilder.immediately',
    defaultMessage: 'Immediately',
  },
  sendingScheduleTitle: {
    id: 'campaignBuilderSendingSchedule.title',
    defaultMessage: 'Sending Schedule',
  },
  sendingScheduleHoursAfter: {
    id: 'campaignBuilderSendingSchedule.hoursAfter',
    defaultMessage: 'Hour(s) after Auto-tag is applied',
  },
  sendingScheduleDaysAfter: {
    id: 'campaignBuilderSendingSchedule.daysAfter',
    defaultMessage: 'Day(s) after Auto-tag is applied',
  },
  sendingScheduleSpecificDayAndTime: {
    id: 'campaignBuilderSendingSchedule.specificDayAndTime',
    defaultMessage: 'Specific day and time',
  },
  selectHour: {
    id: 'campaignBuilder.selectHour',
    defaultMessage: 'Select hour',
  },
  selectDay: {
    id: 'campaignBuilder.selectDay',
    defaultMessage: 'Select day',
  },
  selectDays: {
    id: 'campaignBuilder.selectDays',
    defaultMessage: 'Select day(s)',
  },
  every: {
    id: 'campaignBuilder.every',
    defaultMessage: 'Every',
  },
  day: {
    id: 'campaignBuilder.day',
    defaultMessage: 'Day',
  },
  days: {
    id: 'campaignBuilder.days',
    defaultMessage: 'Days',
  },
  buildingAutotagError: {
    id: 'campaignBuilder.buildingAutotagError',
    defaultMessage: '{tagName} Auto-tag is being updated and cannot be used at the moment. Check back soon.',
  },
  buildingMultipleAutotagsError: {
    id: 'campaignBuilder.buildingMultipleAutotagsError',
    defaultMessage: '{tagNames} Auto-tags are being updated and cannot be used at the moment. Check back soon.',
  },
  settingUnchangeable: {
    id: 'campaignBuilder.settingUnchangeable',
    defaultMessage: 'Customers will not be able to change this setting.',
  },
  settingChangeable: {
    id: 'campaignBuilder.settingChangeable',
    defaultMessage: 'Customers will be able to change this setting.',
  },
  searchAutotags: {
    id: 'campaignBuilderAudience.searchAutotags',
    defaultMessage: 'Search Auto-tags',
  },
  searchAutotagsAndClientTags: {
    id: 'campaignBuilderAudience.searchAutotagsAndClientTags',
    defaultMessage: 'Search Auto-tags and client tags',
  },
  recipientDescription: {
    id: 'campaignBuilderAudience.recipientDescription',
    defaultMessage: 'The recipients are based on Auto-tags and Client Tags, which can be found',
  },
  recipientSelectorAll: {
    id: 'recipientSelectorAll',
    defaultMessage: 'Send to all guests who have opted in',
  },
  recipientSelectorFiltered: {
    id: 'recipientSelectorFiltered',
    defaultMessage: 'Send to specific segments',
  },
  recipientTagsRequiredError: {
    id: 'recipientTagsRequiredError',
    defaultMessage: 'Please select at least one tag',
  },
  audienceSizeBannerTitle: {
    id: 'audienceSizeBannerTitle',
    defaultMessage: 'Estimated audience size',
  },
  audienceSizeBannerDescription: {
    id: 'audienceSizeBannerDescription',
    defaultMessage: 'Check your audience size here to make sure you have the correct segment.',
  },
  audienceSizeBannerDescriptionCalculatedValues: {
    id: 'audienceSizeBannerDescriptionCalculated',
    defaultMessage: '~{matchingClients}/{totalClients} guests ({percentage}%).',
  },
  audienceSizeBannerAction: {
    id: 'audienceSizeBannerAction',
    defaultMessage: 'Calculate Audience Size',
  },
  internalName: {
    id: 'campaignBuilder.internalName',
    defaultMessage: 'Internal name, not visible to guests.',
  },
  saveCampaignSuccessMessage: {
    id: 'campaignBuilder.createCampaignSuccessMessage',
    defaultMessage: 'Campaign has been saved.',
  },
  saveCampaignErrorMessage: {
    id: 'campaignBuilder.createCampaignErrorMessage',
    defaultMessage: 'Unable to save campaign, please try again later.',
  },
  audienceSizeFetchError: {
    id: 'audienceSizeFetchError',
    defaultMessage: 'Unable to calculate audience size, please try again later.',
  },
  sendingConfigurations: {
    id: 'campaignBuilder.sendingConfigurations',
    defaultMessage: 'Sending Configurations',
  },
  audienceTitle: {
    id: 'campaignBuilderAudience.title',
    defaultMessage: 'Audience',
  },
  recipientTagDeleted: {
    id: 'campaignBuilderAudience.recipientDeleted',
    defaultMessage: 'Auto-tag has been deleted',
  },
  excludeTagDeleted: {
    id: 'campaignBuilderAudience.excludeDeleted',
    defaultMessage: 'One or more tags have been deleted',
  },
  offerModalTitle: {
    id: 'campaignEditorOfferModalHeader.title',
    defaultMessage: 'Add Offer Link',
  },
  offerModalSubTitleNoDisplayText: {
    id: 'campaignEditorOfferModalHeader.subTitle',
    defaultMessage: 'Select offer below',
  },
  offerModalSubTitle: {
    id: 'campaignEditorOfferModalHeader.subTitle',
    defaultMessage: 'Select offer below and enter display text',
  },
  noOfferModalSubTitle: {
    id: 'campaignEditorNoOfferModalHeader.subTitle',
    defaultMessage: 'You have not created an offer yet',
  },
  modalAddOfferButton: {
    id: 'campaignEditorNoOfferModalAddOfferButton.text',
    defaultMessage: 'Add Offers',
  },
  offerModalLabel: {
    id: 'campaignEditorOfferModalLabel.primary',
    defaultMessage: 'Text to display',
  },
  offerModalInputPlaceholder: {
    id: 'campaignEditorOfferModalInput.placeholder',
    defaultMessage: 'Enter text',
  },
  offerModalLinkPrefix: {
    id: 'campaignEditorOfferModal.offerModalLinkPrefix',
    defaultMessage: 'offer',
  },
  offerModalOfferDirectoryLabel: {
    id: 'campaignEditorOfferModal.offerModalOfferDirectoryLabel',
    defaultMessage: 'Offers Directory',
  },
  modalCancelButton: {
    id: 'campaignEditorOfferModalCancelButton.text',
    defaultMessage: 'Cancel',
  },
  modalPrimaryButton: {
    id: 'campaignEditorOfferModalPrimaryButton.text',
    defaultMessage: 'Add Link',
  },
  menu: {
    id: 'campaignBuilderEditor.menu',
    defaultMessage: 'Menu Link',
  },
  offer: {
    id: 'campaignBuilderEditor.offer',
    defaultMessage: 'Offers',
  },
  reservation: {
    id: 'campaignBuilderAudience.whenDescription',
    defaultMessage: 'Reservation Widget',
  },
  timeInThePastError: {
    id: 'campaignBuilder.timeInThePastError',
    defaultMessage: 'Scheduled time is in the past.',
  },
  cancelEditWarning: {
    id: 'campaignBuilder.cancelEditWarning',
    defaultMessage: 'Are you sure you want to leave without saving changes?',
  },
  exitWithoutSaving: {
    id: 'campaignBuilder.exitWithoutSaving',
    defaultMessage: 'Exit Without Saving',
  },
  backToEditing: {
    id: 'campaignBuilder.backToEditing',
    defaultMessage: 'Back to Editing',
  },
  copyOf: {
    id: 'campaignBuilder.copyOf',
    defaultMessage: 'Copy of',
  },
  send: {
    id: 'campaignBuilder.send',
    defaultMessage: 'Send',
  },
  schedule: {
    id: 'campaignBuilder.schedule',
    defaultMessage: 'Schedule',
  },
  scheduleSuccess: {
    id: 'campaignBuilder.scheduleSuccess',
    defaultMessage: '"{campaign}" is now scheduled to be sent on {date} at {time}',
  },
  sendSuccess: {
    id: 'campaignBuilder.sendSuccess',
    defaultMessage: '"{campaign}" is now sending to "{recipientTagNames}"',
  },
  excludeBlacklist: {
    // BLACKLIST_HACK
    id: 'campaignBuilder.excludeBlacklist',
    defaultMessage: 'Exclude Blacklist',
  },
  crossPromotion: {
    id: 'campaignBuilder.crossPromotion',
    defaultMessage: 'Cross-Promotion Link',
  },
} as const)
