import _ from 'lodash'
import {
  SET_INITIAL_DATA,
  ADD_NEW_TAG,
  ADD_NEW_CATEGORY,
  MAP_TAG,
  FILE_UPLOADED,
  SUBMISSION_DIALOG_INITIALIZED,
  SUBMISSION_DIALOG_CLOSED,
  IMPORT_SUBMISSION_STARTED,
  IMPORT_SUBMISSION_COMPLETED,
} from 'mgr/pages/single-venue/settings/actions/ReservationAndClientImport'

const initialState = {
  isInitialDataLoaded: false,
  tagGroups: {},
  fields: {},
  licenseKey: null,
  mappedTags: {},
  blobstoreUploadKey: null,
  importType: null,
  importId: null,
  isPopUpOpen: false,
  isSubmitting: false,
}

const ReservationAndClientImportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INITIAL_DATA:
      // eslint-disable-next-line no-case-declarations
      const mappedTags = {}
      // eslint-disable-next-line no-case-declarations
      const payloadData = action.initialData.data
      _.each(payloadData.mapped_tags_from_backend, (value, key) => {
        const tagGroup = _.find(payloadData.tag_groups, elem => elem.id === value.tag_group_id)
        if (tagGroup) {
          mappedTags[key] = {
            tagGroup: {
              name_display: tagGroup.name,
              tag_name_displays: {},
              id: tagGroup.id,
              name: tagGroup.name,
              color_hex: tagGroup.color_hex,
              tags: tagGroup.tags,
            },
            tagName: value.tag_name,
          }
        }
      })
      return {
        ...state,
        importType: action.importType,
        isInitialDataLoaded: true,
        tagGroups: payloadData.tag_groups,
        fields: payloadData.fields,
        licenseKey: payloadData.license_key,
        companyId: payloadData.company_id,
        companyName: payloadData.company_name,
        email: payloadData.email,
        name: payloadData.name,
        userId: payloadData.user_id,
        dateFields: payloadData.date_fields,
        mappedTags,
        blobstoreUploadKey: null,
        importId: null,
        isPopUpOpen: false,
        isSubmitting: false,
      }
    case ADD_NEW_TAG: {
      const index = _.findIndex(state.tagGroups, tagGroup => tagGroup.id === action.tag.tagGroup.id)
      if (index !== -1) {
        // add to existing category
        const newArray = [...state.tagGroups] // making a new array
        newArray[index] = {
          ...newArray[index],
          tags: [...newArray[index].tags, action.tag.tagName],
        }
        return {
          ...state,
          tagGroups: newArray,
        }
      }
      return {
        ...state,
      }
    }
    case ADD_NEW_CATEGORY: {
      return {
        ...state,
        tagGroups: [...state.tagGroups, action.tagGroup],
      }
    }
    case MAP_TAG: {
      const tag = {
        isNew: action.tagToMap.isNew,
        tagName: action.tagToMap.tagName,
        tagNameDisplay: action.tagToMap.tagNameDisplay,
        tagGroup: {
          name: action.tagToMap.tagGroup.name,
          id: action.tagToMap.tagGroup.id,
          isNew: action.tagToMap.tagGroup.isNew,
        },
      }
      return {
        ...state,
        mappedTags: {
          ...state.mappedTags,
          [action.mappedTag]: tag,
        },
      }
    }
    case FILE_UPLOADED:
      return {
        ...state,
        blobstoreUploadKey: action.data.post_key,
      }
    case SUBMISSION_DIALOG_INITIALIZED:
      return {
        ...state,
        importId: null,
        isPopUpOpen: true,
        isSubmitting: false,
      }
    case SUBMISSION_DIALOG_CLOSED:
      return {
        ...state,
        importId: null,
        isPopUpOpen: false,
        isSubmitting: false,
      }
    case IMPORT_SUBMISSION_STARTED:
      return {
        ...state,
        isSubmitting: true,
      }
    case IMPORT_SUBMISSION_COMPLETED:
      return {
        ...state,
        importId: action.importId,
        isSubmitting: false,
      }
    default:
      return state
  }
}

export default ReservationAndClientImportReducer
