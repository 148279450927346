import type { ReservationWidget } from '@sevenrooms/core/domain'
import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { Totals, Amount, Charges, ChargesCategory, TaxesAndServices, TaxesAndServicesCategory } from './components'
import { paymentMessages } from './Payment.locales'
import type { PaymentForm } from './Payment.zod'
import type { PropsWithChildren } from 'react'

export interface PaymentProps {
  field: Field<PaymentForm>
  oldValues?: PaymentForm
  override: boolean
  title?: string
  selectable?: boolean
  taxGroups: ReservationWidget.TaxGroup[]
  currencyCode: string
  currencySymbol: string
}

export function Payment({ field, title, selectable, oldValues, ...props }: PaymentProps) {
  const { override } = props
  const { formatMessage } = useLocales()
  const [categories, categoriesBundled] = useWatchMany(field, ['categories', 'categoriesBundled'])
  const categoriesBundledEntries = Object.entries(categoriesBundled)
  const categoriesEntries = Object.entries(categories)

  return (
    <VStack>
      <Group title={formatMessage(paymentMessages.chargesPriceTitle)}>
        <Amount
          field={field.prop('amount')}
          title={title ?? formatMessage(paymentMessages.amount)}
          selectable={selectable}
          oldValue={oldValues?.amount}
          {...props}
        />
        <Charges field={field} selectable={selectable} oldValue={oldValues?.charges} {...props} />
        {categoriesBundledEntries.map(([catId, { upgrades }]) =>
          Object.entries(upgrades).map(([id, { name, count }]) => (
            <Amount
              key={`${catId}${id}`}
              field={field.prop(`categoriesBundled.${catId}.upgrades.${id}.amount`)}
              oldValue={oldValues?.categoriesBundled?.[catId]?.upgrades?.[id]?.amount}
              title={name}
              count={count}
              {...props}
            />
          ))
        )}
        {(!selectable || categoriesBundledEntries.length > 0) && <TaxesAndServices field={field} {...props} />}
      </Group>
      {categoriesEntries.map(([catId, { name, upgrades }]) => (
        <Group key={catId} title={formatMessage(paymentMessages.chargesUpgradesTitle, { name })}>
          {Object.entries(upgrades).map(([id, { name, count }]) => (
            <Amount
              key={id}
              field={field.prop(`categories.${catId}.upgrades.${id}.amount`)}
              oldValue={oldValues?.categories?.[catId]?.upgrades?.[id]?.amount}
              title={name}
              count={count}
              {...props}
            />
          ))}
          <ChargesCategory field={field.prop(`categories.${catId}`)} oldValue={oldValues?.categories?.[catId]?.charges} {...props} />
          {!override && <TaxesAndServicesCategory field={field.prop(`categories.${catId}`)} {...props} />}
        </Group>
      ))}
      <Totals field={field} oldValues={oldValues} {...props} />
    </VStack>
  )
}

interface GroupProps {
  title: string
}

function Group({ title, children }: PropsWithChildren<GroupProps>) {
  return (
    <VStack borderTopColor="borders" mb="m">
      <Box p="s m" backgroundColor="secondaryBackground">
        <Text textStyle="body1Bold">{title}</Text>
      </Box>
      <Box>{children}</Box>
    </VStack>
  )
}
