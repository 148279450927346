import { type TypedUseSelectorHook, useSelector } from 'react-redux'
import type { InternalAvailabilityTimeslot, MixedAvailabilityTimeslot } from '@sevenrooms/core/api'
import type { GratuityType } from '@sevenrooms/mgr-reservation-slideout/Payment'
import type { initialState as initialAvailabilityState } from '../reducers/BookAvailabilityReducer'
import type { initialState as initialDetailsState } from '../reducers/BookDetailsReducer'
import type { initialState as initialPaymentState } from '../reducers/BookPaymentReducer'
import type { Actual, Charges, Venue, Transaction } from '../reducers/BookPaymentSlice.types'
import type { initialState as initialBookState, stepCollapsedStateDefault } from '../reducers/BookReducer'
import type { UpgradesState } from '../reducers/UpgradesSlice'

type BookAvailabilityState = {
  searchVenues: { id: string }[]
  availabilityByVenue: Record<string, { availableTimes?: InternalAvailabilityTimeslot[] }>
  actual: Actual
  selectedTimeSlot: MixedAvailabilityTimeslot
  previousSelectedTimeSlot: MixedAvailabilityTimeslot
  internalArBookingEnabled?: boolean
} & Omit<typeof initialAvailabilityState, 'searchVenues' | 'actual' | 'selectedTimeSlot' | 'previousSelectedTimeSlot'>
type BookPaymentState = {
  outstandingPaylinkTransaction: Transaction
  outstandingAddFlowTransaction: Transaction
  allTransactions: Transaction[]
  availabilityChargeData: Charges | null
  paylinkGratuityType: GratuityType
  upgrades: {
    upsellAmount: number
    automaticallyIncludedUpsells: []
    selectedUpsells: {}
    chargeUpsellSnapshot?: {}
  }
} & Omit<
  typeof initialPaymentState,
  | 'outstandingPaylinkTransaction'
  | 'outstandingAddFlowTransaction'
  | 'allTransactions'
  | 'availabilityChargeData'
  | 'paylinkGratuityType'
  | 'upgrades'
>
type BookDetailsState = typeof initialDetailsState
type BookState = { isStepCollapsed: typeof stepCollapsedStateDefault; selectedVenue: Venue } & Omit<
  typeof initialBookState,
  'isStepCollapsed' | 'selectedVenue'
>

export interface State {
  bookState: BookState
  bookAvailabilityState: BookAvailabilityState
  bookPaymentState: BookPaymentState
  bookDetailsState: BookDetailsState
  upgradesState: UpgradesState
}

export const useStoreSelector: TypedUseSelectorHook<State> = useSelector
