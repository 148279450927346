/* eslint-disable no-nested-ternary */
import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import ClientHotelLookupResult from 'mgr/actualslideout/components/client/ClientHotelLookupResult'
import ClientLookupResult from 'mgr/actualslideout/components/client/ClientLookupResult'
import ClientSearchInput from 'mgr/actualslideout/components/client/ClientSearchInput'
import { LoadingSpinner } from 'mgr/layout/StyledComponentUtils'
import { VmsIcons } from 'svr/common/VmsIcons'
import DropdownMenu from 'svr/component-lib/Manager/Menus/DropdownMenu'
import { ContainerFluid } from 'svr/lib/styled-bootstrap-grid'
import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core'

const LookupResultsContainer = styled.div`
  min-height: 700px;
`

const LookupResultsSectionHeader = styled.div`
  text-transform: uppercase;
  background-color: ${props => props.theme.color.greyMuted};
  color: ${props => props.theme.color.grey};
  border-top: 1px solid ${props => props.theme.color.white};
  font-size: 12px;
  font-weight: 500;
  padding: 6px 16px;
`

const LookupResultsPlaceholder = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  margin: 24px 10px;
  color: ${props => (props.error ? props.theme.error : props.theme.mediumSilver)};
`

const AddAsNewClientContainer = styled.div`
  margin: 10px auto;
  width: 398px;
`

const AddAsNewClientButton = styled.button`
  ${props => props.theme.primaryButton} height: 36px;
  line-height: 36px;
  width: 100%;
`

const HydrateLoading = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
`

const SearchLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 9px 2px 9px 11px;
`

const SearchLineItemInput = styled.div`
  flex-grow: 1;
  margin-right: 9px;
`

const SearchLineItemHotel = styled.div``

const ClientLookup = ({
  venue,
  isLookupLoading,
  isLookupLoadError,
  isHydrateLoading,
  isHydrateLoadError,
  actions,
  searchTerms,
  clientResults,
  returnSearchInputRef,
  labels,
  hotelLookupOptions,
  selectedHotelId,
  isHotelLookupLoading,
  isHotelLookupLoadError,
  clientHotelResults,
  clientTags,
  reservationTags,
  canViewClientSpendData,
  belmondClientLookupEnabled,
  areThirdPartyErrors,
}) => {
  const { canViewThirdPartyClientDbSearch } = useNavigationPermissionsContext()
  const renderSearchResults = () =>
    clientResults.map((client, i) => (
      <ClientLookupResult
        testId={`sr-search_results-client_${i}`}
        key={client.id}
        {...{ client, canViewClientSpendData }}
        onClick={() => (client.id ? actions.selectExistingClient(client.id) : actions.selectHotelClient(venue, client))}
      />
    ))
  const renderHotelSearchResults = () =>
    clientHotelResults.map(client => (
      <ClientHotelLookupResult
        key={client.reservation_num}
        {...{ client }}
        onClick={() => actions.selectHotelClient(venue, client, selectedHotelId)}
        reservationTags={reservationTags[venue.id]}
        clientTags={clientTags[venue.id]}
      />
    ))
  const renderAddAsNewClientButton = () => (
    <AddAsNewClientContainer>
      <AddAsNewClientButton
        data-test="sr-button-add_as_new"
        onClick={() => {
          actions.clickAddAsNewClient(venue, searchTerms)
        }}
      >
        {labels.addAsNew}
      </AddAsNewClientButton>
    </AddAsNewClientContainer>
  )
  const showHotelDropdown = canViewThirdPartyClientDbSearch && !_.isEmpty(hotelLookupOptions)
  const showHotelResults =
    canViewThirdPartyClientDbSearch && !_.isEmpty(hotelLookupOptions) && !_.isNil(selectedHotelId) && !_.isEmpty(searchTerms)
  const hotelOptions =
    showHotelDropdown &&
    _.sortBy(
      Object.keys(hotelLookupOptions).map(hotelId => ({
        value: hotelId,
        name: hotelLookupOptions[hotelId],
      })),
      'name'
    )
  const selectedHotelOption = showHotelDropdown && _.find(hotelOptions, { value: selectedHotelId })
  const hotelSectionTitleSuffix =
    (canViewThirdPartyClientDbSearch && isHotelLookupLoading) || isHotelLookupLoadError || !_.isEmpty(clientHotelResults)
      ? ''
      : ' (no results)'
  const showSectionHeaders = showHotelResults
  return (
    <div>
      <SearchLineContainer>
        <SearchLineItemInput>
          <ClientSearchInput
            debounceTimeout={belmondClientLookupEnabled ? 2000 : 500}
            testId="sr-input-search"
            onChange={actions.changeClientSearchTerms}
            placeholder={showHotelDropdown ? 'NAME, PHONE, EMAIL, ROOM, CONFIRMATION NUMBER' : 'NAME, PHONE, EMAIL'}
            {...{ returnSearchInputRef }}
          />
        </SearchLineItemInput>
        {showHotelDropdown && (
          <SearchLineItemHotel>
            <DropdownMenu
              key="hotel_selector"
              choices={hotelOptions}
              selectedValues={[selectedHotelOption]}
              isLightTheme
              isRightAlignedMenu
              dropdownIndent="7px"
              onChangeHandler={choices => actions.changeSelectedHotelId(choices[0].value)}
              iconDropdown={VmsIcons.Company}
            />
          </SearchLineItemHotel>
        )}
      </SearchLineContainer>
      <LookupResultsContainer>
        {showHotelResults && (
          <div>
            {showSectionHeaders && (
              <LookupResultsSectionHeader>{hotelLookupOptions[selectedHotelId] + hotelSectionTitleSuffix}</LookupResultsSectionHeader>
            )}
            {isHotelLookupLoading ? (
              <LookupResultsPlaceholder>
                <LoadingSpinner />
              </LookupResultsPlaceholder>
            ) : isHotelLookupLoadError ? (
              <div>
                <LookupResultsPlaceholder error>{labels.errorMessage}</LookupResultsPlaceholder>
              </div>
            ) : _.isEmpty(clientHotelResults) ? null : (
              <div>
                <ContainerFluid style={{ paddingRight: 0 }}>{renderHotelSearchResults()}</ContainerFluid>
              </div>
            )}
          </div>
        )}
        {areThirdPartyErrors && <LookupResultsPlaceholder error>{labels.thirdPartyError}</LookupResultsPlaceholder>}
        {showSectionHeaders && <LookupResultsSectionHeader>SEVENROOMS</LookupResultsSectionHeader>}
        {_.isEmpty(searchTerms) ? (
          <LookupResultsPlaceholder>{labels.searchPlaceholder}</LookupResultsPlaceholder>
        ) : isLookupLoading ? (
          <LookupResultsPlaceholder>
            <LoadingSpinner />
          </LookupResultsPlaceholder>
        ) : isLookupLoadError ? (
          <div>
            <LookupResultsPlaceholder error>{labels.errorMessage}</LookupResultsPlaceholder>
            {renderAddAsNewClientButton()}
          </div>
        ) : _.isEmpty(clientResults) ? (
          <div>
            {renderAddAsNewClientButton()}
            <LookupResultsPlaceholder>{labels.noResults}</LookupResultsPlaceholder>
          </div>
        ) : (
          <div>
            {renderAddAsNewClientButton()}
            <ContainerFluid data-test="sr-search_results-clients">{renderSearchResults()}</ContainerFluid>
            {isHydrateLoading ? <HydrateLoading /> : null}
          </div>
        )}
      </LookupResultsContainer>
    </div>
  )
}

ClientLookup.propTypes = {
  venue: React.PropTypes.object,
  actions: React.PropTypes.object.isRequired,
  isLookupLoading: React.PropTypes.bool.isRequired,
  isLookupLoadError: React.PropTypes.bool.isRequired,
  isHydrateLoading: React.PropTypes.bool.isRequired,
  isHydrateLoadError: React.PropTypes.bool.isRequired,
  clientResults: React.PropTypes.array.isRequired,
  hotelLookupOptions: React.PropTypes.object,
  selectedHotelId: React.PropTypes.string,
  isHotelLookupLoading: React.PropTypes.bool,
  isHotelLookupLoadError: React.PropTypes.bool,
  clientHotelResults: React.PropTypes.array,
  returnSearchInputRef: React.PropTypes.func.isRequired,
  canViewClientSpendData: React.PropTypes.bool,
  belmondClientLookupEnabled: React.PropTypes.bool,
  areThirdPartyErrors: React.PropTypes.bool,
}

ClientLookup.defaultProps = {
  actions: {},
  returnSearchInputRef: () => {},
}

export default ClientLookup
