import { useMemo } from 'react'
import type { VenueProfile, ExperiencesData, GroupVenue, GroupVenuesDict } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button, Topol, useTopolContext, FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, HStack, Box, DividerLine, Image, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { emailBuilderMessages, EmailEditor, BodyContentWrapper, LimitedEditorWrapper } from '@sevenrooms/marketing'
import previewTextTooltip from '@sevenrooms/marketing/images/previewTextTooltip.png'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import type { EmailContentFormField } from '@sevenrooms/mgr-marketing-ongoing-email-center/components'

interface EmailContentFromScratchProps {
  fields: EmailContentFormField
  imageUpload?: boolean
  disabled?: boolean
  venueProfile: VenueProfile
  experiencesData?: ExperiencesData
  isDirty?: boolean
  setGoToOffers?: Function
  shouldUseTopol: boolean
  template: object | null
  groupVenues: GroupVenue[]
}

export function EmailContentFromScratch({
  fields,
  imageUpload,
  disabled,
  venueProfile,
  experiencesData,
  isDirty,
  setGoToOffers,
  template,
  shouldUseTopol,
  groupVenues,
}: EmailContentFromScratchProps) {
  const { formatMessage } = useLocales()
  const { venue, venueKey } = useVenueContext()
  const { venueSettings } = useAppContext()
  const nav = useNavigation()
  const { save: saveTopol } = useTopolContext()

  const crossPromotionChoices = groupVenues.map(groupVenue => ({ id: groupVenue.name, label: groupVenue.name }))
  const groupVenuesDict: GroupVenuesDict = useMemo(() => {
    const dict: GroupVenuesDict = {}
    groupVenues.forEach((groupVenue: GroupVenue) => {
      dict[groupVenue.name] = {
        urlkey: groupVenue.urlkey,
        reservationLink: groupVenue.reservationWidgetLink,
      }
    })
    return dict
  }, [groupVenues])
  if (!venue) {
    return null
  }

  return (
    <BaseSection title={formatMessage(emailBuilderMessages.emailContentTitle)}>
      <Box p="lm">
        <VStack spacing="lm" mb="lm">
          <HStack spacing="lm" width="100%">
            <VStack>
              <Label primary={formatMessage(emailBuilderMessages.senderVenue)} />
              <LimitedEditorWrapper disable={disabled}>
                <EmailEditor
                  disabled={disabled}
                  field={fields.prop('emailSender.value')}
                  isLimitedEditor
                  destination={routes.manager2.marketing.emailCenter.emails.emailBuilder.emailBuilderFromScratch}
                  experiencesData={experiencesData}
                  venueProfile={venueProfile}
                  venue={venue}
                  data-test="emailSender"
                />
              </LimitedEditorWrapper>
            </VStack>

            <VStack>
              <Label primary={formatMessage(emailBuilderMessages.emailSubjectLineTitle)} />
              <LimitedEditorWrapper disable={disabled}>
                <EmailEditor
                  field={fields.prop('subjectLine.value')}
                  isLimitedEditor
                  destination={routes.manager2.marketing.emailCenter.emails.emailBuilder.emailBuilderFromScratch}
                  disabled={disabled}
                  experiencesData={experiencesData}
                  venueProfile={venueProfile}
                  venue={venue}
                  data-test="subjectLine"
                />
              </LimitedEditorWrapper>
            </VStack>
          </HStack>

          {!shouldUseTopol && (
            <HStack width="100%">
              <VStack width="50%">
                <Label
                  primary={formatMessage(emailBuilderMessages.emailPreviewTextTitle)}
                  secondary={formatMessage(emailBuilderMessages.emailPreviewTextDescription)}
                  info={
                    <VStack spacing="s" data-test="preview-text-tooltip">
                      <Text color="lightFont" fontWeight="bold">
                        {formatMessage(emailBuilderMessages.emailPreviewTextTooltipTitle)}
                      </Text>

                      <Image src={previewTextTooltip} alt="email content tip" width={352} height={78} />
                    </VStack>
                  }
                  tooltipDisplayAction="hover"
                >
                  <FormInput disabled={disabled} field={fields.prop('emailPreview.value')} autoComplete="none" />
                </Label>
              </VStack>
            </HStack>
          )}
        </VStack>

        <DividerLine color="dividerLines" margin="none" />

        <VStack mt="lm" width="100%">
          <HStack justifyContent="space-between" pb="m">
            <VStack>
              <Label
                primary={formatMessage(emailBuilderMessages.designYourEmailContent)}
                secondary={formatMessage(emailBuilderMessages.designYourEmailContentDescription)}
              />
            </VStack>

            <HStack spacing="m">
              <Button
                variant="secondary"
                size="m"
                data-test="email-campaign-preview-send-test-email-modal-button"
                onClick={async () => {
                  if (venue) {
                    if (shouldUseTopol) {
                      await saveTopol()
                    }

                    nav.push(routes.manager2.marketing.emailCenter.emails.emailBuilder.sendTestEmailModal, {
                      params: { venueKey: venue.urlKey },
                    })
                  }
                }}
              >
                {formatMessage(emailBuilderMessages.sendTestEmailButtonText)}
              </Button>
            </HStack>
          </HStack>

          {shouldUseTopol ? (
            <Box width="100%" height="700px">
              <Topol
                html={fields.prop('emailBodySections.0.value')}
                json={fields.prop('topolTemplate')}
                initialValue={template}
                venueKey={venueKey}
                venueProfile={venueProfile}
                isLoyaltyAndPerksEnabled={venueSettings?.isLoyaltyAndPerksEnabled}
                referralProgramEnabled={venueSettings?.referralProgramEnabled}
                venueUrlKey={venue?.urlKey}
                experiencesData={experiencesData}
                groupVenues={groupVenues}
              />
            </Box>
          ) : (
            <BodyContentWrapper disable={disabled}>
              <EmailEditor
                hasDesktopView
                field={fields.prop('emailBodySections.0.value')}
                destination={routes.manager2.marketing.emailCenter.emails.emailBuilder.emailBuilderFromScratch}
                imageUpload={imageUpload}
                disabled={disabled}
                experiencesData={experiencesData}
                crossPromotionChoices={crossPromotionChoices}
                groupVenuesDict={groupVenuesDict}
                venueProfile={venueProfile}
                isDirty={isDirty}
                setGoToOffers={setGoToOffers}
                venue={venue}
                data-test="emailBodySection"
              />
            </BodyContentWrapper>
          )}
        </VStack>
      </Box>
    </BaseSection>
  )
}
