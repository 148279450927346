import { defineMessages } from '@sevenrooms/core/locales'

export const paymentMessages = defineMessages({
  chargesPriceTitle: {
    id: 'chargesPriceTitle',
    defaultMessage: 'Reservation Price',
  },
  chargesUpgradesTitle: {
    id: 'chargesReservationPriceTitle',
    defaultMessage: 'Upgrades - {name}',
  },
  gratuity: {
    id: 'gratuity',
    defaultMessage: 'Gratuity (not taxed)',
  },
  service: {
    id: 'service',
    defaultMessage: 'Service Charge (taxed)',
  },
  tax: {
    id: 'tax',
    defaultMessage: 'Tax',
  },
  taxesAndFees: {
    id: 'taxesAndFees',
    defaultMessage: 'Taxes and Service Fees',
  },
  total: {
    id: 'total',
    defaultMessage: 'Total',
  },
  previousPayment: {
    id: 'previousPayment',
    defaultMessage: 'Previous Payment',
  },
  amountCharge: {
    id: 'amountCharge',
    defaultMessage: 'Amount to be Charged',
  },
  amountRefund: {
    id: 'amountRefund',
    defaultMessage: 'Amount to be Refunded',
  },
  amountRefundWarning: {
    id: 'amountRefundWarning',
    defaultMessage: 'Refunds must be issued separately.',
  },
  amount: {
    id: 'amount',
    defaultMessage: 'Amount',
  },
} as const)
