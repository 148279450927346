import _ from 'lodash'
// eslint-disable-next-line import/no-cycle
import * as BookActions from 'mgr/actualslideout/actions/BookActions'
import * as GlobalActionTypes from 'mgr/lib/actions/GlobalActionTypes'
import AssignmentAndProblemsServices from 'mgr/lib/services/AssignmentAndProblemsServices'
import { NotificationLevel } from 'mgr/lib/utils/Constants'
import { batchActions } from 'svr/common/ReduxUtils'
import * as ActionTypes from './ActionTypes'
import { clearAvailabilityTimeslots } from '@sevenrooms/core/api'

/* GENERAL SLIDEOUT ACTIONS */
export const initialize = globalInit => ({
  type: GlobalActionTypes.INITIALIZE,
  globalInit,
})
export const closeSlideout = message => ({
  type: ActionTypes.CLOSE_SLIDEOUT,
  message,
})
export const verifyNoEditAndCloseSlideout = message => (dispatch, getState) => {
  const state = getState()
  const { isViewMode } = state.slideoutState
  const { isDirty } = state.bookState

  if (!isViewMode && isDirty) {
    dispatch(BookActions.onBeforePageUnload())
    return
  }
  dispatch(closeSlideout(message))
}

export const showNotificationBanner = notificationBannerState => ({
  type: ActionTypes.SHOW_NOTIFICATION_BANNER,
  notificationBannerState,
})
export const showNotificationError = message =>
  showNotificationBanner({
    message,
    level: NotificationLevel.ERROR,
    visible: true,
  })

export const closeNotificationBanner = () => ({
  type: ActionTypes.CLOSE_NOTIFICATION_BANNER,
})
export const clickNotificationBannerAction = () => ({
  type: ActionTypes.CLICK_NOTIFICATION_BANNER_ACTION,
})
const doEnterEditReservation = params => ({
  type: ActionTypes.ENTER_EDIT_RESERVATION,
  ...params,
})
export const enterEditReservation = params => (dispatch, getState) => {
  const state = getState()
  const { selectedTableIds, isAutoAssign, isCustomAssign } = state.viewResState
  const venue = state.bookState.selectedVenue
  dispatch(clearAvailabilityTimeslots())
  dispatch(
    doEnterEditReservation({
      ...params,
      selectedTableIds,
      isAutoAssign,
      isCustomAssign,
      venue,
      accountId: venue.accountId,
      connectedSetupIntents: venue.connectedSetupIntents,
    })
  )
}

export const enterCloneReservation = params => ({
  type: ActionTypes.ENTER_CLONE_RESERVATION,
  ...params,
})
export const resetBookedByNames = params => ({
  type: ActionTypes.RESET_BOOKED_BY_NAMES,
  ...params,
})

export const invokeRegisteredHandlers = (store, handlerName, params) => {
  for (const handlers of store.slideoutState.registeredHandlers) {
    handlers[handlerName] && handlers[handlerName](...params)
  }
}

export const postbackActualOnEdit =
  (autoAssignmentsAndProblemResPromise, response = null) =>
  (dispatch, getState) => {
    const store = getState()
    const { actual } = store.viewResState

    // Bridge between ES6 promise to a jQuery promise
    const jqPromiseBridge = new $.Deferred()
    autoAssignmentsAndProblemResPromise.then(aaAndProblems => {
      const legacyAssignmentsAndProblems = {
        problems: aaAndProblems.problems,
        assignments: aaAndProblems.autoAssignments,
      }
      response = response || { statusCode: 200, payload: { actual } }
      jqPromiseBridge.resolve(legacyAssignmentsAndProblems)
      invokeRegisteredHandlers(store, 'onEditSave', [actual.id, actual, response, jqPromiseBridge])
    })
  }

export const postbackActualOnBook = (actual, response, autoAssignmentsAndProblemResPromise) => (dispatch, getState) => {
  const store = getState()
  // Bridge between ES6 promise to a jQuery promise
  const jqPromiseBridge = new $.Deferred()
  autoAssignmentsAndProblemResPromise.then(aaAndProblems => {
    const legacyAssignmentsAndProblems = {
      problems: aaAndProblems.problems,
      assignments: aaAndProblems.autoAssignments,
    }
    jqPromiseBridge.resolve(legacyAssignmentsAndProblems)
    invokeRegisteredHandlers(store, 'onBook', [actual, response, jqPromiseBridge])
  })
}

const getAutoAssignmentsAndProblemsBegin = () => ({
  type: ActionTypes.LOAD_AUTO_ASSIGNMENTS_AND_PROBLEMS_START,
})
const getAutoAssignmentsAndProblemsSuccess = (problems, autoAssignments) => ({
  type: ActionTypes.LOAD_AUTO_ASSIGNMENTS_AND_PROBLEMS_SUCCESS,
  problems,
  autoAssignments,
})
const getAutoAssignmentsAndProblemsFail = () => ({
  type: ActionTypes.LOAD_AUTO_ASSIGNMENTS_AND_PROBLEMS_FAIL,
})

export const tryGetAutoAssignmentsAndProblems =
  (forceUpdateActualId = null, actual = null) =>
  (dispatch, getState) => {
    dispatch(getAutoAssignmentsAndProblemsBegin())
    if (_.isNil(actual)) {
      const state = getState()
      actual = state.viewResState.actual
    }
    const venueId = actual.venue_id
    const shiftPersistentId = actual.shift_persistent_id
    const date = actual.date_moment

    const errHandler = error =>
      dispatch(batchActions([getAutoAssignmentsAndProblemsFail(), showNotificationError(`Error getting table assignments: ${error}`)]))

    return AssignmentAndProblemsServices.fetchAutoAssignmentsAndProblems(
      venueId,
      date,
      shiftPersistentId,
      true,
      true,
      forceUpdateActualId,
      errHandler
    ).then(data => {
      const cbState = getState()
      const cbActual = cbState.viewResState.actual
      if (cbActual && actual.id === cbActual.id) {
        return dispatch(getAutoAssignmentsAndProblemsSuccess(data.problems, data.assignments))
      }
      svrDebug('Ignoring slow response for fetchAutoAssignmentsAndProblems')
    })
  }

/* EXPOSED EXTERNALLY */

export const enterAddReservation = params => ({
  type: ActionTypes.ENTER_ADD_RESERVATION,
  ...params,
})
export const registerCallbackHandlers = handlers => ({
  type: ActionTypes.REGISTER_CALLBACK_HANDLERS,
  handlers,
})
