import { skipToken } from '@reduxjs/toolkit/query'
import type { ISODate, AvailabilityTimeslotRequest } from '@sevenrooms/core/domain'
import type { State } from '../../selectors/useStoreSelector'

export function availabilityQueryParamsSelector(bookAvailabilityState: State['bookAvailabilityState']) {
  const {
    showAccessRules,
    shiftPersistentId,
    date,
    partySize,
    duration,
    seatingAreaId,
    searchVenues,
    audienceId,
    actual,
    bufferMins,
    availabilityByVenue,
  } = bookAvailabilityState
  const venueId = searchVenues[0]?.id
  return showAccessRules && shiftPersistentId && venueId && audienceId && availabilityByVenue[venueId]?.availableTimes?.length
    ? ({
        venue: venueId,
        date: date.format('YYYY-MM-DD') as ISODate,
        partySize,
        duration,
        seatingArea: seatingAreaId,
        channel: audienceId,
        actualId: actual?.id,
        buffer: bufferMins,
      } as AvailabilityTimeslotRequest)
    : skipToken
}
