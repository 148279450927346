// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in MA-857
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { emailCenterMessages } from '../../locales'
import { RecommendedLabel } from '../RecommendedLabel'

export function StartOngoingFromTemplateCard() {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()

  if (!venue || !venueSettings) {
    return null
  }

  return (
    <SectionWrapper>
      <VStack ml="l" mr="l" mb="l" alignItems="center" justifyContent="space-between">
        {venueSettings.pre_built_campaigns_enabled && venueSettings.is_custom_automated_emails_enabled && <RecommendedLabel />}
        <BaseSection>
          <VStack
            mt={venueSettings.pre_built_campaigns_enabled ? 'm' : 'lm'}
            alignItems="center"
            justifyContent="space-between"
            minHeight="194px"
          >
            <VStack alignItems="center">
              <Header type="h3">{formatMessage(emailCenterMessages.emailCenterStandardAutomatedEmails)}</Header>
              <Description>{formatMessage(emailCenterMessages.emailCenterStandardAutomatedEmailsDescription)}</Description>
            </VStack>
            <Button
              data-test="start-ongoing-button"
              onClick={() => {
                nav.push(routes.manager2.marketing.emailCenter.emails.emailTemplate, {
                  params: { venueKey: venue.urlKey },
                  queryMode: 'clear',
                })
              }}
              disabled={!venueSettings.pre_built_campaigns_enabled}
            >
              {formatMessage(
                venueSettings.pre_built_campaigns_enabled
                  ? emailCenterMessages.emailCenterUseStandardAutomatedEmailsOn
                  : emailCenterMessages.emailCenterUseStandardAutomatedEmailsOff
              )}
            </Button>
          </VStack>
        </BaseSection>
      </VStack>
    </SectionWrapper>
  )
}

const SectionWrapper = styled.div`
  border: 1px solid #cccccc;
  box-sizing: border-box;
  box-shadow: ${props => props.theme.shadows.primary};
  border-radius: 4px;
  width: 315px;
  section {
    border: none;
  }
`

const Description = styled(Text)`
  padding: 12px 0px;
  text-align: center;
`
