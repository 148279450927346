/* eslint-disable camelcase */
import money from 'money-math'
import type { AutomaticUpsell, GratuityCharge } from '@sevenrooms/core/api'
import type { TaxGroup } from '@sevenrooms/core/domain'
import type { PaymentProps } from './Payment'
import type { ChargesForm, PaymentForm, UpgradeForm, CategoryForm } from './Payment.zod'

export function collectTotalCategories(
  category: Record<string, CategoryForm>,
  taxGroups: TaxGroup[],
  override: boolean,
  taxesOnly?: boolean
) {
  return Object.entries(category).reduce(
    (acc, [, { charges, upgrades }]) => acc + collectTotalUpgrades(upgrades, charges, taxGroups, override, taxesOnly),
    0
  )
}

export function collectTotalUpgrades(
  upgrades: Record<string, UpgradeForm>,
  charges: ChargesForm,
  taxGroups: TaxGroup[],
  override: boolean,
  taxesOnly?: boolean
) {
  return Object.entries(upgrades).reduce(
    (acc, [, { amount }]) => (!amount ? acc : acc + calculateTotal(amount, charges, taxGroups, override, taxesOnly).total),
    0
  )
}

interface Totals {
  total: number
  gratuityAmount: number
  serviceAmount: number
  taxAmount: number
}
interface Total {
  total: number
}

export function calculateTotal<T extends boolean>(
  amount: number,
  charges: Partial<ChargesForm>,
  taxGroups: TaxGroup[],
  override: boolean,
  taxesOnly?: T
): T extends true ? Total : Totals
export function calculateTotal(
  amount: number,
  charges: Partial<ChargesForm>,
  taxGroups: TaxGroup[],
  override: boolean,
  taxesOnly?: boolean
): Total | Totals {
  const { service, applyService, taxId, applyTax, gratuity, applyGratuity } = charges
  let total = 0
  const taxRate = taxGroups.find(option => option.id === taxId)?.taxRate
  const serviceAmount = (override || applyService) && service ? amount * service : 0
  const subtotal = amount + serviceAmount / 100
  const taxAmount = (override || applyTax) && taxRate ? subtotal * taxRate : 0
  if (taxesOnly) {
    total = (serviceAmount + taxAmount) / 100
    return { total }
  }
  const gratuityAmount = (override || applyGratuity) && gratuity ? amount * gratuity : 0
  total = amount + (serviceAmount + taxAmount + gratuityAmount) / 100
  return {
    total,
    gratuityAmount: gratuityAmount / 100,
    serviceAmount: serviceAmount / 100,
    taxAmount: taxAmount / 100,
  }
}

export function calculateTotalToReducer(
  values: PaymentForm,
  taxGroups: TaxGroup[],
  override: boolean,
  bundledUpgrades: AutomaticUpsell[],
  oldValues?: PaymentProps['oldValues']
) {
  // reservation price
  const amount = values.amount ?? 0
  const { service, applyService, taxId, applyTax, gratuity, applyGratuity } = values.charges
  const taxGroup = taxGroups.find(g => g.id === taxId)
  const { total, gratuityAmount, serviceAmount, taxAmount } = calculateTotal(amount, values.charges, taxGroups, false, false)

  let upgradesTotal = 0
  // bundled upgrades
  const automaticallyIncludedUpsells: AutomaticUpsellApi[] = []
  Object.entries(values.categoriesBundled).forEach(([, { upgrades, charges }]) => {
    Object.entries(upgrades).forEach(([id, { amount, count }]) => {
      upgradesTotal += calculateTotal(amount ?? 0, charges, taxGroups, override, false).total
      const upgrade = bundledUpgrades?.find(upgrade => upgrade.id === id)
      if (upgrade) {
        automaticallyIncludedUpsells.push({
          id,
          quantity_equal_type: upgrade.quantity_equal_type,
          price: (amount ?? 0) / count,
          quantity_num: count,
        })
      }
    })
  })
  // selectable upgrades
  let upgradesAmount = 0
  const selectedCategories: SelectedUpsellApi['selected_categories'] = {}
  const selectedUpsells: SelectedUpsellApi['selected_inventories'] = {}
  // selectable upgrades snapshot
  const selectedCategoriesSnapshot: SelectedUpsellApi['selected_categories'] = {}
  const selectedUpsellsSnapshot: SelectedUpsellApi['selected_inventories'] = {}
  Object.entries(values.categories).forEach(([catId, { charges, upgrades }]) => {
    const categoryObject = {
      gratuity_charge_type: charges.gratuityClientSelect ? 'CLIENT_GRATUITY' : 'SPECIFIC_GRATUITY',
      is_charging_gratuity: charges.applyGratuity,
      gratuity_percentage: charges.gratuity,
      is_charging_service_charge: charges.applyService,
      service_charge_percentage: charges.service,
      is_charging_tax: charges.applyTax,
      tax_group_id: charges.taxId,
    } as const
    selectedCategories[catId] = categoryObject
    if (oldValues?.categories && !oldValues.categories[catId]) {
      selectedCategoriesSnapshot[catId] = categoryObject
    }
    Object.entries(upgrades).forEach(([id, { amount, count }]) => {
      upgradesTotal += calculateTotal(amount ?? 0, charges, taxGroups, override, false).total
      upgradesAmount += amount ?? 0
      const upgradeObject = {
        price: (amount ?? 0) / count,
        quantity: count,
      } as const
      selectedUpsells[id] = upgradeObject
      if (oldValues?.categories && !oldValues.categories[catId]?.upgrades[id]) {
        selectedUpsellsSnapshot[id] = upgradeObject
      }
    })
  })

  let prevTotal = 0
  let prevUpgradesAmount = 0
  if (oldValues) {
    prevTotal += calculateTotal(oldValues.amount ?? 0, oldValues.charges, taxGroups, false, false).total
    prevTotal += collectTotalCategories(oldValues.categories, taxGroups, false, false)
    prevTotal += collectTotalCategories(oldValues.categoriesBundled, taxGroups, false, false)
    prevUpgradesAmount = Object.values(oldValues.categories).reduce(
      (acc, category) => acc + Object.values(category.upgrades).reduce((acc, upgrade) => acc + (upgrade.amount ?? 0), 0),
      0
    )
  }

  return {
    // gratuity
    applyGratuityCharge: applyGratuity,
    gratuityCharge: gratuity,
    gratuityChargeAmount: money.floatToAmount(gratuityAmount),
    // tax
    chargeApplyTax: applyTax,
    chargeTax: taxGroup?.taxRate,
    taxGroupId: taxGroup?.id,
    taxAmount: money.floatToAmount(taxAmount),
    // service
    applyServiceCharge: applyService,
    serviceCharge: service,
    serviceChargeAmount: money.floatToAmount(serviceAmount),
    // base amount
    chargeAmount: money.floatToAmount(amount),
    // total
    chargeTotal: money.floatToAmount(total + upgradesTotal - prevTotal),
    // upgrades
    upgrades: {
      upgradesTotal,
      upsellAmount: upgradesAmount - prevUpgradesAmount,
      automaticallyIncludedUpsells,
      selectedUpsells: {
        selected_categories: selectedCategories,
        selected_inventories: selectedUpsells,
      },
      chargeUpsellSnapshot: oldValues
        ? {
            selected_categories: selectedCategoriesSnapshot,
            selected_inventories: selectedUpsellsSnapshot,
          }
        : undefined,
    },
  }
}

export type CalculateTotalToReducer = ReturnType<typeof calculateTotalToReducer>

export function switchToNoPaymentUpgrades(upgrades: CalculateTotalToReducer['upgrades']) {
  const inventoriesMapper = (inventories: SelectedUpsellApi['selected_inventories']) =>
    Object.fromEntries(Object.entries(inventories).map(([key, inventory]) => [key, { ...inventory, price: 0 }]))
  return {
    upgradesTotal: 0,
    upsellAmount: 0,
    automaticallyIncludedUpsells: upgrades.automaticallyIncludedUpsells.map(inventory => ({ ...inventory, price: 0 })),
    selectedUpsells: {
      selected_categories: upgrades.selectedUpsells.selected_categories,
      selected_inventories: inventoriesMapper(upgrades.selectedUpsells.selected_inventories),
    },
    chargeUpsellSnapshot: upgrades.chargeUpsellSnapshot
      ? {
          selected_categories: upgrades.chargeUpsellSnapshot.selected_categories,
          selected_inventories: inventoriesMapper(upgrades.chargeUpsellSnapshot.selected_inventories),
        }
      : undefined,
  }
}

interface AutomaticUpsellApi extends AutomaticUpsell {
  price: number
}

interface SelectedUpsellApi {
  selected_categories: Record<
    string,
    {
      gratuity_charge_type: GratuityCharge
      is_charging_gratuity: boolean
      gratuity_percentage: number | null
      is_charging_tax: boolean
      tax_group_id: string | null
      is_charging_service_charge: boolean
      service_charge_percentage: number | null
    }
  >
  selected_inventories: Record<
    string,
    {
      price: number
      quantity: number
    }
  >
}
