import _ from 'lodash'
import { type ChangeEvent, useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'

const CHAR_LIMIT = 10
const MIN_BUTTON_CHOICE = 2
const MAX_BUTTON_CHOICE = 10

function getValueFromPartySize(partySize?: number) {
  const value = partySize != null && (partySize < MIN_BUTTON_CHOICE || partySize > MAX_BUTTON_CHOICE) ? partySize : null
  return value ? String(value) : ''
}

interface CustomerGuestsInputProps {
  value: string
  onChange: (value: string) => void
  testId?: string
  onBlur: () => void
  onFocus: () => void
}

function CustomGuestsInput({ value, onChange, testId, onBlur, onFocus }: CustomerGuestsInputProps) {
  const changeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let newVal = e.target.value
      if (newVal !== '') {
        const match = newVal.match(/[0-9]+/g)
        newVal = match == null ? '' : match[0]?.substring(0, CHAR_LIMIT) ?? ''
      }
      if (newVal !== value) {
        onChange(newVal)
      }
    },
    [onChange, value]
  )

  return (
    <StyledInput
      value={value}
      onChange={changeHandler}
      onBlur={onBlur}
      onFocus={onFocus}
      maxLength={CHAR_LIMIT}
      $selected={value !== ''}
      type="text"
      placeholder="___"
      data-test={testId}
    />
  )
}

interface AvailabilityPartySizeSelectBarProps {
  testId: string
  partySize?: number
  onChangeHandler: (value?: number) => void
}

export default function AvailabilityPartySizeSelectBar({ testId, partySize, onChangeHandler }: AvailabilityPartySizeSelectBarProps) {
  const [value, setValue] = useState(() => getValueFromPartySize(partySize))
  const [focused, setFocused] = useState(false)

  const onClick = useCallback(
    (i: number) => {
      setValue('')
      if (i !== partySize) {
        onChangeHandler(i)
      }
    },
    [onChangeHandler, partySize]
  )

  const onChange = useCallback(
    (val: string) => {
      setValue(val)
      const newVal = val !== '' ? Number(val) : undefined
      if (newVal !== partySize) {
        onChangeHandler(newVal)
      }
    },
    [onChangeHandler, partySize]
  )

  const onBlur = useCallback(() => {
    setFocused(false)
    if (getValueFromPartySize(partySize) === '') {
      setValue('')
    }
  }, [partySize])

  const onFocus = useCallback(() => {
    setFocused(true)
  }, [])

  useEffect(() => {
    const newVal = getValueFromPartySize(partySize)
    if (newVal !== '' || !focused) {
      setValue(newVal)
    }
  }, [focused, partySize])

  const buttonChoices = _.range(MIN_BUTTON_CHOICE, MAX_BUTTON_CHOICE + 1).map(i => (
    <ButtonChoice
      data-test={`sr-choice-${i}`}
      key={`ButtonChoice_${i}`}
      $selected={partySize === i}
      $isFirst={i === MIN_BUTTON_CHOICE}
      onClick={() => onClick(i)}
    >
      {i}
    </ButtonChoice>
  ))
  return (
    <div data-test={testId}>
      <NameOutside>GUESTS</NameOutside>
      <BarWrapper>
        {buttonChoices}
        <CustomGuestsInput testId="sr-party-size-input" value={value} {...{ onFocus, onBlur, onChange }} />
      </BarWrapper>
    </div>
  )
}

const NameOutside = styled.label`
  color: ${props => props.theme.darkGrey};
  ${props => props.theme.fontWeight300} font-size: 11px;
  display: block;
  padding: 0 0 4px 10px;
`
const BarWrapper = styled.div`
  height: 44px;
  width: 453px;
  max-width: 100%;
  border: 1px solid ${props => props.theme.lightGrey};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

const ButtonChoice = styled.div<{ $selected: boolean; $isFirst: boolean }>`
  width: 44px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  line-height: 44px;
  ${props => (props.$isFirst ? 'border-radius: 4px 0 0 4px;' : '')}
  border-right: 1px solid ${props => props.theme.lightGrey};
  color: ${props => (props.$selected ? props.theme.white : props.theme.navigationDark)};
  background-color: ${props => (props.$selected ? props.theme.primarySelected : props.theme.white)};
  :hover {
    background-color: ${props => (props.$selected ? props.theme.primarySelected : props.theme.lightGrey)};
  }
`

// Ugly to have all these !important decorators, but needed for production.css overrides on input fields
const StyledInput = styled.input<{ $selected: boolean }>`
  flex-grow: 1;
  border-radius: 0 4px 4px 0;
  font-size: 18px !important;
  font-weight: 500;
  line-height: 19px;
  width: 28px !important;
  min-width: 28px;
  border: 0 !important;
  outline: none;
  overflow: hidden;
  background-color: ${props => (props.$selected ? props.theme.primarySelected : props.theme.background)} !important;
  padding: 0 10px !important;
  color: ${props => (props.$selected ? props.theme.white : props.theme.navigationDark)} !important;
  ::-webkit-input-placeholder {
    color: ${props => props.theme.lightGrey};
    font-family: inherit;
  }
  ::-moz-placeholder {
    color: ${props => props.theme.lightGrey};
    font-family: inherit;
  }
  :-ms-input-placeholder {
    color: ${props => props.theme.lightGrey};
    font-family: inherit;
  }
`
