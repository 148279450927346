import _ from 'lodash'
import React from 'react'
import styled, { css } from 'styled-components'
import DropdownArrowsPicker from 'mgr/lib/components/DropdownArrowsPicker'
import DateRangePicker from 'mgr/pages/single-venue/marketing/components/shared/DateRangePicker'
import { dateFilters, platformFilters, ratingFilters, feedbackTypeFilters } from 'mgr/pages/single-venue/marketing/reducers/Filters'
import TextInput from 'svr/component-lib/Generic/TextInputs/TextInput'

const FiltersContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

const FiltersOnlyContainer = styled.div`
  display: flex;
`

export const MarketingFilter = ({
  choices,
  value = null,
  values = null,
  onChangeHandler,
  isMultiSelect,
  style,
  name = '',
  noHeader = false,
  showNavigation,
  displayOverride = null,
}) => (
  <DropdownArrowsPicker
    choices={choices}
    value={value}
    values={values}
    isLightTheme
    style={style}
    name={name}
    onChangeHandler={onChangeHandler}
    isMultiSelect={isMultiSelect}
    noHeader={noHeader}
    useOutsideLabel={false}
    showNavigation={showNavigation}
    displayOverride={displayOverride}
  />
)

// TODO probably make this more dynamic and abstract it out of guest-satisfaction
// TODO make this fixed
class Filters extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      search: '',
    }

    this.onSearchChange = this.onSearchChange.bind(this)
    this.onDateFilterChange = this.onDateFilterChange.bind(this)

    this.handleSearchDebounced = _.debounce(function () {
      this.props.onSearchFilterChange.apply(this, [this.state.search])
      window.metric.track('GuestSatisfaction.SearchedReviews')
    }, 800)
  }

  onFilterChange = filter => (selected, type) => {
    const handler = this.props[filter]

    if (_.includes(selected, 'all') && type === 'added') {
      handler('all')
    } else {
      handler(selected.filter(filter => filter && filter !== 'all').join(','))
    }
  }

  onDateFilterChange(value) {
    this.props.onDateFilterChange(value)
  }

  componentDidMount() {
    this.setState({ search: this.props.searchValue })
  }

  onSearchChange(value) {
    this.setState({ search: value })
    this.props.startLoading()
    this.handleSearchDebounced()
  }

  render() {
    const {
      dateValue,
      platformValues,
      ratingValues,
      feedbackTypeValues,
      startDate,
      endDate,
      onCustomDateRangeChange,
      locale,
      isFullFeatured,
      channels,
      configuredChannels,
      isOrdersIntegrationEnabled,
    } = this.props

    return (
      <FiltersContainer>
        <FiltersOnlyContainer>
          <MarketingFilter
            choices={dateFilters}
            value={dateValue}
            style={{ width: 'auto' }}
            onChangeHandler={this.onDateFilterChange}
            name="Date"
          />
          {dateValue !== 'all' && (
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onDateChange={onCustomDateRangeChange}
              hasMinDate={false}
              locale={locale}
            />
          )}
          {isFullFeatured && (
            <MarketingFilter
              choices={platformFilters(configuredChannels)}
              values={platformValues}
              onChangeHandler={this.onFilterChange('onPlatformFilterChange')}
              style={{ width: 200 }}
              isMultiSelect
              name="Platform"
            />
          )}
          <MarketingFilter
            choices={ratingFilters}
            values={ratingValues}
            onChangeHandler={this.onFilterChange('onRatingFilterChange')}
            style={{ width: 100 }}
            isMultiSelect
            name="Rating"
          />
          {isOrdersIntegrationEnabled && (
            <MarketingFilter
              choices={feedbackTypeFilters}
              values={feedbackTypeValues}
              onChangeHandler={this.onFilterChange('onFeedbackTypeFilterChange')}
              style={{ width: 100 }}
              isMultiSelect
              name="Type"
            />
          )}
        </FiltersOnlyContainer>
        <TextInput
          placeholder="Search"
          value={this.state.search}
          onChange={this.onSearchChange}
          customInput={css`
            border-color: #dedede !important;
            padding-left: ${props => props.theme.gutter.standard} !important;
          `}
          customInputWrapper={css`
            width: auto;
          `}
        />
      </FiltersContainer>
    )
  }
}

export default Filters
