import { skipToken } from '@reduxjs/toolkit/query'
import { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { type CampaignActivityItem, useGetClientTagGroupsQuery } from '@sevenrooms/core/api'
import { isTagGlobal } from '@sevenrooms/core/domain'
import { Locale } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Loader, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { useEmailCampaignPerformanceContext } from '@sevenrooms/marketing'
import { useVenueContext, useStoreSelector } from '@sevenrooms/mgr-core'
import { getCampaignInfo, setEmailCampaignTableRows } from '../../store'
import { ConfirmArchiveModal } from '../ConfirmArchiveModal'
import { EmailCampaignTable, type EmailCampaignTableRow } from '../EmailCampaignTable'

export interface UnformattedEmailCampaignTableRow
  extends Omit<
    EmailCampaignTableRow,
    | 'recipients'
    | 'revenue'
    | 'clicks'
    | 'reservations'
    | 'covers'
    | 'orders'
    | 'opens'
    | 'unsubscribed'
    | 'bounces'
    | 'complaints'
    | 'crossSelling'
  > {
  recipients: number
  revenue: number
  clicks: number
  reservations: number
  unsubscribed: number
  bounces: number
  complaints: number
  crossSelling: number
  covers: number
  orders: number
  opens: number
  usesGlobalTag: boolean
}

export function EmailCampaigns() {
  const { venue } = useVenueContext()
  const nav = useNavigation()
  const dispatch = useDispatch()
  const campaignInfo = useStoreSelector(getCampaignInfo)
  const {
    filteredCampaigns,
    isCampaignQueryFetching,
    isCampaignQuerySuccess,
    campaignsRefetch,
    campaignActivityData,
    isActivityQueryFetching,
    isActivityQuerySuccess,
    averageSpendPerCover,
    isPOSActivated,
  } = useEmailCampaignPerformanceContext()

  const venueKey = venue?.urlKey
  const { data: clientAutotags, isFetching: isClientAutotagsFetching } = useGetClientTagGroupsQuery(venueKey ? { venueKey } : skipToken)

  const getRevenue = useCallback(
    (campaignActivityData?: CampaignActivityItem) => {
      let revenue = 0
      if (campaignActivityData) {
        if (!isPOSActivated && averageSpendPerCover) {
          return campaignActivityData.covers * averageSpendPerCover
        }
        revenue = campaignActivityData.reservationRevenue || 0
        revenue += campaignActivityData.orderRevenue || 0
      }
      return revenue
    },
    [isPOSActivated, averageSpendPerCover]
  )

  const locale = Locale.getLocale(venue?.locale)
  const currencySymbol = venue?.currencySymbol

  const sortByStatusAndName = (a: UnformattedEmailCampaignTableRow, b: UnformattedEmailCampaignTableRow) =>
    campaignStatusSortOrder[a.status] - campaignStatusSortOrder[b.status] || a.campaignName.localeCompare(b.campaignName)

  useMemo(() => {
    const formatEmailCampaignTableRows = (unformattedRows: UnformattedEmailCampaignTableRow[]): EmailCampaignTableRow[] =>
      unformattedRows.map(row => ({
        ...row,
        recipients: row.recipients.toLocaleString(locale),
        opens: row.opens.toLocaleString(locale),
        clicks: row.clicks.toLocaleString(locale),
        revenue: `${currencySymbol ?? ''}${Math.round(parseFloat(`${row.revenue}`)).toLocaleString(locale)}`,
        reservations: row.reservations.toLocaleString(locale),
        covers: row.covers.toLocaleString(locale),
        orders: row.orders.toLocaleString(locale),
        unsubscribed: row.unsubscribed.toLocaleString(locale),
        bounces: row.bounces.toLocaleString(locale),
        complaints: row.complaints.toLocaleString(locale),
        crossSelling: row.crossSelling.toLocaleString(locale),
      }))

    if (isCampaignQuerySuccess && isActivityQuerySuccess && !isClientAutotagsFetching) {
      const campaignsData: UnformattedEmailCampaignTableRow[] = []
      if (filteredCampaigns && campaignActivityData) {
        for (const campaign of filteredCampaigns) {
          const campaignData: UnformattedEmailCampaignTableRow = {
            campaignName: campaign.campaignName,
            emailSubject: campaign.emailSubject,
            status: campaign.status,
            id: campaign.id,
            recipients: campaignActivityData.campaignActivity[campaign.id]?.recipients || 0,
            revenue: getRevenue(campaignActivityData.campaignActivity[campaign.id]),
            clicks: campaignActivityData.campaignActivity[campaign.id]?.clicks || 0,
            reservations: campaignActivityData.campaignActivity[campaign.id]?.reservations || 0,
            covers: campaignActivityData.campaignActivity[campaign.id]?.covers || 0,
            orders: campaignActivityData.campaignActivity[campaign.id]?.orders || 0,
            unsubscribed: campaignActivityData.campaignActivity[campaign.id]?.unsubscribed || 0,
            bounces: campaignActivityData.campaignActivity[campaign.id]?.bounces || 0,
            complaints: campaignActivityData.campaignActivity[campaign.id]?.complaints || 0,
            opens: campaignActivityData.campaignActivity[campaign.id]?.opens || 0,
            crossSelling: campaignActivityData.campaignActivity[campaign.id]?.crossSelling || 0,
            created: new Date(campaign.created),
            parentTemplateId: campaign?.parentTemplateId,
            updated: campaign?.updated,
            hasBeenManuallyModified: campaign?.hasBeenManuallyModified,
            hasParentTemplateUpdates: campaign?.hasParentTemplateUpdates,
            usesGlobalTag: isTagGlobal(campaign?.recipientClientTags, clientAutotags),
            recipientClientTags: campaign?.recipientClientTags,
            campaignEmailType: campaign?.campaignEmailType,
          }
          campaignsData.push(campaignData)
        }
        campaignsData.sort(sortByStatusAndName)
      }
      dispatch(setEmailCampaignTableRows(formatEmailCampaignTableRows(campaignsData)))
    }
  }, [
    campaignActivityData,
    clientAutotags,
    currencySymbol,
    dispatch,
    filteredCampaigns,
    isActivityQuerySuccess,
    isCampaignQuerySuccess,
    isClientAutotagsFetching,
    locale,
    getRevenue,
  ])

  if (!venue) {
    return null
  }

  return (
    <>
      <VStack spacing="lm" width="100%">
        {isActivityQueryFetching || isCampaignQueryFetching ? <Loader /> : <EmailCampaignTable />}
      </VStack>

      <Surface destination={routes.manager2.marketing.emailCenter.emails.archiveEmail}>
        <Window>
          <ConfirmArchiveModal
            onConfirm={campaignsRefetch}
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.emailCenter.emails.archiveEmail, {
              params: { venueKey: venue.urlKey, campaignId: campaignInfo.currentCampaignId },
            })}
          />
        </Window>
      </Surface>
    </>
  )
}

const campaignStatusSortOrder = {
  ACTIVE: 1,
  DRAFT: 2,
  INACTIVE: 3,
  SENT: 4,
  SENDING: 5,
  ARCHIVED: 6,
}
