import _ from 'lodash'
import React from 'react'
import Tags from 'mgr/lib/components/Tags'
import moment from 'moment-timezone'
import { VmsIcons } from 'svr/common/VmsIcons'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { StatusLabel } from '@sevenrooms/core/ui-kit/typography'
import { DateTime } from '@sevenrooms/core/timepiece'
import { AddTextWithBoldPart, CardDetailFactory, ViewCardTitleSmall, StandardFieldText, ViewField } from './ViewCommon'
import { FlexColumnContainer } from './ViewLayout'
import { getAdjustedWeekday } from 'mgr/lib/utils/MomentUtils'

const AddNewTag = () => <AddTextWithBoldPart boldText="Add" regularText="reservation tags" />
const TagValueArea = ({ value }) => <Tags tags={value} />

const TagAreaComponent = CardDetailFactory(AddNewTag, TagValueArea)

export const TagSubCard = ({ selectedTags, editCallback }) => (
  <FlexColumnContainer onClick={editCallback}>
    <TagAreaComponent icon={VmsIcons.TagLine} label="Reservation Tags" value={selectedTags} />
  </FlexColumnContainer>
)

export const LoyaltyPerks = ({ perksList = [], autoTags = [], reservationTags = [], startDateTime = '' }) => {
  const matchedPerks = perksList.filter(perk => {
    const hasStartDateTime = startDateTime !== null && startDateTime !== ''
    let isWithinWeekAvailability = perk.isRestrictedByWeekOfDayAndTimeRange ? hasStartDateTime : true
    let isWithinDateRange = perk.isRestrictedByDateRange ? hasStartDateTime : true
    let hasAssociatedClientTag = false
    let hasAssociatedReservationTag = false
    let hasPerk = isWithinDateRange && isWithinWeekAvailability
    if (perk.associatedClientTags.length > 0) {
      hasAssociatedClientTag = perk.associatedClientTags.some(tag =>
        autoTags.some(autoTag => tag.label === autoTag.tag_name_display || tag.label === autoTag.tagName)
      )
    }

    if (perk.associatedReservationTags.length > 0) {
      hasAssociatedReservationTag = perk.associatedReservationTags.some(tag =>
        reservationTags.some(reservationTag => tag.label === reservationTag.tagName)
      )
    }

    hasPerk = hasPerk && (hasAssociatedClientTag || hasAssociatedReservationTag)
    if (!hasPerk) {
      return false
    }
    if (hasPerk && perk.isRestrictedByDateRange) {
      const reservationDateTime = DateTime.fromSafe(startDateTime)
      const withinStartDateRange = reservationDateTime.isGreaterThanOrEqualTo(perk.startDate)
      const withinEndDateRange = perk.endDate ? reservationDateTime.isLessThanOrEqualTo(perk.endDate) : true
      isWithinDateRange = withinStartDateRange && withinEndDateRange
      hasPerk = hasPerk && isWithinDateRange
    }
    if (!hasPerk) {
      return false
    }
    if (hasPerk && perk.isRestrictedByWeekOfDayAndTimeRange) {
      const reservationDateTime = DateTime.fromSafe(startDateTime)
      const reservationWeekday = getAdjustedWeekday(moment(startDateTime))
      isWithinWeekAvailability = Object.entries(perk.perkAvailabilityTimes).some(([perkWeekday, perkAvailability]) => {
        const availabilityStartDateTime = DateTime.fromSafe(`${reservationDateTime.toDateOnly().toIso()} ${perkAvailability.startTime}`)
        const availabilityEndDateTime = DateTime.fromSafe(`${reservationDateTime.toDateOnly().toIso()} ${perkAvailability.endTime}`)
        const isReservationOnPerkAvailabilityDay = reservationWeekday === parseInt(perkWeekday)
        const isReservationOnPerkAvailabilityDateTime =
          reservationDateTime.isGreaterThanOrEqualTo(availabilityStartDateTime) &&
          reservationDateTime.isLessThanOrEqualTo(availabilityEndDateTime)
        return isReservationOnPerkAvailabilityDay && isReservationOnPerkAvailabilityDateTime
      })
      hasPerk = hasPerk && isWithinWeekAvailability
    }

    return hasPerk
  })

  return (
    matchedPerks.length > 0 && (
      <HStack mt="sm" ml="m" pl="xs" flexWrap="wrap" rowGap="s" columnGap="s">
        {matchedPerks.map(perk => (
          <StatusLabel data-test="sr-perk-label" key={perk.id} variant="primaryNavigation">
            {perk.internalName}
          </StatusLabel>
        ))}
      </HStack>
    )
  )
}

export const NoteSubCard = ({ noteContents, editCallback }) => <StandardFieldText onClick={editCallback}>{noteContents}</StandardFieldText>

const AddPerks = () => <AddTextWithBoldPart boldText="Add" regularText="perks" />
const PerksValueArea = ({ value }) => <StandardFieldText>{value}</StandardFieldText>
const PerksAreaComponent = CardDetailFactory(AddPerks, PerksValueArea)
export const PerksSubCard = ({ startDateTime, freeFormPerks, editCallback, perksList, autoTags, reservationTags }) => (
  <FlexColumnContainer onClick={editCallback}>
    <PerksAreaComponent icon={VmsIcons.StarLine} label="Perks" value={freeFormPerks} />
    <LoyaltyPerks {...{ perksList, autoTags, reservationTags, startDateTime }} />
  </FlexColumnContainer>
)

const AddPrice = () => <AddTextWithBoldPart boldText="Add" regularText="price" />
const PriceValueArea = ({ value }) => <StandardFieldText>{value}</StandardFieldText>
const PriceAreaComponent = CardDetailFactory(AddPrice, PriceValueArea)
export const PriceSubCard = ({ costOptionAmountFormatted, editCallback }) => (
  <FlexColumnContainer onClick={editCallback}>
    <PriceAreaComponent icon={VmsIcons.Money} label="Price" value={costOptionAmountFormatted} />
  </FlexColumnContainer>
)
export const DetailsHeaderSubCard = () => <ViewCardTitleSmall>details</ViewCardTitleSmall>

export const buildCustomFieldInputs = (customFieldsConfig, customFields) =>
  customFieldsConfig.reduce((result, customField, idx) => {
    if (!customField.enabled) {
      return result
    }
    const isIndexedField = Boolean(customField.db_name)
    const value = (isIndexedField ? customFields[customField.db_name] : customFields.custom_unindexed[customField.system_name]) || ''
    if (value === '' && !customField.editable) {
      return result
    }
    const addCustomField = () => <AddTextWithBoldPart boldText="Add" regularText={customField.name.toLowerCase()} />
    const viewCustomField = ({ value }) => <StandardFieldText>{value}</StandardFieldText>
    const CustomFieldComponent = CardDetailFactory(addCustomField, viewCustomField)
    result.push(
      // eslint-disable-next-line react/no-array-index-key
      <ViewField key={idx}>
        <CustomFieldComponent icon={VmsIcons.MenuLine} value={value} label={customField.name} />
      </ViewField>
    )
    return result
  }, [])

export const CustomFieldsSubCard = ({ customFieldInputs, editCallback = () => {} }) => (
  <div onClick={editCallback}>
    <ViewField>
      <ViewCardTitleSmall>other</ViewCardTitleSmall>
    </ViewField>
    {customFieldInputs}
  </div>
)
