import { skipToken } from '@reduxjs/toolkit/query'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  addAvailabilityTimeslot,
  type TimeslotsByType,
  useGetAvailabilityTimeslotsQuery,
  useGetExperiencesQuery,
} from '@sevenrooms/core/api'
import { type AvailabilityTimeslotRequest, ExperienceStatusEnum, OfferTypeEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core'
import { setExperiences } from '../../actions/BookAvailabilityActions'
import { useStoreSelector } from '../../selectors/useStoreSelector'
import { AvailabilityLocales } from './Availability.locales'
import { availabilityQueryParamsSelector } from './availabilityQueryParamsSelector'
import { initAvailabilityTimeslotFromAccessRule } from './createARTimeslotHelper'
import { defaultAvailability } from './defaults'
import { useGetItemFromTimeslot } from './useGetItemFromTimeslot'

export function useAvailabilityTimeslotsRequest() {
  const dispatch = useDispatch()
  const { formatMessage } = useLocales()
  const getItemFromTimeslot = useGetItemFromTimeslot()
  const bookAvailabilityState = useStoreSelector(state => state.bookAvailabilityState)
  const { availabilityByVenue, searchVenues, previousSelectedTimeSlot, shiftPersistentId, internalArBookingEnabled } = bookAvailabilityState
  const { availabilityChargeData } = useStoreSelector(state => state.bookPaymentState)

  const requestParams = availabilityQueryParamsSelector(bookAvailabilityState)
  const { data: availability = defaultAvailability, isFetching } = useGetAvailabilityTimeslotsQuery(requestParams)

  const foundAvailability = availability.find(item => item.persistentId === shiftPersistentId)
  const category = foundAvailability?.category
  const timeslots = foundAvailability?.timeslots
  const upgradeCategories = foundAvailability?.upsellCategories
  const permissions = useNavigationPermissionsContext()
  const { data: experiences } = useGetExperiencesQuery(
    internalArBookingEnabled && searchVenues[0]?.id && permissions?.canViewMarketing
      ? {
          venueId: searchVenues[0]?.id,
          status: ExperienceStatusEnum.ACTIVE,
          excludedOfferTypes: [OfferTypeEnum.EVENT],
        }
      : skipToken
  )
  useEffect(() => {
    if (!category) {
      return
    }
    const foundTimeslot = timeslots?.find(
      t =>
        t.access_persistent_id === previousSelectedTimeSlot?.access_persistent_id && t.sort_order === previousSelectedTimeSlot?.sort_order
    )
    const availableTimes = searchVenues[0] ? availabilityByVenue[searchVenues[0].id]?.availableTimes : undefined
    const internalTimeslot = availableTimes?.find(t => t.sort_order === previousSelectedTimeSlot?.sort_order)
    if (
      timeslots &&
      !foundTimeslot &&
      internalTimeslot &&
      previousSelectedTimeSlot?.access_persistent_id &&
      !previousSelectedTimeSlot.real_datetime_of_slot
    ) {
      const initedTimeslot = initAvailabilityTimeslotFromAccessRule(
        previousSelectedTimeSlot,
        internalTimeslot,
        availabilityChargeData ?? undefined
      )
      dispatch(addAvailabilityTimeslot(requestParams as AvailabilityTimeslotRequest, initedTimeslot))
    }
    dispatch(setExperiences(experiences?.results ?? []))
    // eslint-disable-next-line
  }, [timeslots, experiences])
  const [timeslotsByType, types] = useMemo(() => {
    const timeslotsByType: TimeslotsByType = {}
    const types: { name: string; value: string }[] = []
    timeslots?.forEach(timeslot => {
      const item = getItemFromTimeslot(timeslot)
      if (timeslotsByType[item.id]) {
        timeslotsByType[item.id]?.push(timeslot)
      } else {
        timeslotsByType[item.id] = [timeslot]
        types.push({ name: item.name || formatMessage(AvailabilityLocales.noTimeslotDescriptionTypeName), value: item.id })
      }
    })
    return [timeslots ? timeslotsByType : undefined, types]
  }, [formatMessage, getItemFromTimeslot, timeslots])

  const byOfferChoices = useMemo(
    () =>
      experiences?.results
        ?.map(experience => {
          if (timeslots?.some(i => i.experience_id === experience.id)) {
            return { name: experience.name, value: experience.id }
          }
          return undefined
        })
        .filter(i => i) ?? [],
    [experiences, timeslots]
  )

  return {
    timeslotsByType,
    types,
    experiences: experiences?.results ?? [],
    category,
    upgradeCategories,
    isFetching,
    requestParams,
    foundAvailability,
    byOfferChoices,
  }
}
