import _ from 'lodash'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore, compose } from 'redux'
import { enableBatching } from 'redux-batched-actions'
import thunk from 'redux-thunk'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { actualSlideoutApi, clearAvailabilityTimeslots, experiencesApi } from '@sevenrooms/core/api'
import { handleAction } from './actions/Epics'
import * as ReviewActions from './actions/ReviewActions'
import * as SlideoutActions from './actions/SlideoutActions'
import * as ViewActualActions from './actions/ViewActualActions'
import App from './containers/App'
import rootReducer from './reducers/CombineReducer'
import { svrExport } from '@sevenrooms/core/utils'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  enableBatching(rootReducer),
  // due to we're combining old redux + redux toolkit we have odd behaviour with toolkit caching
  // (doesn't work or getting errors related with async actions)
  // as workaround - to apply thunk middleware before and after adding middlewares from redux toolkit
  composeEnhancers(
    applyMiddleware(thunk),
    applyMiddleware(actualSlideoutApi.middleware),
    applyMiddleware(experiencesApi.middleware),
    applyMiddleware(thunk)
  )
)
// Subscribe before initialize gets called, so that we're guaranteed to process user domain venues
Pmp.Manager.Global.subscribeOnUserDomainVenuesLoad(() => store.dispatch(GlobalActions.userDomainVenuesLoaded()))

/*
handle 'epics' - taking actions and dispatching new ones
(assumes lastActions reducer is installed)
*/
let lastConsumedActionIndex = -1
store.subscribe(() => {
  const state = store.getState()
  if (_.isEmpty(state.lastActions)) {
    return
  }
  const actionsToRun = state.lastActions.filter(({ action, actionIndex }) => actionIndex > lastConsumedActionIndex)
  if (_.isEmpty(actionsToRun)) {
    return
  }
  lastConsumedActionIndex = _.last(actionsToRun).actionIndex
  const { dispatch, getState } = store
  for (const { action } of actionsToRun) {
    handleAction(action, dispatch, getState)
  }
})

const render = (Component, _globalInit) => {
  const actualSlideout = document.getElementById('sevenrooms-actual-slideout')
  if (actualSlideout) {
    ReactDOM.render(
      <Provider store={store}>
        <AppContainer>
          <Component globalInit={_globalInit} />
        </AppContainer>
      </Provider>,
      actualSlideout
    )
  }
}

render(App, globalInit)

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./containers/App', () => {
    render(App, globalInit)
  })
}

export const viewActual = (actualId, venueId, onComplete) => {
  if (_.isNil(Pmp.Manager.Global.UserDomainVenues)) {
    window.svrDebug('viewActual attempt before UserDomainVenues loaded')
    return
  }
  store.dispatch(ViewActualActions.tryShowActual(actualId, venueId, onComplete))
}
svrExport('ActualSlideout', 'viewActual', viewActual)

export const closeSlideout = () => store.dispatch(SlideoutActions.closeSlideout())
svrExport('ActualSlideout', 'closeSlideout', closeSlideout)

export const addReservation = params => {
  if (_.isNil(Pmp.Manager.Global.UserDomainVenues)) {
    window.svrDebug('addReservation attempt before UserDomainVenues loaded')
    return
  }
  store.dispatch(clearAvailabilityTimeslots())
  store.dispatch(SlideoutActions.enterAddReservation(params))
}
svrExport('ActualSlideout', 'addReservation', addReservation)

export const setToLinking = reviewId => {
  if (_.isNil(Pmp.Manager.Global.UserDomainVenues)) {
    window.svrDebug('setToLinking attempt before UserDomainVenues loaded')
    return
  }
  store.dispatch(ReviewActions.setToLinking(reviewId))
}
svrExport('ActualSlideout', 'setToLinking', setToLinking)

export const scrollOnLoad = () => {
  store.dispatch(ReviewActions.scrollOnLoad())
}
svrExport('ActualSlideout', 'scrollOnLoad', scrollOnLoad)

export const registerCallbackHandlers = handlers => {
  store.dispatch(SlideoutActions.registerCallbackHandlers(handlers))
}
svrExport('ActualSlideout', 'registerCallbackHandlers', registerCallbackHandlers)
