import _ from 'lodash'
import { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import {
  type OngoingEmailCampaignType,
  type NewEmailCampaignFields,
  useCreateEmailCampaignMutation,
  useUpdateEmailCampaignsMutation,
  type EmailCampaignResult,
} from '@sevenrooms/core/api'
import {
  OngoingEmailCampaignStatusEnum,
  MarketingCampaignTypeEnum,
  type VenueProfile,
  type ExperiencesData,
  type GenericTagGroup,
  type GroupVenue,
} from '@sevenrooms/core/domain'
import { useForm, z } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { ActionsButton, Button, Form } from '@sevenrooms/core/ui-kit/form'
import { useBeforeUnload } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack, notify, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import {
  Audience,
  emailBuilderMessages,
  generateEmailCampaignPreviewTemplate,
  SendTestEmailModal,
  extractAutoTagIds,
  getEmailBody,
  getPlainTextFromHtml,
  packSendTimeConfig,
  encodeShortCode,
  campaignBuilderMessages,
  EmailContentFromScratch,
  EmailDetails,
  CancelEmailEditModal,
  useOngoingEmailCampaignFromScratchForm,
  type OngoingEmailCampaignFromScratchFormData,
  getEmailDetailsDefaultValues,
  getAudienceDefaultValues,
  type EmailDetailsFormField,
  type AudienceFormField,
} from '@sevenrooms/marketing'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import {
  OngoingSendingSchedule,
  type OngoingSendingScheduleFormField,
  getOngoingSendingScheduleDefaultValues,
} from '@sevenrooms/mgr-marketing-ongoing-email-center/components'
import type { EmailContentFormField } from '../EmailContent'

export interface OngoingEmailCampaignFromScratchFormProps {
  emailCampaign?: OngoingEmailCampaignType
  venueProfile: VenueProfile
  experiencesData: ExperiencesData
  isDuplicate: Boolean
  isEdit: Boolean
  validate: boolean
  autoTags: GenericTagGroup[]
  groupVenues: GroupVenue[]
}

export function OngoingEmailCampaignFromScratchForm({
  emailCampaign,
  venueProfile,
  experiencesData,
  isDuplicate,
  isEdit,
  validate,
  autoTags,
  groupVenues,
}: OngoingEmailCampaignFromScratchFormProps) {
  const [isCancelled, setIsCancelled] = useState<boolean>(false)
  const [isSaved, setIsSaved] = useState<boolean>(false)
  const formSchema = useOngoingEmailCampaignFromScratchForm()
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue, venueId } = useVenueContext()
  const cancelModalCloseHref = emailCampaign?.id
    ? nav.href(routes.manager2.marketing.emailCenter.emails.editEmailFromScratch, {
        params: { venueKey: venue.urlKey, campaignId: emailCampaign?.id },
        queryMode: 'clear',
      })
    : nav.href(routes.manager2.marketing.emailCenter.emails.emailBuilder.emailBuilderFromScratch, {
        params: { venueKey: venue.urlKey },
        queryMode: 'clear',
      })
  const goToOffers = useRef<boolean>(false)
  const setGoToOffers = () => {
    goToOffers.current = true
  }
  const getDuplicateCampaignName = useCallback(
    () => `${formatMessage(campaignBuilderMessages.copyOf)} ${emailCampaign?.campaignName}`,
    [emailCampaign?.campaignName, formatMessage]
  )

  const campaignName = useMemo(
    () => (isDuplicate ? getDuplicateCampaignName() : emailCampaign?.campaignName ?? ''),
    [isDuplicate, getDuplicateCampaignName, emailCampaign?.campaignName]
  )

  const defaultValues = useMemo(
    () => getDefaultValues(emailCampaign ? { ...emailCampaign, campaignName } : emailCampaign, validate, autoTags),
    [emailCampaign, campaignName, validate, autoTags]
  )
  const form = useForm(formSchema, {
    defaultValues,
  })

  const {
    field,
    formState: { isDirty, dirtyFields },
    setValue,
    getValues,
    trigger,
    reset,
  } = form

  const formRef = useRef<HTMLFormElement>(null)
  const shouldExit = useRef<boolean>(false)
  const newCampaignId = useRef<string>('')

  const setCampaignStateActive = () => {
    setValue('campaignState', OngoingEmailCampaignStatusEnum.ACTIVE)
  }

  useEffect(() => {
    if (validate) {
      trigger()
    }
  }, [validate, trigger])

  useBeforeUnload(isDirty && !isCancelled && !isSaved)

  const [createEmailCampaign, { isLoading: isCreating }] = useCreateEmailCampaignMutation()
  const [updateEmailCampaignsQuery, { isLoading: isUpdating }] = useUpdateEmailCampaignsMutation()
  const isActionDisabled = isCreating || isUpdating

  const errorMap: z.ZodErrorMap = (issue, ctx) => {
    if (issue.code === z.ZodIssueCode.invalid_type) {
      if (issue.received === 'unknown') {
        return { message: formatMessage(commonMessages.required) }
      }
    }
    if (issue.code === z.ZodIssueCode.too_small) {
      if (issue.minimum === 1) {
        return { message: formatMessage(commonMessages.required) }
      }
    }
    if (issue.code === z.ZodIssueCode.invalid_string) {
      if (issue.validation === 'email') {
        return { message: formatMessage(emailBuilderMessages.invalidEmail) }
      }
    }
    return { message: ctx.defaultError }
  }
  z.setErrorMap(errorMap)

  async function trySaveEmailCampaign(formData: OngoingEmailCampaignFromScratchFormData) {
    const campaignData: NewEmailCampaignFields = {
      ongoingSendTimeConfig: packSendTimeConfig(formData),
      campaignType: MarketingCampaignTypeEnum.ONGOING,
      isAnyClientTags: true,
      status: formData.campaignState,
      campaignName: formData.campaignName,
      emailReplyTo: formData.replyToAddress,
      emailSender: getPlainTextFromHtml(formData.emailSender.value ?? ''),
      emailSubject: formData.subjectLine.value ?? undefined,
      emailBody: formData.emailBodySections[0]?.value ?? undefined,
      previewText: formData.emailPreview.value ?? undefined,
      recipientClientTags: extractAutoTagIds(formData.recipientAutotags),
      recipientClientTagsExclude: extractAutoTagIds(formData.excludedAutotags),
      usingTopolEditor: false,
      topolTemplate: undefined,
    }

    const createUpdatePromise =
      (isEdit && emailCampaign) || newCampaignId.current
        ? updateEmailCampaignsQuery({
            emailCampaignIds: emailCampaign ? [emailCampaign.id] : [newCampaignId.current],
            emailCampaignUpdate: campaignData,
            venueId: venueId || '',
          })
        : createEmailCampaign({ emailCampaign: campaignData, venueId: venueId || '' })

    try {
      setIsSaved(true)
      const updateResponse = await createUpdatePromise.unwrap()
      notify({
        content: formatMessage(campaignBuilderMessages.saveCampaignSuccessMessage),
        type: 'success',
      })
      if (shouldExit.current) {
        nav.push(routes.manager2.marketing.emailCenter.emails, { params: { venueKey: venue.urlKey }, queryMode: 'clear' })
      }
      if (!isEdit && !newCampaignId.current && !shouldExit.current) {
        const response = updateResponse as EmailCampaignResult
        newCampaignId.current = response.emailCampaign?.id ?? ''
        if (isDuplicate) {
          nav.push(routes.manager2.marketing.emailCenter.emails.editEmailFromScratch, {
            params: { venueKey: venue.urlKey, campaignId: newCampaignId.current },
          })
        }
      }
      reset({}, { keepValues: true })
    } catch {
      notify({
        content: formatMessage(campaignBuilderMessages.saveCampaignErrorMessage),
        type: 'error',
      })
    }
    setIsSaved(false)
  }

  if (!venue) {
    return null
  }

  return (
    <>
      <SettingsPageMeta
        venue={venue.name}
        title={formatMessage(isEdit ? emailBuilderMessages.editAutomatedEmailCampaign : emailBuilderMessages.createAutomatedEmailCampaign)}
      />
      <Form
        {...form}
        onSubmit={async data => {
          trySaveEmailCampaign(data)
        }}
        onInvalid={() => {}}
        innerRef={formRef}
      >
        <SettingsPageContent
          headerWidth="calc(100% - 274px)"
          secondHeaderMaxWidth="968px"
          title={formatMessage(
            isEdit ? emailBuilderMessages.emailBuilderFromScratchTitle : emailBuilderMessages.emailBuilderNewFromScratchTitle
          )}
          actions={
            <HStack spacing="s">
              <Button
                data-test="sr-email-builder-from-scratch-cancel-button"
                variant="tertiary"
                onClick={() => {
                  if (isDirty) {
                    goToOffers.current = false
                    nav.push(routes.manager2.marketing.emailCenter.emails.emailBuilder.cancelEmailEditModal, {
                      params: { venueKey: venue.urlKey },
                    })
                  } else {
                    nav.push(routes.manager2.marketing.emailCenter.emails, { params: { venueKey: venue.urlKey } })
                  }
                }}
                disabled={isActionDisabled}
              >
                {formatMessage(commonMessages.cancel)}
              </Button>

              {isEdit && emailCampaign?.status !== OngoingEmailCampaignStatusEnum.DRAFT && !validate ? (
                <ActionsButton
                  data-test="sr-email-builder-from-scratch-save-changes-button"
                  variant="primary"
                  type="submit"
                  disabled={isActionDisabled}
                  actions={[
                    {
                      id: 'saveChanges',
                      title: formatMessage(commonMessages.saveChanges),
                      onClick: () => {
                        shouldExit.current = false
                      },
                    },
                    {
                      id: 'saveAndExit',
                      title: formatMessage(commonMessages.saveAndExit),
                      onClick: () => {
                        shouldExit.current = true
                        formRef?.current?.requestSubmit()
                      },
                    },
                  ]}
                />
              ) : (
                <>
                  {!validate && (
                    <ActionsButton
                      data-test="sr-email-builder-from-scratch-save-as-draft-button"
                      variant="secondary"
                      type="submit"
                      disabled={isActionDisabled}
                      actions={[
                        {
                          id: 'saveDraft',
                          title: formatMessage(commonMessages.saveDraft),
                          onClick: () => {
                            shouldExit.current = false
                            setValue('campaignState', OngoingEmailCampaignStatusEnum.DRAFT, { shouldDirty: false })
                          },
                        },
                        {
                          id: 'saveDraftAndExit',
                          title: formatMessage(commonMessages.saveAndExit),
                          onClick: () => {
                            shouldExit.current = true
                            setValue('campaignState', OngoingEmailCampaignStatusEnum.DRAFT, { shouldDirty: false })
                            formRef?.current?.requestSubmit()
                          },
                        },
                      ]}
                    />
                  )}
                  <Button
                    data-test="sr-email-builder-from-scratch-save-and-publish-button"
                    variant="primary"
                    type="submit"
                    onClick={() => {
                      shouldExit.current = true
                      setValue('campaignState', OngoingEmailCampaignStatusEnum.ACTIVE, { shouldDirty: false })
                    }}
                    disabled={(validate && !dirtyFields?.recipientAutotags) || isActionDisabled}
                  >
                    {formatMessage(commonMessages.saveAndPublish)}
                  </Button>
                </>
              )}
            </HStack>
          }
        >
          <Box p="lm" width="100%">
            <VStack spacing="lm">
              <EmailDetails autoFocus field={field as unknown as EmailDetailsFormField} isAutomatedEmail />
              <Audience
                campaignContent={emailCampaign}
                field={field as unknown as AudienceFormField}
                venue={venue}
                venueProfile={venueProfile}
                messages={emailBuilderMessages}
              />
              <OngoingSendingSchedule field={field as unknown as OngoingSendingScheduleFormField} />
              <EmailContentFromScratch
                fields={field as unknown as EmailContentFormField}
                venueProfile={venueProfile}
                experiencesData={experiencesData}
                isDirty={isDirty}
                setGoToOffers={setGoToOffers}
                shouldUseTopol={false}
                template={defaultValues?.topolTemplate as object}
                groupVenues={groupVenues}
              />
            </VStack>
          </Box>
        </SettingsPageContent>
      </Form>
      <Surface destination={routes.manager2.marketing.emailCenter.emails.emailBuilder.sendTestEmailModal}>
        <Window>
          <SendTestEmailModal
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.emailCenter.emails.emailBuilder.sendTestEmailModal, {
              params: { venueKey: venue.urlKey },
            })}
            subjectLine={getValues().subjectLine.value ?? ''}
            senderAddress={getPlainTextFromHtml(getValues().emailSender.value ?? '')}
            replyToAddress={getValues().replyToAddress}
            previewText={getValues().emailPreview.value ?? ''}
            template={generateEmailCampaignPreviewTemplate('', getValues().emailBodySections[0]?.value ?? '', '', '', '')}
            emailBody={getValues().emailBodySections[0]?.value ?? ''}
            signoff=""
            emailGreeting=""
            footer=""
            signature=""
            venueId={venueId}
            withPreview={false}
            onConfirm={setCampaignStateActive}
            onValidate={() =>
              trigger(['campaignName', 'replyToAddress', 'emailSender', 'subjectLine.value', 'emailBodySections'], {
                shouldFocus: true,
              })
            }
          />
        </Window>
      </Surface>
      <Surface destination={routes.manager2.marketing.emailCenter.emails.emailBuilder.cancelEmailEditModal}>
        <Window>
          <CancelEmailEditModal
            onConfirm={() => {
              setIsCancelled(true)
            }}
            onCancel={() => {
              setIsCancelled(false)
            }}
            closeHref={cancelModalCloseHref}
            goToOffers={goToOffers.current}
          />
        </Window>
      </Surface>
    </>
  )
}

function getDefaultValues(
  emailCampaign?: OngoingEmailCampaignType,
  validate?: boolean,
  clientTagGroups?: GenericTagGroup[]
): OngoingEmailCampaignFromScratchFormData {
  return {
    ...getEmailDetailsDefaultValues(emailCampaign),
    campaignState: emailCampaign?.status || OngoingEmailCampaignStatusEnum.DRAFT,
    emailSender: {
      value: encodeShortCode(_.escape(emailCampaign?.emailSender) || ''),
      maxLen: null,
      isEditable: true,
    },
    subjectLine: {
      value: encodeShortCode(emailCampaign?.emailSubject || ''),
      maxLen: null,
      isEditable: true,
    },
    emailPreview: {
      value: emailCampaign?.previewText || '',
      maxLen: null,
      isEditable: true,
    },
    emailBodySections: [
      {
        value: getEmailBody({ emailCampaign }),
        maxLen: null,
        isEditable: true,
      },
    ],
    ...getOngoingSendingScheduleDefaultValues(emailCampaign),
    ...getAudienceDefaultValues(emailCampaign, clientTagGroups),
    emailGreeting: null,
    signature: null,
    signoff: null,
    footer: null,
    validate,
    topolTemplate: emailCampaign?.topolTemplate ?? null,
  }
}
