import { skipToken } from '@reduxjs/toolkit/query'
import { useMemo } from 'react'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in MA-857
import { type EmailCampaignTemplate, useGetOngoingEmailCampaignTemplatesQuery, useGetClientTagGroupsQuery } from '@sevenrooms/core/api'
import { type EmailCampaignCategory, isTagGlobal } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, Flex, HStack, Loader, SettingsSection, VStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import { Header, Text } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPageContent, SettingsPageMeta, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { EmailTemplateModal, EmailTemplateCard } from '../../components'
import { emailTemplateMessages } from '../../locales'

export function EmailTemplate() {
  const { formatMessage } = useLocales()
  const { venue, venueId } = useVenueContext()
  const nav = useNavigation()
  const { venueSettings } = useVenueSettingsContext()
  const canManageEmailsWithGlobalTags = useAppContext().userDomain?.canManageEmailsWithGlobalTags || false

  const { data: tagGroups, isFetching: isFetchingTagGroups } = useGetClientTagGroupsQuery(
    venue.urlKey ? { venueKey: venue.urlKey } : skipToken
  )
  const { data, isFetching } = useGetOngoingEmailCampaignTemplatesQuery({ venueId })
  const groupedTemplates = useMemo(() => {
    if (!data?.templates) {
      return null
    }
    const map = new Map()
    for (const item of data.templates) {
      if (!canManageEmailsWithGlobalTags && isTagGlobal(item.recipientClientTags, tagGroups)) {
        continue
      }

      if (map.has(item.campaignCategory)) {
        map.get(item.campaignCategory).push(item)
      } else {
        map.set(item.campaignCategory, [item])
      }
    }

    const sortCampaignsByName = (data: EmailCampaignTemplate[]) =>
      [...data].sort((lhs, rhs) => lhs.campaignName.localeCompare(rhs.campaignName))

    const sortedByNameMap = new Map([...map].sort().map(([key, value]) => [key, sortCampaignsByName(value)]))

    return sortedByNameMap
  }, [canManageEmailsWithGlobalTags, data?.templates, tagGroups])

  const handleEmailCenterClick = () => {
    nav.push(routes.manager2.marketing.emailCenter, { params: { venueKey: venue.urlKey } })
  }

  const templateInUse = (templateId: string) => !!data?.campaignsFromTemplate.find(item => item === templateId)

  if (venueSettings && !venueSettings.pre_built_campaigns_enabled) {
    nav.push(routes.manager2.marketing.emailCenter, { params: { venueKey: venue.urlKey } })
  }

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={formatMessage(emailTemplateMessages.emailTemplateTitle)} />

      <SettingsPageContent
        title={formatMessage(emailTemplateMessages.emailTemplateTitle)}
        description={formatMessage(emailTemplateMessages.emailTemplateDescription)}
      >
        <Box p="m" width="100%">
          <BaseSection maxWidth="1000px">
            {isFetching || isFetchingTagGroups ? (
              <Loader />
            ) : (
              <>
                {data?.templates.length === data?.campaignsFromTemplate.length ? (
                  <StyledBox p="m" ml="lm" mt="lm" mr="lm" textAlign="left" width="95%">
                    <Header type="h4">{formatMessage(emailTemplateMessages.emptyTemplateHeaderText)}</Header>
                    <HStack mt="s">
                      <Text color="secondaryFont">{formatMessage(emailTemplateMessages.emptyTemplateText)}</Text>
                      <StyledButton data-test="empty-template-button" variant="secondary" size="s" onClick={handleEmailCenterClick}>
                        {formatMessage(emailTemplateMessages.emptyTemplateButtonText)}
                      </StyledButton>
                    </HStack>
                  </StyledBox>
                ) : null}

                <Box p="m">
                  <VStack>
                    {groupedTemplates &&
                      Array.from(groupedTemplates.keys()).map((key: EmailCampaignCategory) => (
                        <StyledSettingsSection
                          title={`${formatMessage(emailTemplateMessages[`emailCampaignCategory${key}Text`])} (${
                            groupedTemplates.get(key)?.length
                          })`}
                          key={key}
                        >
                          <Flex flexWrap="wrap" alignItems="flex-end">
                            {groupedTemplates.get(key)?.map((template: EmailCampaignTemplate) => (
                              <Box p="m" key={template.id}>
                                <EmailTemplateCard template={template} disable={templateInUse(template.id)} />
                              </Box>
                            ))}
                          </Flex>
                        </StyledSettingsSection>
                      ))}
                  </VStack>
                </Box>
              </>
            )}
          </BaseSection>
        </Box>
        <Surface destination={routes.manager2.marketing.emailCenter.emails.emailTemplate.useTemplate}>
          <Window>
            <EmailTemplateModal
              closeHref={nav.closeSurfaceHref(routes.manager2.marketing.emailCenter.emails.emailTemplate.useTemplate, {
                params: { venueKey: venue.urlKey },
              })}
            />
          </Window>
        </Surface>
      </SettingsPageContent>
    </>
  )
}

const StyledSettingsSection = styled(SettingsSection)`
  margin-top: 16px !important;
`

const StyledBox = styled(Box)`
  box-sizing: border-box;
  border: 2px solid ${theme.colors.success};
  border-radius: 4px;
  background-color: ${theme.colors.successBackground};
  padding: 16px 24px;
  width: 952px;
  height: 108px;
`

const StyledButton = styled(Button)`
  background-color: ${theme.colors.primaryBackground} !important;
`
