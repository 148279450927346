/* eslint-disable camelcase */
import { useMemo } from 'react'
import type { AutomaticUpsell } from '@sevenrooms/core/api'
import type { GuestFacingUpgrade, GuestFacingUpgradeCategory, GuestFacingUpgradeInventory } from '@sevenrooms/core/domain'
import { z } from '@sevenrooms/core/form'
import type { UpgradesForm } from '../Upgrades'

type CardEntryOption = 'manual' | 'paylink' | string | null

export type PaymentForm = z.infer<ReturnType<typeof usePaymentForm>>

export const usePaymentForm = () => {
  const charges = useChargesForm()
  const category = useCategoryForm()
  return useMemo(
    () =>
      z.object({
        amount: z.number().nullable(),
        charges,
        categoriesBundled: z.record(category),
        categories: z.record(category),
      }),
    [charges, category]
  )
}

export type CategoryForm = z.infer<ReturnType<typeof useCategoryForm>>

const useCategoryForm = () => {
  const charges = useChargesForm()
  const upgrade = useUpgradeForm()
  return useMemo(
    () =>
      z.object({
        name: z.string(),
        upgrades: z.record(upgrade),
        charges,
      }),
    [charges, upgrade]
  )
}

export type UpgradeForm = z.infer<ReturnType<typeof useUpgradeForm>>

const useUpgradeForm = () =>
  useMemo(
    () =>
      z.object({
        amount: z.number().nullable(),
        name: z.string(),
        count: z.number(),
      }),
    []
  )

export type ChargesForm = z.infer<ReturnType<typeof useChargesForm>>

const useChargesForm = () =>
  useMemo(
    () =>
      z
        .object({
          gratuity: z.number().nullable(),
          gratuityClientSelect: z.boolean(),
          requireGratuityCharge: z.boolean(),
          service: z.number().nullable(),
          taxId: z.string().nullable(),
          applyGratuity: z.boolean(),
          applyService: z.boolean(),
          applyTax: z.boolean(),
        })
        .superRefine((values, ctx) => {
          if (values.requireGratuityCharge && (values.gratuity == null || values.gratuity === 0)) {
            ctx.addIssue({ code: z.ZodIssueCode.custom, path: ['gratuity'] })
          }
        }),
    []
  )

interface UseDefaultValuesParams {
  partySize: number
  chargeDetails: {
    chargeAmount: string
    chargeApplyTax: boolean | null
    taxGroupId: string | null
    applyServiceCharge: boolean | null
    serviceCharge: string | null
    applyGratuityCharge: boolean | null
    gratuityCharge: string | null
    paylinkGratuityType: GratuityType
    cardEntryOption?: CardEntryOption
  }
  bundledUpgrades?: AutomaticUpsell[]
  upgrades: GuestFacingUpgrade
  defaultGratuity: number
  defaultServiceCharge: number
  categoriesForm?: UpgradesForm['categories']
  isPreselectedTime: boolean
  oldValues?: PaymentForm
  isDirtyUpgrades: boolean
}

export type GratuityType = 'gratuity_percentage' | 'client_select_gratuity' | 'require_client_select_charge'

export const useDefaultValues = ({
  partySize,
  chargeDetails,
  bundledUpgrades,
  upgrades,
  defaultGratuity,
  defaultServiceCharge,
  categoriesForm,
  isPreselectedTime,
  oldValues,
  isDirtyUpgrades,
}: UseDefaultValuesParams): PaymentForm =>
  useMemo(() => {
    const { inventories, categories } = upgrades
    const selectedUpgradesCategories: PaymentForm['categories'] = {}
    const selectedBundledUpgradesCategories: PaymentForm['categoriesBundled'] = {}

    Object.entries(categoriesForm ?? {}).forEach(([categoryId, { upgrades: formUpgrades }]) => {
      const category = categories.find(category => category.id === categoryId)
      const upgradesFiltered = Object.entries(formUpgrades).filter(([, count]) => count > 0)
      if (category && upgradesFiltered.length) {
        selectedUpgradesCategories[category.id] = getCategoryForm(category, defaultGratuity, defaultServiceCharge)
        upgradesFiltered.forEach(([upgradeId, count]) => {
          const upgrade = inventories.find(inventory => inventory.id === upgradeId)
          if (upgrade) {
            // category exists, we already created object above
            // eslint-disable-next-line
            selectedUpgradesCategories[category.id]!.upgrades[upgrade.id] = getUpgradesForm(count, upgrade)
          }
        })
      }
    })

    bundledUpgrades?.forEach(({ id, quantity_equal_type, quantity_num }) => {
      const upgrade = inventories.find(inventory => inventory.id === id)
      const category = upgrade ? categories.find(category => category.id === upgrade.categoryId) : undefined
      if (upgrade && category) {
        if (!selectedBundledUpgradesCategories[category.id]) {
          selectedBundledUpgradesCategories[category.id] = getCategoryForm(category, defaultGratuity, defaultServiceCharge)
        }
        const count = quantity_equal_type === 'SPECIFIC_NUMBER' ? quantity_num : quantity_num * partySize
        // category exists, we already created object above
        // eslint-disable-next-line
        selectedBundledUpgradesCategories[category.id]!.upgrades[upgrade.id] = getUpgradesForm(count, upgrade)
      }
    })

    const charges = {
      gratuity: Number(chargeDetails.gratuityCharge ?? ''),
      gratuityClientSelect: chargeDetails.paylinkGratuityType !== 'gratuity_percentage',
      requireGratuityCharge:
        chargeDetails.paylinkGratuityType === 'require_client_select_charge' && chargeDetails.cardEntryOption !== 'paylink',
      service: Number(chargeDetails.serviceCharge ?? ''),
      taxId: chargeDetails.taxGroupId ?? '',
      applyGratuity: !!chargeDetails.applyGratuityCharge,
      applyService: !!chargeDetails.applyServiceCharge,
      applyTax: !!chargeDetails.chargeApplyTax,
    }

    return {
      amount: isPreselectedTime && oldValues ? oldValues.amount : Number(chargeDetails.chargeAmount ?? ''),
      charges: isPreselectedTime && oldValues ? oldValues.charges : charges,
      categoriesBundled: isPreselectedTime && oldValues ? oldValues.categoriesBundled : selectedBundledUpgradesCategories,
      categories: !isDirtyUpgrades && oldValues ? oldValues.categories : selectedUpgradesCategories,
    }
  }, [
    upgrades,
    categoriesForm,
    bundledUpgrades,
    chargeDetails.chargeAmount,
    chargeDetails.gratuityCharge,
    chargeDetails.paylinkGratuityType,
    chargeDetails.serviceCharge,
    chargeDetails.taxGroupId,
    chargeDetails.applyGratuityCharge,
    chargeDetails.applyServiceCharge,
    chargeDetails.chargeApplyTax,
    chargeDetails.cardEntryOption,
    partySize,
    defaultGratuity,
    defaultServiceCharge,
    isPreselectedTime,
    oldValues,
    isDirtyUpgrades,
  ])

function getCategoryForm(category: GuestFacingUpgradeCategory, defaultGratuity: number, defaultServiceCharge: number): CategoryForm {
  return {
    name: category.name,
    upgrades: {},
    charges: getChargesForm(category, defaultGratuity, defaultServiceCharge),
  }
}

function getUpgradesForm(count: number, upgrade: GuestFacingUpgradeInventory): UpgradeForm {
  return {
    name: upgrade.name,
    count,
    amount: upgrade.price * count,
  }
}

function getChargesForm(category: GuestFacingUpgradeCategory, defaultGratuity: number, defaultServiceCharge: number): ChargesForm {
  return {
    gratuity:
      category.isChargingGratuity && category.gratuityChargeType === 'DEFAULT_GRATUITY' ? defaultGratuity : category.gratuityPercentage,
    gratuityClientSelect: category.gratuityChargeType === 'CLIENT_GRATUITY',
    requireGratuityCharge: category.requireGratuityCharge,
    service:
      category.isChargingServiceCharge && !category.serviceChargePercentage ? defaultServiceCharge : category.serviceChargePercentage,
    taxId: category.taxGroupId,
    applyGratuity: category.isChargingGratuity,
    applyService: category.isChargingServiceCharge,
    applyTax: category.isChargingTax,
  }
}
