import { defineMessages } from '@sevenrooms/core/locales'

export const emailBuilderMessages = defineMessages({
  createEmailCampaign: {
    id: 'emailBuilderLayout.createEmailCampaign',
    defaultMessage: 'Create Email Campaign',
  },
  editEmailCampaign: {
    id: 'emailBuilderLayout.editEmailCampaign',
    defaultMessage: 'Edit Email Campaign',
  },
  viewEmailCampaign: {
    id: 'emailBuilderLayout.viewEmailCampaign',
    defaultMessage: 'View Email Campaign',
  },
  createAutomatedEmailCampaign: {
    id: 'emailBuilderLayout.createAutomatedEmailCampaign',
    defaultMessage: 'Create Automated Email Campaign',
  },
  editAutomatedEmailCampaign: {
    id: 'emailBuilderLayout.editAutomatedEmailCampaign',
    defaultMessage: 'Edit Automated Email Campaign',
  },
  emailBuilderFromScratchTitle: {
    id: 'emailBuilderLayout.title',
    defaultMessage: 'From Scratch / HTML',
  },
  emailBuilderFromTemplateTitle: {
    id: 'emailBuilderLayout.title',
    defaultMessage: 'From Template',
  },
  emailBuilderNewFromScratchTitle: {
    id: 'emailBuilderLayout.title',
    defaultMessage: 'New Automated Email - From Scratch',
  },
  emailBuilderNewFromTemplateTitle: {
    id: 'emailBuilderLayout.title',
    defaultMessage: 'New Automated Email - From Template',
  },
  audienceDescription: {
    id: 'emailBuilderAudience.title',
    defaultMessage: 'Audience',
  },
  audienceSizeBannerDescriptionCalculated: {
    id: 'audienceSizeBannerDescriptionCalculated',
    defaultMessage: 'You are sending this email to approximately ',
  },
  emailContentTitle: {
    id: 'emailBuilderEmailContent.title',
    defaultMessage: 'Email Content',
  },
  emailSubjectLineTitle: {
    id: 'emailBuilderEmailContent.subjectLineTitle',
    defaultMessage: 'Subject Line',
  },
  emailPreviewTextTitle: {
    id: 'emailBuilderEmailContent.previewTextTitle',
    defaultMessage: 'Preview Text (optional)',
  },
  emailPreviewTextDescription: {
    id: 'emailBuilderEmailContent.previewTextDescription',
    defaultMessage: 'This snippet will appear in the inbox after the subject line.',
  },
  emailPreviewTextTooltipTitle: {
    id: 'emailBuilderEmailContent.emailPreviewTextTooltipTitle',
    defaultMessage: 'What is “Preview Text”?',
  },
  emailGreetingTitle: {
    id: 'emailBuilderEmailContent.greetingTitle',
    defaultMessage: 'Greeting',
  },
  emailBodySectionTitle: {
    id: 'emailBuilderEmailContent.bodySectionTitle',
    defaultMessage: 'Body Section',
  },
  emailAddNewBodySectionTitle: {
    id: 'emailBuilderEmailContent.addNewbodySectionTitle',
    defaultMessage: 'Add New Body Section',
  },
  emailFooterTitle: {
    id: 'emailBuilderEmailContent.footerTitle',
    defaultMessage: 'Footer',
  },
  signoff: {
    id: 'emailBuilderEmailContent.signoff',
    defaultMessage: 'Sign-off',
  },
  emailSignatureTitle: {
    id: 'emailBuilderEmailContent.signatureTitle',
    defaultMessage: 'Signature',
  },
  emailCampaignPreviewTitle: {
    id: 'emailBuilderEmailCampaignPreview.title',
    defaultMessage: 'Email Preview',
  },
  emailCampaignPreviewDesktop: {
    id: 'emailBuilderEmailCampaignPreview.desktop',
    defaultMessage: 'Desktop',
  },
  emailCampaignPreviewMobile: {
    id: 'emailBuilderEmailCampaignPreview.mobile',
    defaultMessage: 'Mobile',
  },
  sendTestEmailButtonText: {
    id: 'emailBuilderEmailContent.sendATestEmailButtonText',
    defaultMessage: 'Send test email',
  },
  sendTestEmailModalTitle: {
    id: 'emailBuilder.sendTestEmailModal.title',
    defaultMessage: 'Send a test email',
  },
  sendTestEmailModalSubTitle: {
    id: 'emailBuilder.sendTestEmailModal.subTitle',
    defaultMessage: 'Immediately send a test email for review.',
  },
  sendTestEmailModalEmailAddressesLabelPrimary: {
    id: 'emailBuilder.sendTestEmailModal.emailAddressesLabelPrimary',
    defaultMessage: 'Email addresses',
  },
  sendTestEmailModalEmailAddressesLabelSecondary: {
    id: 'emailBuilder.sendTestEmailModal.emailAddressesLabelSecondary',
    defaultMessage: 'Please separate email addresses with commas (,)',
  },
  sendTestEmailModalEmailAddressesInputPlaceholder: {
    id: 'emailBuilder.sendTestEmailModal.emailAddressesInputPlaceholder',
    defaultMessage: 'test1@test.com, test2@test.com',
  },
  sendTestEmailModalEmailAddressesInputNoEmailAddressesError: {
    id: 'emailBuilder.sendTestEmailModal.emailAddressesInputNoEmailAddressesError',
    defaultMessage: 'Please enter valid email addresses to test this campaign.',
  },
  sendTestEmailModalEmailAddressesInputInvalidEmailAddressError: {
    id: 'emailBuilder.sendTestEmailModal.emailAddressesInputInvalidEmailAddressError',
    defaultMessage: '"{emailAddress}" is not a valid email address.',
  },
  sendTestEmailModalCancelButton: {
    id: 'emailBuilder.sendTestEmailModal.cancelButton',
    defaultMessage: 'Cancel',
  },
  sendTestEmailModalConfirmButton: {
    id: 'emailBuilder.sendTestEmailModal.confirmButton',
    defaultMessage: 'Send Test Email',
  },
  sendTestEmailModalSuccessMessage: {
    id: 'emailBuilder.sendTestEmailModal.successMessage',
    defaultMessage: 'A test campaign has been sent to {emailAddresses}.',
  },
  sendTestEmailModalErrorMessage: {
    id: 'emailBuilder.sendTestEmailModal.errorMessage',
    defaultMessage: 'Unable to send test campaign, please try again later.',
  },
  emailDetailsTitle: {
    id: 'emailBuilderEmailContent.title',
    defaultMessage: 'Email Details',
  },
  emailDetailsDescription: {
    id: 'emailBuilderEmailContent.title',
    defaultMessage: 'Description',
  },
  sendingScheduleDescription: {
    id: 'emailBuilderSendingSchedule.description',
    defaultMessage: 'After Auto-tags have been applied, choose when to send the email.',
  },
  emailName: {
    id: 'emailCenter.campaignName',
    defaultMessage: 'Name of Email Campaign',
  },
  automatedEmailName: {
    id: 'emailCenter.automatedEmailName',
    defaultMessage: 'Automated Email Name',
  },
  senderVenue: {
    id: 'emailCenter.senderVenue',
    defaultMessage: 'From',
  },
  replyToEmailAddresses: {
    id: 'emailCenter.replyToEmailAddresses',
    defaultMessage: 'Reply-to Addresses',
  },
  replyToEmailAddressesDescription: {
    id: 'emailCenter.replyToEmailAddressesDescription',
    defaultMessage: 'Please separate email addresses with commas (,)',
  },
  reservationModalTitle: {
    id: 'emailEditorReservationModalHeader.title',
    defaultMessage: 'Edit Reservation Widget Link',
  },
  reservationModalSubTitle: {
    id: 'emailEditorReservationModalHeader.subTitle',
    defaultMessage: 'Add link for reservation widget and choose the text you wish to display',
  },
  reservationTextModalLabel: {
    id: 'emailEditorReservationModalTextLabel.primary',
    defaultMessage: 'Text to display',
  },
  reservationLinkModalLabel: {
    id: 'emailEditorReservationModalLinkLabel.primary',
    defaultMessage: 'URL',
  },
  reservationModalTextInputPlaceholder: {
    id: 'emailEditorReservationModalTextInput.placeholder',
    defaultMessage: 'Enter text',
  },
  reservationModalInputLinkPlaceholder: {
    id: 'emailEditorReservationModalTextInput.placeholder',
    defaultMessage: 'www.link.com',
  },
  reservationModalReservationWidgetLinkTemplate: {
    id: 'emailEditorReservationModal.reservationWidgetLinkTemplate',
    defaultMessage: '<reservation_widget_link>',
  },
  unsubscribeModalTitle: {
    id: 'emailEditorUnsubscribeModalHeader.title',
    defaultMessage: 'Edit Unsubscribe Link',
  },
  unsubscribeModalSubTitle: {
    id: 'emailEditorUnsubscribeModalHeader.subTitle',
    defaultMessage: 'Add unsubscribe link and choose the text you wish to display',
  },
  menuModalTitle: {
    id: 'emailEditorMenuModalHeader.title',
    defaultMessage: 'Edit Menu Link',
  },
  menuModalSubTitle: {
    id: 'emailEditorMenuModalHeader.subTitle',
    defaultMessage: 'Add menu link and choose the text you wish to display',
  },
  menuModalMenuLinkTemplate: {
    id: 'emailEditorMenuModal.menuLinkTemplate',
    defaultMessage: '<menu_link>',
  },
  campaignSentTo: {
    id: 'emailBuilderAudience.campaignSentTo',
    defaultMessage: 'Who would you like to send this email to?',
  },
  excludeText: {
    id: 'emailBuilderAudience.exclude',
    defaultMessage: 'Is there anyone you would like to exclude this email from? (optional)',
  },
  emailConsentOverride: {
    id: 'emailBuilderAudience.emailConsentOverride',
    defaultMessage: 'Send this email only to guests who have opted in',
  },
  whenDescription: {
    id: 'emailBuilderEmailEditor.reservation',
    defaultMessage: 'After Tags have been applied, choose when to send the email.',
  },
  unsubscribe: {
    id: 'emailBuilderEmailEditor.unsubscribe',
    defaultMessage: 'Unsubscribe Link',
  },
  deleteSection: {
    id: 'emailBuilder.deleteSection',
    defaultMessage: 'Delete Section',
  },
  saveAutomatedEmailSuccessMessage: {
    id: 'emailBuilder.createAutomatedEmailSuccessMessage',
    defaultMessage: 'Automated Email has been saved.',
  },
  saveAutomatedEmailnErrorMessage: {
    id: 'emailBuilder.createAutomatedEmailErrorMessage',
    defaultMessage: 'Unable to save automated email, please try again later.',
  },
  maxCharacterCountExceeded: {
    id: 'emailBuilder.maxCharacterCountExceeded',
    defaultMessage: 'Character count exceeds maximum for this section',
  },
  autoTagToActivate: {
    id: 'emailBuilder.autoTagToActivate',
    defaultMessage: 'Please add an auto-tag before activating the automated email',
  },
  invalidEmail: {
    id: 'emailBuilder.invalidEmail',
    defaultMessage: 'Invalid email',
  },
  invalidEmailAddresses: {
    id: 'emailBuilder.invalidEmailAddresses',
    defaultMessage: 'Invalid email - "{invalidAddress}"',
  },
  designYourEmailContent: {
    id: 'emailBuilder.designYourEmailContent',
    defaultMessage: 'Design Your Email Content',
  },
  designYourEmailContentDescription: {
    id: 'emailBuilder.designYourEmailContentDescription',
    defaultMessage: 'Start constructing your email content by clicking in the editor below.',
  },
  emailCampaignPreviewDescription: {
    id: 'emailBuilder.emailPreviewDescription',
    defaultMessage: 'The preview box shows how your content will look in the email.',
  },
  adminAudienceDescription: {
    id: 'emailBuilder.adminAudienceDescription',
    defaultMessage: 'Who and where should these emails be sent?',
  },
  adminCampaignSentTo: {
    id: 'emailBuilder.adminCampaignSentTo',
    defaultMessage: 'Who would you like to send this email to?',
  },
  adminExcludeText: {
    id: 'emailBuilder.adminExcludeText',
    defaultMessage: 'Is there anyone you would like to exclude from the automated emails?',
  },
  oneTimeEmail: {
    id: 'emailBuilder.oneTimeEmail',
    defaultMessage: 'Email Campaign',
  },
  createOneTimeEmail: {
    id: 'emailBuilder.createOneTimeEmail',
    defaultMessage: 'Create Email Campaign',
  },
  editOneTimeEmail: {
    id: 'emailBuilder.editOneTimeEmail',
    defaultMessage: 'Edit Email Campaign',
  },
  aboutGDPR: {
    id: 'emailBuilder.aboutGDPR',
    defaultMessage: 'Learn More About GDPR',
  },
  relativeScheduleText: {
    id: 'emailBuilder.relativeScheduleText',
    defaultMessage: 'This email will be sent {hours, plural, =0 {shortly} one {# hour} other {# hours}} after the tag is applied.',
  },
  scheduledScheduleText: {
    id: 'emailBuilder.scheduledScheduleText',
    defaultMessage: 'This email will be sent every {day} at {time}.',
  },
  absoluteScheduleText: {
    id: 'emailBuilder.absoluteScheduleText',
    defaultMessage:
      'This email will be sent {days, plural, =0 {the same or the following day} one {# day} other {# days}} after the tag is applied, at {time}.',
  },
  autoUpdatesBannerTitle: {
    id: 'emailBuilder.emailUpdatesBannerTitle',
    defaultMessage: 'New Template Copy Update',
  },
  autoUpdatesBannerDescription: {
    id: 'emailBuilder.emailUpdatesBannerDescription',
    defaultMessage: 'Our experts have optimized the copy in this email. It has been automatically updated for you.',
  },
  seeUpdates: {
    id: 'emailBuilder.seeUpdates',
    defaultMessage: 'See Updates',
  },
  manualUpdatesBannerTitle: {
    id: 'emailBuilder.manualUpdatesBannerTitle',
    defaultMessage: 'New Template Copy Update',
  },
  manualUpdatesBannerDescription: {
    id: 'emailBuilder.manualUpdatesBannerDescription',
    defaultMessage:
      'Our experts have optimized the copy in this email. Please review the updated copy and choose to accept the changes or leave the email as is.',
  },
  viewNewCopy: {
    id: 'emailBuilder.viewNewCopy',
    defaultMessage: 'View New Copy',
  },
  updatesModalTitle: {
    id: 'emailBuilder.updatesModalTitle',
    defaultMessage: 'What were the updates?',
  },
  updatesModalDescription: {
    id: 'emailBuilder.updatesModalDescription',
    defaultMessage: 'The updates were made on',
  },
  totalUpdates: {
    id: 'emailBuilder.totalUpdates',
    defaultMessage: 'Total Updates:',
  },
  doNotUpdate: {
    id: 'emailBuilder.doNotUpdate',
    defaultMessage: 'Do Not Update',
  },
  previousCopy: {
    id: 'emailBuilder.previousCopy',
    defaultMessage: 'Previous Copy',
  },
  currentCopy: {
    id: 'emailBuilder.currentCopy',
    defaultMessage: 'Current Copy',
  },
  proposedChanges: {
    id: 'emailBuilder.proposedChanges',
    defaultMessage: 'Proposed Changes',
  },
  acceptUpdatesFromTemplateSuccess: {
    id: 'emailBuilder.acceptUpdatesFromTemplateSuccess',
    defaultMessage: 'Successfully applied updates from parent template',
  },
  acceptUpdatesFromTemplateError: {
    id: 'emailBuilder.acceptUpdatesFromTemplateError',
    defaultMessage: 'There was an error applying the updates from the parent template',
  },
  acknowledgeUpdatesFromTemplateError: {
    id: 'emailBuilder.acknowledgeUpdatesFromTemplateError',
    defaultMessage: 'There was an error acknowledging the updates from the parent template',
  },
  dismissUpdatesFromTemplateSuccess: {
    id: 'emailBuilder.dismissUpdatesFromTemplateSuccess',
    defaultMessage: 'Dismissed the updates from the parent template',
  },
  dismissUpdatesFromTemplateError: {
    id: 'emailBuilder.dismissUpdatesFromTemplateError',
    defaultMessage: 'There was an error dismissing the updates from the parent template',
  },
  crossPromotionModalTitle: {
    id: 'emailEditorCrossPromotionModalHeader.title',
    defaultMessage: 'Add a Cross-Promotion Link',
  },
  crossPromotionModalSubTitle: {
    id: 'emailEditorCrossPromotionModalHeader.subTitle',
    defaultMessage: 'Insert a booking link to a different venue in your venue group',
  },
  venue: {
    id: 'emailBuilder.venue',
    defaultMessage: 'Venue',
  },
  chooseVenue: {
    id: 'emailBuilder.chooseVenue',
    defaultMessage: 'Choose a Venue',
  },
} as const)
