import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useHistory, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { PrimaryHeaderLayout } from '@sevenrooms/core/ui-kit/layout'
import { useUserContext, useVenueContext, useVenues } from '@sevenrooms/mgr-core'

export interface PrimaryHeaderProps {
  width?: string
  isShowingSideNavigation?: boolean
}

export function PrimaryHeader({ width, isShowingSideNavigation }: PrimaryHeaderProps) {
  const history = useHistory()
  const { formatMessage } = useLocales()
  const user = useUserContext()
  const userVenues = useVenues()
  const { venue } = useVenueContext()
  const nav = useNavigation()
  const venueOptions = userVenues.map(venue => ({ id: venue.id, label: venue.name }))

  const onVenueChange = (selectedVenue: string | null) => {
    const newVenue = userVenues.find(venue => selectedVenue === venue.id)
    if (!newVenue) {
      return
    }
    const urlSplit = history.location.pathname.split('/')
    urlSplit[2] = newVenue.urlKey
    window.location.replace(`${urlSplit.join('/')}${history.location.search}`)
  }

  const profileOptions = venue &&
    user && [
      {
        id: 'profile',
        label: formatMessage(commonMessages.profile),
        onClick: () => {
          window.location.replace(nav.href(routes.manager.account, { params: { userId: user.id, venueKey: venue.urlKey } }))
        },
      },
      {
        id: 'settings',
        label: formatMessage(commonMessages.settings),
        onClick: () => {
          window.location.replace(
            nav.href(routes.manager2.settings.venue, {
              params: { venueKey: venue.urlKey },
            })
          )
        },
      },
      {
        id: 'help',
        label: formatMessage(commonMessages.help),
        onClick: () => {
          window.location.replace(nav.href(routes.help))
        },
      },
      {
        id: 'logout',
        label: formatMessage(commonMessages.logout),
        onClick: () => {
          window.location.replace(nav.href(routes.logout))
        },
      },
    ]

  return (
    <PrimaryHeaderLayout
      width={width}
      isShowingSideNavigation={isShowingSideNavigation}
      venueOptions={venueOptions}
      selectedVenueId={venue?.id}
      onVenueChange={onVenueChange}
      profileOptions={profileOptions}
      user={user}
    />
  )
}
