import _ from 'lodash'
import { getSortAndIntervalOrdersForShift, getCapacityForOrder } from 'mgr/lib/utils/ShiftUtils'
import { EMPTY_ARRAY, EMPTY_DICT } from './Constants'

export function getDateForActual(actual) {
  return moment(actual.date, 'MM/DD/YY')
}

export function getActualsFromGridMap(gridActualsMapByShift, shiftPersistentId, tableId, sortOrder) {
  try {
    return gridActualsMapByShift[shiftPersistentId][tableId][sortOrder] || EMPTY_ARRAY
  } catch (err) {}
  return EMPTY_ARRAY
}

export function getActualsByOrderFromGridMap(gridActualsMapByShift, shiftPersistentId, tableId) {
  try {
    return gridActualsMapByShift[shiftPersistentId][tableId] || EMPTY_DICT
  } catch (err) {}
  return EMPTY_DICT
}

export function getUnassignedActuals(gridActualsMapByShift, shiftPersistentId) {
  try {
    return gridActualsMapByShift[shiftPersistentId]._UNASSIGNED
  } catch (err) {}
  return EMPTY_ARRAY
}

export function getMaxResOrder(gridActualsMapByShift, shiftPersistentId) {
  try {
    return gridActualsMapByShift[shiftPersistentId]._maxResOrder
  } catch (err) {}
  return EMPTY_ARRAY
}

export function getUnassignedGuestSizes(gridActualsMapByShift, shiftPersistentId) {
  const actuals = getUnassignedActuals(gridActualsMapByShift, shiftPersistentId)
  return actuals.map(actual => actual.max_guests)
}

export function getUsedOrdersByTableFromGridMap(gridActualsMapByShift, shiftPersistentId, tableId) {
  try {
    return gridActualsMapByShift[shiftPersistentId][tableId]._usedOrders || EMPTY_DICT
  } catch (err) {}
  return EMPTY_DICT
}

function isAutoAssign(actual, venueSettings) {
  return venueSettings.autoselect_table && !actual.disable_auto_assign && _.isEmpty(actual.all_table_ids_list)
}

function getAssignedSortOrder(actual, assignments) {
  const autoAssignment = assignments[actual.id]
  if (autoAssignment && !_.isNil(autoAssignment.sort_order)) {
    return autoAssignment.sort_order
  }
  return actual.arrival_time_sort_order
}

function getAssignedTableList(actual, assignments, venueSettings) {
  if (_.isEmpty(assignments)) {
    return []
  }
  const showTables =
    assignments[actual.id] && (isAutoAssign(actual, venueSettings) || !assignments[actual.id].hasOwnProperty('is_reassigned'))
  return showTables ? assignments[actual.id].table_ids : []
}

function getTableEntities(tableIds, tablesByTableId) {
  const tables = []
  for (const tableId of tableIds) {
    tables.push(tablesByTableId[tableId])
  }
  return tables
}

export function computeGridActualsMap(
  actualsByShift,
  waitlistEntriesByShift,
  autoAssignmentsByShift,
  problemReservationsByShift,
  venueSettings,
  tablesByTableId
) {
  const gridActualsMapByShift = {}
  for (const shiftPersistentId in actualsByShift) {
    gridActualsMapByShift[shiftPersistentId] = {
      _UNASSIGNED: [],
      _maxResOrder: 0,
    }
    const actuals = _.concat(actualsByShift[shiftPersistentId] || [], waitlistEntriesByShift[shiftPersistentId] || [])
    for (let i = 0; i < actuals.length; i++) {
      const assignments = autoAssignmentsByShift[shiftPersistentId]
      const problems = problemReservationsByShift[shiftPersistentId]
      const actual = actuals[i]
      const sortOrder = getAssignedSortOrder(actual, assignments)
      const tableList = getAssignedTableList(actual, assignments, venueSettings)
      const isProblemActual = problems && problems[actual.id]

      actual.computedTableList = getTableEntities(tableList, tablesByTableId)
      if (isProblemActual) {
        actual.problem = problems[actual.id]
      }

      if (sortOrder == -1) {
        continue
      }

      const newMaxOrder = sortOrder + Math.floor(actual.duration / 15)
      if (newMaxOrder > gridActualsMapByShift[shiftPersistentId]._maxResOrder) {
        gridActualsMapByShift[shiftPersistentId]._maxResOrder = newMaxOrder
      }

      if (actual.is_dead) {
        // Filter out canceled, no show, no entry
      } else if (_.isEmpty(tableList)) {
        // unassigned reservations
        gridActualsMapByShift[shiftPersistentId]._UNASSIGNED.push(actual)
      } else {
        // assigned reservations
        for (let tableIdx = 0; tableIdx < tableList.length; tableIdx++) {
          const tableId = tableList[tableIdx]
          if (!(tableId in gridActualsMapByShift[shiftPersistentId])) {
            gridActualsMapByShift[shiftPersistentId][tableId] = {
              [sortOrder]: [],
              _usedOrders: {},
            }
          } else if (!(sortOrder in gridActualsMapByShift[shiftPersistentId][tableId])) {
            gridActualsMapByShift[shiftPersistentId][tableId][sortOrder] = []
          }
          gridActualsMapByShift[shiftPersistentId][tableId][sortOrder].push(actual)

          // Keep track of which orders are occupied by a (locked) reservation
          if (!_.isEmpty(actual.all_table_ids_list) || actual.is_in_service) {
            const durationOrders = Math.floor(actual.duration / 15)
            for (let currentOrder = sortOrder; currentOrder < sortOrder + durationOrders; currentOrder++) {
              gridActualsMapByShift[shiftPersistentId][tableId]._usedOrders[currentOrder] = actual
            }
          }
        }
      }
    }

    // sort unassigned section by max_guests ascending
    gridActualsMapByShift[shiftPersistentId]._UNASSIGNED = _.sortBy(gridActualsMapByShift[shiftPersistentId]._UNASSIGNED, 'max_guests')
  }

  return gridActualsMapByShift
}

export function getSortOrderStatsForShift(shiftPersistentId, gridStatsByShift) {
  try {
    return gridStatsByShift[shiftPersistentId].sortOrder || EMPTY_DICT
  } catch (err) {}
  return EMPTY_DICT
}

export function getStatsForOrder(sortOrder, sortOrderStats) {
  const zero = { covers: 0, count: 0, maxCovers: 0 }
  try {
    return sortOrderStats[sortOrder] || zero
  } catch (err) {}
  return zero
}

export function getStatsForSeatingAreaAndShift(seatingAreaId, shiftPersistentId, gridStats) {
  const zero = { covers: 0, count: 0 }
  try {
    return gridStats[shiftPersistentId].seatingArea[seatingAreaId] || zero
  } catch (err) {}
  return zero
}

export function getStatsForShift(shiftPersistentId, gridStats) {
  const zero = { covers: 0, count: 0 }
  try {
    return gridStats[shiftPersistentId].total || zero
  } catch (err) {}
  return zero
}

export function computeGridStats(
  statusesByDb,
  startOfDayHour,
  actualsByShift,
  waitlistEntriesByShift,
  shiftsByPersistentId,
  date,
  seatingAreasByTableId,
  autoAssignmentsByShift,
  venueSettings
) {
  const gridStats = {}
  for (const shiftPersistentId in actualsByShift) {
    if (!(shiftPersistentId in shiftsByPersistentId)) {
      continue
    }

    const shift = shiftsByPersistentId[shiftPersistentId]
    const assignments = autoAssignmentsByShift[shiftPersistentId]
    const actuals = _.concat(actualsByShift[shiftPersistentId] || [], waitlistEntriesByShift[shiftPersistentId] || [])
    const { sortOrders, intervalOrder } = getSortAndIntervalOrdersForShift(date, shift)
    gridStats[shiftPersistentId] = {
      sortOrder: _.zipObject(
        sortOrders,
        _.map(sortOrders, order => ({
          count: 0,
          covers: 0,
          maxCovers: getCapacityForOrder(shift, startOfDayHour, order),
        }))
      ),
      seatingArea: {
        _UNASSIGNED: {
          count: 0,
          covers: 0,
        },
      },
      total: {
        count: 0,
        covers: 0,
      },
    }

    // sort order stats
    const sortOrderStats = gridStats[shiftPersistentId].sortOrder
    for (let i = 0; i < actuals.length; i++) {
      const actual = actuals[i]
      const maxGuests = actual.max_guests ? actual.max_guests : 0
      const sortOrder = getAssignedSortOrder(actual, assignments)

      if (sortOrder == -1) {
        continue
      }

      if (actual.status in statusesByDb) {
        if (statusesByDb[actual.status].dead) {
          continue
        }
      }

      if (!_.has(sortOrderStats, sortOrder)) {
        continue
      }

      // sort order stats
      sortOrderStats[sortOrder].count += 1
      sortOrderStats[sortOrder].covers += maxGuests

      // total stats
      gridStats[shiftPersistentId].total.count += 1
      gridStats[shiftPersistentId].total.covers += maxGuests

      // seating area stats
      const seatingAreaStats = gridStats[shiftPersistentId].seatingArea

      const tableList = getAssignedTableList(actual, assignments, venueSettings)
      let actualCounted = false
      for (const tableId of tableList) {
        const seatingArea = seatingAreasByTableId[tableId]

        if (!seatingArea) {
          continue
        }

        const seatingAreaId = seatingArea.id
        if (!_.has(seatingAreaStats, seatingAreaId)) {
          seatingAreaStats[seatingAreaId] = {
            count: 0,
            covers: 0,
          }
        }
        if (!actualCounted) {
          const seatingAreaStat = seatingAreaStats[seatingAreaId]
          seatingAreaStat.count += 1
          seatingAreaStat.covers += maxGuests
          actualCounted = true
        }
      }

      if (_.isEmpty(tableList)) {
        seatingAreaStats._UNASSIGNED.count += 1
        seatingAreaStats._UNASSIGNED.covers += maxGuests
      }
    }
  }
  return gridStats
}
