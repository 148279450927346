import fetch from 'isomorphic-fetch'

export const fetchShift4InitialData = venueId => {
  const url = `/api-yoa/shift_4/${venueId}/get_initial_data`
  return fetch(url, {
    method: 'GET',
  })
    .then(response => response.json())
    .then(response => response.data)
}

export const initI4goTrueTokenObj = (response, onSuccess, onFailure) =>
  $('#i4goMainForm').i4goTrueToken({
    debug: false,
    remoteDebug: false,
    url: response.i4go_i4m_url,
    server: response.i4go_server,
    accessBlock: response.i4go_accessblock,
    self: document.location,
    template: 'bootstrap5',
    language: 'en',
    cardType: {
      visible: false,
    },
    cvv2Code: {
      placeholder: 'CVV',
      required: true,
    },
    streetAddress: {
      visible: true,
      placeholder: 'Street Address',
      required: true,
    },
    postalCode: {
      visible: true,
      placeholder: 'Postal Code',
      required: true,
    },
    submitButton: {
      visible: false,
    },
    cssRules: [
      `div#container{padding: 3px; max-width: inherit}`,
      `label{display: none}`,
      `#i4go_postalCode{width: 48%}`,
      `.form-control{font-family: Roboto, sans-serif; font-size: 14px; margin-top: 0.5rem; margin-bottom: 0 !important}`,
      `.error{font-family: Roboto, sans-serif; font-size: 14px}`,
      `body{min-height: inherit}`,
    ],
    onSuccess,
    onFailure,
  })
