import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormPercentageInput } from '@sevenrooms/core/ui-kit/form'
import { paymentMessages } from '../Payment.locales'
import { ChargeLine } from './ChargeLine'
import type { ChargesForm } from '../Payment.zod'

interface GratuityProps {
  amount: number | null
  field: Field<ChargesForm>
  oldValue?: number | null
  override: boolean
  selectable?: boolean
  currencyCode: string
  forceDisableEdit?: boolean // need for beta
}

export function Gratuity({ amount, field, oldValue, selectable, currencyCode, override, forceDisableEdit }: GratuityProps) {
  const { formatMessage } = useLocales()

  const [gratuityValue, applyGratuity, gratuityClientSelect] = useWatchMany(field, ['gratuity', 'applyGratuity', 'gratuityClientSelect'])
  const isShowInput = !forceDisableEdit && ((override && !selectable) || applyGratuity)

  const gratuity = gratuityValue ?? 0
  const chargeAmount = amount ? amount * (gratuity / 100) : 0

  return (
    <ChargeLine
      title={formatMessage(paymentMessages.gratuity)}
      field={selectable ? field.prop('applyGratuity') : undefined}
      testId="sr-supafly-apply-gratuity-charge"
      oldAmount={oldValue != null && oldValue !== gratuity ? oldValue : null}
      amount={chargeAmount}
      currencyCode={currencyCode}
    >
      {isShowInput && (
        <FormPercentageInput
          data-test="sr-supafly-gratuity-charge"
          field={field.prop('gratuity')}
          fieldHeight="s"
          hideErrorMessage
          disabled={!override && !selectable && !gratuityClientSelect}
        />
      )}
    </ChargeLine>
  )
}
