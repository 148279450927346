import { useCallback } from 'react'
import { useDismissUpdatesFromParentTemplateMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { notify, Banner } from '@sevenrooms/core/ui-kit/layout'
import { emailBuilderMessages } from '@sevenrooms/marketing'
import { useVenueContext } from '@sevenrooms/mgr-core'
import type { EmailUpdateTypes } from '../../typings'

export interface EmailUpdatesBannerProps {
  campaignId: string
  type: EmailUpdateTypes
  updatedAt: DateOnly | undefined
}

export function EmailUpdatesBanner({ type, campaignId, updatedAt }: EmailUpdatesBannerProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueId, venueKey } = useVenueContext()

  const [dismissUpdates] = useDismissUpdatesFromParentTemplateMutation()

  const acknowledgeUpdates = useCallback(async () => {
    try {
      await dismissUpdates({ venueId, campaignId })
    } catch {
      notify({
        content: formatMessage(emailBuilderMessages.acknowledgeUpdatesFromTemplateError),
        type: 'error',
      })
    }
  }, [campaignId, dismissUpdates, formatMessage, venueId])

  const onUpdatesButtonClick = useCallback(() => {
    acknowledgeUpdates()
    nav.push(routes.manager2.marketing.emailCenter.emails.editEmail.updatesModal, {
      params: { venueKey, campaignId },
    })
  }, [acknowledgeUpdates, nav, venueKey, campaignId])

  if (type === 'auto') {
    return (
      <Banner
        type="success"
        title={`${formatMessage(emailBuilderMessages.autoUpdatesBannerTitle)} ${updatedAt?.formatNYearNMonthNDay()}`}
        description={formatMessage(emailBuilderMessages.autoUpdatesBannerDescription)}
        action={formatMessage(emailBuilderMessages.seeUpdates)}
        onAction={onUpdatesButtonClick}
      />
    )
  }
  return (
    <Banner
      type="warning"
      title={`${formatMessage(emailBuilderMessages.manualUpdatesBannerTitle)} ${updatedAt?.formatNYearNMonthNDay()}`}
      description={formatMessage(emailBuilderMessages.manualUpdatesBannerDescription)}
      action={formatMessage(emailBuilderMessages.viewNewCopy)}
      onAction={() => {
        nav.push(routes.manager2.marketing.emailCenter.emails.editEmail.updatesModal, {
          params: { venueKey, campaignId },
        })
      }}
    />
  )
}
