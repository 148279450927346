import camelcaseKeys from 'camelcase-keys'
import * as React from 'react'
import type { CamelCaseKeys } from '@sevenrooms/core/utils'
import type { VenueSettings as APIVenueSettings, UserDomain as APIUserDomain } from '../typings'

export function useAppContext() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return React.useMemo<AppContext>(() => camelcaseKeys((window as any).globalInit, { deep: true, stopPaths: [''] }), [])
}

export interface AppContext {
  venueId: string
  venueLongId: string
  venueName: string
  internalDisplayName: string
  venueClass: string
  venueUrlKey: string
  venueGroupId: string
  venueLocale: string
  venueLogo: string
  venueCurrencySymbol: string
  venueCurrencyCode: string
  venueTimezone: string
  venueStartOfDayHour: number
  venueIsNoConciergeAccess: boolean
  privilegeLevel: string
  posIntegrationType: string
  posActivated: boolean
  posKey: string
  mediaUrl: string
  venueSettings: VenueSettings
  userDomain?: UserDomain
}

export type UserDomain = CamelCaseKeys<APIUserDomain>
export type VenueSettings = CamelCaseKeys<APIVenueSettings>
