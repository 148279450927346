import { useMemo } from 'react'
import type { AvailabilityTimeslot } from '@sevenrooms/core/api'
import { getShouldTimeSlotShowCost, TimeslotDefaults, type ChargePerType } from '@sevenrooms/core/domain'
import { useLocales, type FormatMessage, FormatService } from '@sevenrooms/core/locales'
import type { TimeslotItem } from '@sevenrooms/core/ui-kit/vx-layout'
import { useStoreSelector } from '../../selectors/useStoreSelector'
import { useGetItemFromTimeslotMessages } from './useGetItemFromTimeslot.locale'

export function useGetItemFromTimeslot() {
  const { formatMessage } = useLocales()
  const { currencyCode } = useStoreSelector(state => state.bookPaymentState)
  const { partySize } = useStoreSelector(state => state.bookAvailabilityState)
  return useMemo(
    () =>
      function (timeslot: AvailabilityTimeslot): TimeslotItem {
        const showCost = getShouldTimeSlotShowCost(
          timeslot.require_credit_card,
          timeslot.cc_party_size_min,
          partySize,
          (timeslot.selected_automatic_upsells?.length ?? 0) > 0
        )
        const name =
          getTimeslotDescription(
            formatMessage,
            timeslot.public_time_slot_description,
            showCost,
            timeslot.cost,
            timeslot.charge_type,
            timeslot.duration,
            currencyCode
          ) ?? ''
        return {
          id: `${name}-${timeslot.access_persistent_id}` ?? TimeslotDefaults.DEFAULT,
          name,
          sortOrder: timeslot.sort_order,
          time: timeslot.time,
        }
      },
    [currencyCode, formatMessage, partySize]
  )
}

export function getTimeslotDescription(
  formatMessage: FormatMessage,
  timeslotDescription: string | undefined,
  showCost: boolean,
  cost: number | undefined | null,
  chargeType: ChargePerType | undefined | null,
  duration: number,
  venueCurrencyCode: string
) {
  if (timeslotDescription) {
    return timeslotDescription
  }
  if (!showCost || !cost || !chargeType) {
    return undefined
  }
  const isPersonChargeType = chargeType === 'person' || chargeType === 'person_slot'
  const chargeTypeModiferString = formatMessage(
    isPersonChargeType ? useGetItemFromTimeslotMessages.timeslotPerPersonLabel : useGetItemFromTimeslotMessages.timeslotPerReservationLabel
  )
  const isSlotChargeType = chargeType === 'person_slot' || chargeType === 'reservation_slot'
  return `${FormatService.formatCurrency(isSlotChargeType ? (cost * duration) / 15 : cost, venueCurrencyCode)}/${chargeTypeModiferString}`
}
