import { defineMessages } from '@sevenrooms/core/locales'

export const keepOrRemoveARPopupLocales = defineMessages({
  title: {
    id: 'actual.payment.keepOrRemoveARPopupTitle',
    defaultMessage: 'Keep or remove the linked Access Rule?',
  },
  body: {
    id: 'actual.payment.keepOrRemoveARPopupBody',
    defaultMessage:
      'This updated time slot does not include Access Rule {name} by default. Would you like to keep {name} linked to this reservation?',
  },
  remove: {
    id: 'actual.payment.keepOrRemoveARPopupRemove',
    defaultMessage: 'Remove Access Rule',
  },
  keep: {
    id: 'actual.payment.keepOrRemoveARPopupKeep',
    defaultMessage: 'Keep Access Rule',
  },
} as const)
