import _ from 'lodash'
import * as GlobalActions from 'mgr/lib/actions/GlobalActions'
import { ReservationStatuses } from 'mgr/lib/utils/Statuses'
import * as Actions from 'mgr/pages/single-venue/dayview/actions/Actions'
import * as ActionTypes from 'mgr/pages/single-venue/dayview/actions/ActionTypes'
import { batchActions } from 'svr/common/ReduxUtils'
import { orderToTime } from 'svr/common/TimeUtil'

export function handleAction(action, dispatch, getState) {
  let venueId = null
  let state = null
  const shiftPersistentId = null
  const date = null
  switch (action.type) {
    case ActionTypes.CHANGE_DATE:
      state = getState()
      venueId = state.appState.venue.urlKey
      if (_.isEmpty(venueId)) {
        return
      }
      dispatch(Actions.tryLoadDayAction(venueId, action.date))
      break
    case ActionTypes.CHANGE_SHIFT:
      Actions.onChangeShift(dispatch, getState)
      break
    case ActionTypes.GRID_DATA_READY:
      if (!SvrManager.BlockSlideout.hasHandlers()) {
        state = getState()
        const addBlockHandler = block => {
          // add and edit block handler
          const latestStore = getState()
          dispatch(Actions.blockAdded(block))
          dispatch(
            Actions.refreshAutoAssignments(
              latestStore.appState.venue.id,
              latestStore.dayviewState.date,
              latestStore.dayviewState.shiftPersistentId
            )
          )
          dispatch(GlobalActions.showSuccessMessage(`Block ${block.name} added`))
          metric.track('Grid.addBlock', { page: 'grid' })
        }
        const removeBlockHandler = blockId => {
          // remove block handler
          const latestStore = getState()
          dispatch(Actions.blockRemoved(blockId))
          dispatch(
            Actions.refreshAutoAssignments(
              latestStore.appState.venue.id,
              latestStore.dayviewState.date,
              latestStore.dayviewState.shiftPersistentId
            )
          )
          dispatch(GlobalActions.showSuccessMessage('Block removed'))
          metric.track('Grid.removeBlock', { page: 'grid' })
        }
        SvrManager.BlockSlideout.registerCallbackHandlers({
          onAdd: addBlockHandler,
          onEdit: addBlockHandler,
          onDelete: removeBlockHandler,
        })
        // TODO: remove after access rule slideout is redone
        $('#close-block-slideout-button').click(() => {
          BookingBlockSlideOut.close()
        })
      }
      break
    case ActionTypes.ADD_NEW_RESERVATION: {
      state = getState()
      SvrManager.BlockSlideout.close()
      BookingBlockSlideOut.close()
      metric.track('Grid.bookNew', { page: 'grid' })
      const { date, table, order } = action
      const tableId = table && table.id
      const seatingArea = table && state.appState.floorPlan.seatingAreasByTableId[table.id]
      const seatingAreaId = seatingArea && seatingArea.id
      const partySize = table && table.party_size_max
      const { shiftPersistentId } = state.dayviewState
      if (globalInit.venueSettings.use_supafly) {
        const sortOrder = _.isNumber(order) ? order : null
        SvrManager.ActualSlideout.addReservation({
          date,
          seatingAreaId,
          tableId,
          partySize,
          shiftPersistentId,
          sortOrder,
          venue: state.appState.venue,
        })
      } else {
        const timeSelected = _.isNumber(order) ? orderToTime(state.appState.venue.startOfDayHour, order).format('LT') : null
        const preload =
          table && order
            ? {
                time_selected: timeSelected,
                table_id: tableId,
                max_party_preload: partySize,
                res_shift_preload: shiftPersistentId,
                duration: state.appState.venue.isNightlifeClass ? 30 : null,
              }
            : undefined
        ReservationSlideOut.bookNew(date._d, tableId ? [tableId] : false, preload)
      }
      break
    }
    case ActionTypes.ADD_NEW_REQUEST:
      state = getState()
      SvrManager.BlockSlideout.close()
      BookingBlockSlideOut.close()
      metric.track('Grid.addRequest', { page: 'grid' })
      ReservationSlideOut.bookNew(action.date._d, undefined, undefined, 'force_request')
      break
    case ActionTypes.ADD_NEW_BLOCK:
      state = getState()
      ReservationSlideOut.close()
      SvrManager.BlockSlideout.addBlock({
        date: action.date,
        tableId: action.table ? action.table.id : null,
        startTimeOrder: action.order,
        endTimeOrder: action.order + 1,
      })
      metric.track('Grid.addBlock', { page: 'grid' })
      break
    case ActionTypes.SHOW_ACTUAL: {
      const { actual } = action
      dispatch(Actions.startLoading(false))
      SvrManager.BlockSlideout.close()
      BookingBlockSlideOut.close()
      if (globalInit.venueSettings.use_supafly) {
        SvrManager.ActualSlideout.viewActual(actual.id, actual.venue_id, () => {
          dispatch(Actions.endLoading())
        })
      } else {
        ReservationSlideOut.showReservation(
          actual.id,
          undefined,
          undefined,
          actual.shift_persistent_id,
          () => {
            dispatch(Actions.endLoading())
          },
          moment(actual.date, 'MM-DD-YY').format('MM-DD-YYYY')
        )
      }
      metric.track('Grid.showReservation', { page: 'grid' })
      break
    }
    case ActionTypes.SHOW_BLOCK:
      state = getState()
      ReservationSlideOut.close()
      SvrManager.BlockSlideout.viewBlock({
        blockId: action.block.id,
        selectedDate: state.dayviewState.date,
      })
      metric.track('Grid.showBlock', { page: 'grid' })
      break
    case ActionTypes.SHOW_ACCESS_RULE:
      dispatch(Actions.startLoading(false))
      ReservationSlideOut.close()
      BookingBlockSlideOut.openEditBlock(action.rule.id, action.rule.name, () => {
        dispatch(Actions.endLoading())
      })
      metric.track('Grid.showAccessRule', { page: 'grid' })
      break
    case ActionTypes.SAVE_RES_STATUS_SUCCESS:
      if (action.status === ReservationStatuses.CANCELED) {
        state = getState()
        venueId = state.appState.venue.urlKey
        const { date } = state.dayviewState
        const { shiftPersistentId } = state.dayviewState
        dispatch(Actions.refreshActualsAction(venueId, date, shiftPersistentId, [action.actualId]))
        dispatch(GlobalActions.showSuccessMessage('Reservation status updated'))
        break
      }
      const latestStore = getState()
      dispatch(batchActions([Actions.gridDataReady(latestStore.dayviewState, latestStore.appState), Actions.endLoading()]))
      break
    case ActionTypes.SAVE_RES_STATUS_FAIL:
      dispatch(GlobalActions.showErrorMessage('Status change failed'))
      break
    case ActionTypes.SAVE_RES_TABLE_ASSIGNMENT_SUCCESS:
      state = getState()
      venueId = state.appState.venue.urlKey
      let { date } = state.dayviewState
      let { shiftPersistentId } = state.dayviewState
      dispatch(Actions.refreshActualsAction(venueId, date, shiftPersistentId, [action.actualId]))
      dispatch(GlobalActions.showSuccessMessage('Table assignment updated'))
      break
    case ActionTypes.SAVE_RES_TABLE_ASSIGNMENT_FAIL:
      dispatch(GlobalActions.showErrorMessage('Changing table assignments failed'))
      break
    case ActionTypes.SAVE_RES_DURATION_SUCCESS:
      state = getState()
      venueId = state.appState.venue.urlKey
      date = state.dayviewState.date
      shiftPersistentId = state.dayviewState.shiftPersistentId
      dispatch(Actions.refreshActualsAction(venueId, date, shiftPersistentId, [action.actualId]))
      dispatch(GlobalActions.showSuccessMessage('Duration updated'))
      break
    case ActionTypes.SAVE_RES_DURATION_FAIL:
      dispatch(GlobalActions.showErrorMessage('Updating duration failed'))
      break
    case ActionTypes.GET_ACTUALS_NOT_READY:
    case ActionTypes.GET_WAITLIST_NOT_READY:
      dispatch(
        GlobalActions.showWarnMessage(
          "Sorry, your reservation list is large and we're still preparing it. Please refresh in a few minutes and try again."
        )
      )
      break
    default:
  }
}
