import { defineMessages } from '@sevenrooms/core/locales'

export const marketingCommonMessages = defineMessages({
  active: {
    id: 'marketing.active',
    defaultMessage: 'Active',
  },
  inactive: {
    id: 'marketing.inactive',
    defaultMessage: 'Inactive',
  },
  draft: {
    id: 'marketing.draft',
    defaultMessage: 'Draft',
  },
  scheduled: {
    id: 'marketing.scheduled',
    defaultMessage: 'Scheduled',
  },
  sent: {
    id: 'marketing.sent',
    defaultMessage: 'Sent',
  },
  sizzleSuperheroSuffix: {
    id: 'marketing.sizzleSuperheroSuffix',
    defaultMessage: ' (Superhero)',
  },
  marketing: {
    id: 'marketing.marketing',
    defaultMessage: 'Marketing',
  },
  archived: {
    id: 'marketing.archived',
    defaultMessage: 'Archived',
  },
  complete: {
    id: 'marketing.complete',
    defaultMessage: 'Complete',
  },

  /**
   * Side navbar pages that haven't been updated to typescript yet.
   * Once they're updated, these can be moved to package level locales.
   */
  events: {
    id: 'marketing.events',
    defaultMessage: 'Events',
  },
  profileInformation: {
    id: 'marketing.profileInformation',
    defaultMessage: 'Profile Information',
  },
  upgrades: {
    id: 'marketing.upgrades',
    defaultMessage: 'Upgrades',
  },
  trackingLinks: {
    id: 'marketing.trackingLinks',
    defaultMessage: 'Tracking Links',
  },
  guestSatisfaction: {
    id: 'marketing.guestSatisfaction',
    defaultMessage: 'Guest Satisfaction',
  },
  autoTagging: {
    id: 'marketing.autoTagging',
    defaultMessage: 'Auto-tags',
  },
  offers: {
    id: 'marketing.offers',
    defaultMessage: 'Offers',
  },
  navigationBar: {
    id: 'templateCategory.navigationBar',
    defaultMessage: 'Navigation Bar',
  },
  doubleColumnLayout: {
    id: 'templateCategory.doubleColumnLayout',
    defaultMessage: 'Double Column Layout',
  },
  newsletter: {
    id: 'templateCategory.newsletter',
    defaultMessage: 'Newsletter',
  },
  promotion: {
    id: 'templateCategory.promotion',
    defaultMessage: 'Promotion',
  },
  announcement: {
    id: 'templateCategory.announcement',
    defaultMessage: 'Announcement',
  },
  loyalty: {
    id: 'templateCategory.loyalty',
    defaultMessage: 'Loyalty',
  },
  holidaySeasonal: {
    id: 'templateCategory.holidaySeasonal',
    defaultMessage: 'Holiday & Seasonal',
  },
  postCard: {
    id: 'templateCategory.postCard',
    defaultMessage: 'Post Card',
  },
  singleColumnLayout: {
    id: 'templateCategory.singleColumnLayout',
    defaultMessage: 'Single Column Layout',
  },
  menu: {
    id: 'templateCategory.menu',
    defaultMessage: 'Menu',
  },
  specialOccasion: {
    id: 'templateCategory.specialOccasion',
    defaultMessage: 'Special Occasion',
  },
  surpriseDelight: {
    id: 'templateCategory.surpriseDelight',
    defaultMessage: 'Surprise & Delight',
  },
  event: {
    id: 'templateCategory.event',
    defaultMessage: 'Event',
  },
  staffHighlight: {
    id: 'templateCategory.staffHighlight',
    defaultMessage: 'Staff Highlight',
  },
  anticipation: {
    id: 'templateCategory.anticipation',
    defaultMessage: 'Anticipation',
  },
  lastMinute: {
    id: 'templateCategory.lastMinute',
    defaultMessage: 'Last Minute',
  },
  designEmail: {
    id: 'marketing.designEmail',
    defaultMessage: 'Design Email',
  },
  lastUpdated: {
    id: 'marketing.designEmail',
    defaultMessage: 'Last Updated:',
  },
  saveChanges: {
    id: 'marketing.saveChanges',
    defaultMessage: 'Save Changes',
  },
  textMarketing: {
    id: 'marketing.textMarketing',
    defaultMessage: 'Text Marketing',
  },
} as const)
