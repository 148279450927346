import { useMemo } from 'react'
import { emailCampaignsApi } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useDestination, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
  notify,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { emailCenterMessages } from '../../locales'

export interface ConfirmArchiveModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onConfirm: () => void
}

export function ConfirmArchiveModal({ closeHref, onConfirm }: ConfirmArchiveModalProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const venueId = venue?.id
  const { params } = useDestination(routes.manager2.marketing.emailCenter.emails.archiveEmail)
  const campaignId = params?.campaignId
  const [deleteEmailCampaignByIdMutation, { isSuccess, isError, isLoading }] = emailCampaignsApi.useDeleteEmailCampaignByIdMutation()

  useMemo(() => {
    const showToastNotification = () => {
      if (isSuccess) {
        notify({
          content: formatMessage(emailCenterMessages.emailArchiveSuccess),
          type: 'success',
        })
        nav.closeSurface(closeHref)
        onConfirm()
      } else if (isError) {
        notify({
          content: formatMessage(emailCenterMessages.emailArchiveError),
          type: 'error',
        })
      }
    }
    showToastNotification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeHref, formatMessage, isError, isSuccess, onConfirm])

  const tryArchiveCampaign = async (campaignId: string) => {
    if (isLoading) {
      return
    }
    if (venue && venueId) {
      deleteEmailCampaignByIdMutation({ campaignId, venueId })
    }
  }

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <VStack spacing="s">
          <Status kind="warning">Warning</Status>
          <ModalTitle title={formatMessage(emailCenterMessages.confirmModalTitle)} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <Text>{formatMessage(emailCenterMessages.confirmModalInformation)} </Text>
        <Text>{formatMessage(emailCenterMessages.confirmModalHistoryInfo)} </Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button href={closeHref} variant="secondary" data-test="cancel-button">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button variant="primary" onClick={() => tryArchiveCampaign(campaignId)} data-test="confirm-button">
            {formatMessage(emailCenterMessages.confirmModalDelete)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
