import FroalaReactEditorView from 'react-froala-wysiwyg/FroalaEditorView'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Box,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
} from '@sevenrooms/core/ui-kit/layout'
import { vxTheme as theme } from '@sevenrooms/core/ui-kit/theme'
import { generateEmailCampaignPreviewTemplate } from '@sevenrooms/marketing'
import { useVenueContext, useStoreSelector } from '@sevenrooms/mgr-core'
import { emailTemplateMessages } from '../../locales'
import { getCampaignTemplateToCopy } from '../../store'

interface MenuProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}

export function EmailTemplateModal({ closeHref }: MenuProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const campaignTemplateToCopy = useStoreSelector(getCampaignTemplateToCopy)
  const nav = useNavigation()

  if (!venue || !campaignTemplateToCopy) {
    return null
  }

  const copyTemplate = () => {
    nav.push(routes.manager2.marketing.emailCenter.emails.emailBuilder.emailBuilderFromTemplate, {
      params: { venueKey: venue.urlKey },
      query: { parentTemplateId: campaignTemplateToCopy.id },
    })
  }

  return (
    <Modal ariaLabel="Base">
      <ModalHeader closeHref={closeHref}>
        <HeaderWrapper>
          <ModalTitle title={campaignTemplateToCopy.campaignName} subTitle={campaignTemplateToCopy.campaignDescription} />
        </HeaderWrapper>
      </ModalHeader>
      <ModalBody>
        <BoxWithBackGround p="m">
          <FroalaReactEditorView
            model={generateEmailCampaignPreviewTemplate(
              campaignTemplateToCopy.greeting ?? '',
              campaignTemplateToCopy.emailBodyParts?.join('<br/>') ?? '',
              campaignTemplateToCopy.signature ?? '',
              campaignTemplateToCopy.signoff ?? '',
              campaignTemplateToCopy.footer ?? ''
            )}
          />
        </BoxWithBackGround>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button
            variant="tertiary"
            href={nav.closeSurfaceHref(routes.manager2.marketing.emailCenter.emails.emailTemplate.useTemplate, {
              params: { venueKey: venue.urlKey },
            })}
            data-test="cancel-button"
          >
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button onClick={copyTemplate} variant="primary" data-test="use-this-template-button">
            {formatMessage(emailTemplateMessages.templateModalButton)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}

const HeaderWrapper = styled('div')`
  h2 {
    margin: 0;
  }
`

const BoxWithBackGround = styled(Box)`
  background: ${theme.colors.secondaryBackground};
  border-radius: 4px;
  width: 448px;
  height: 369px;
  font-size: 14px;
  line-height: 18px;
  max-height: 410px;
  overflow-y: scroll;
  p {
    margin: 0;
  }
  .fr-view em {
    font-style: italic !important;
    font-weight: inherit !important;
  }
  .fr-view strong {
    font-style: inherit !important;
    font-weight: 700 !important;
  }
`
