import type { ReservationWidget } from '@sevenrooms/core/domain'
import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { Flex, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text, Status } from '@sevenrooms/core/ui-kit/typography'
import { calculateTotal, collectTotalCategories } from '../payment-utils'
import { paymentMessages } from '../Payment.locales'
import type { PaymentProps } from '../Payment'
import type { PaymentForm } from '../Payment.zod'

interface TotalsProps {
  field: Field<PaymentForm>
  oldValues?: PaymentProps['oldValues']
  taxGroups: ReservationWidget.TaxGroup[]
  currencyCode: string
  override: boolean
}

export function Totals({ field, oldValues, taxGroups, currencyCode, override }: TotalsProps) {
  const { formatMessage } = useLocales()
  const [amount, charges, categoriesBundled, categories] = useWatchMany(field, ['amount', 'charges', 'categoriesBundled', 'categories'])

  let { total } = calculateTotal(amount ?? 0, charges, taxGroups, false, false)
  total += collectTotalCategories(categoriesBundled, taxGroups, override, false)
  total += collectTotalCategories(categories, taxGroups, override, false)

  let prevPayment = 0
  let resultPayment = 0

  if (oldValues) {
    prevPayment += calculateTotal(oldValues.amount ?? 0, oldValues.charges, taxGroups, false, false).total
    prevPayment += collectTotalCategories(oldValues.categories, taxGroups, false, false)
    prevPayment += collectTotalCategories(oldValues.categoriesBundled, taxGroups, false, false)
    resultPayment = total - prevPayment
  }

  return (
    <VStack>
      <VStack borderTopColor="borders" spacing="s" p="m xs">
        <HStack>
          <Flex flex="auto">
            <Text fontSize="xl">{formatMessage(paymentMessages.total)}</Text>
          </Flex>
          <Flex justifyContent="end">
            <Text fontSize="xl">{FormatService.formatCurrency(total, currencyCode)}</Text>
          </Flex>
        </HStack>
        {!!oldValues && (
          <HStack>
            <Flex flex="auto">
              <Text fontSize="xl">{formatMessage(paymentMessages.previousPayment)}</Text>
            </Flex>
            <Flex justifyContent="end">
              <Text fontSize="xl">({FormatService.formatCurrency(prevPayment, currencyCode)})</Text>
            </Flex>
          </HStack>
        )}
      </VStack>
      {!!oldValues && (
        <VStack borderTopColor="borders" spacing="s" p="m xs">
          <HStack>
            <Flex flex="auto">
              <Text fontWeight="bold" fontSize="xl">
                {resultPayment < 0 ? formatMessage(paymentMessages.amountRefund) : formatMessage(paymentMessages.amountCharge)}
              </Text>
            </Flex>
            <Flex justifyContent="end">
              <Text fontWeight="bold" fontSize="xl" color={resultPayment < 0 ? 'error' : 'primaryFont'}>
                {FormatService.formatCurrency(Math.abs(resultPayment), currencyCode)}
              </Text>
            </Flex>
          </HStack>
          {resultPayment < 0 && <Status kind="warning">{formatMessage(paymentMessages.amountRefundWarning)}</Status>}
        </VStack>
      )}
    </VStack>
  )
}
