import snakecaseKeys from 'snakecase-keys'
import { cleanNullish } from '@sevenrooms/core/utils'
import type { OverrideListPayload as OverrideListPayloadApi } from '../../api'
import type { OverrideListPayload } from '../../domain'

export namespace OverrideListPayloadAdapter {
  export function toServer(data: OverrideListPayload): OverrideListPayloadApi {
    return cleanNullish(snakecaseKeys(data))
  }
}
